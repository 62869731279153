import React from "react"
import { DateTime } from "luxon"

import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"

import SpoofUserButton from "./SpoofUserButton.component"
import ResetProgressButton from "./ResetProgressButton.component"

const UserPageDetails = ({ user }) => (
  <Box component={Paper} p={4}>
    <Stack spacing={4}>
      <Stack>
        <Typography component="span" fontSize=".875rem" fontWeight="600">
          User type
        </Typography>
        {user ? (
          <Typography component="span">
            {user.isAdmin ? "Administrator" : "Customer"}
          </Typography>
        ) : (
          <Skeleton sx={{ maxWidth: "200px" }} />
        )}
      </Stack>
      <Stack>
        <Typography component="span" fontSize=".875rem" fontWeight="600">
          User Id
        </Typography>
        {user ? (
          <Typography component="span">{user.id}</Typography>
        ) : (
          <Skeleton sx={{ maxWidth: "400px" }} />
        )}
      </Stack>
      <Stack>
        <Typography component="span" fontSize=".875rem" fontWeight="600">
          Last Active
        </Typography>
        {user ? (
          <Typography component="span">
            {user.lastActive
              ? DateTime.fromISO(user.lastActive).toLocaleString(
                  DateTime.DATETIME_FULL
                )
              : "n/a"}
          </Typography>
        ) : (
          <Skeleton sx={{ maxWidth: "400px" }} />
        )}
      </Stack>
      <Stack>
        <Typography component="span" fontSize=".875rem" fontWeight="600">
          Progress
        </Typography>
        {user ? (
          <>
            <Typography component="span">
              {user.history.filter((day) => day).length} / 90 days
            </Typography>
            <LinearProgress
              value={(user.history.filter((day) => day).length / 90) * 100}
              variant="determinate"
              sx={{ borderRadius: "3px", height: "15px" }}
            />
          </>
        ) : (
          <>
            <Skeleton sx={{ maxWidth: "100px" }} />
            <Skeleton width="90%" />
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        <SpoofUserButton user={user} />
        <ResetProgressButton user={user} />
      </Stack>
    </Stack>
  </Box>
)

export default UserPageDetails
