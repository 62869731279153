import React, { useContext } from "react"
import { DateTime } from "luxon"
import axios from "axios"

import { Box, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { UserContext } from "../../../app/contexts/UserContext"
import { ExercisesContext } from "../../../app/contexts/ExercisesContext"

import ExerciseForm from "../exercises/ExerciseForm.component"

const style = {
  bgcolor: "background.paper",
  border: "1px solid",
  borderColor: "grey.400",
  boxShadow: 24,
  left: "50%",
  maxWidth: "800px",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const EditExerciseModal = ({
  modalOpen,
  handleCloseModal,
  exercise,
}) => {
  const { setAlert } = useContext(AlertContext)
  const { selectUid } = useContext(UserContext)
  const { updateExercise } = useContext(ExercisesContext)

  const handleSaveExercise = (id, videos, formValues) => {
    console.log(formValues)
    const dbObj = {
      ...formValues,
      lastUpdated: DateTime.now().setZone("America/New_York").toISO(),
      lastUpdatedBy: selectUid(),
    }

    axios
      .post("/api/exercises/update", {
        id,
        dbObj,
      })
      .then(() => {
        // Update exercise in store
        updateExercise({ id, ...dbObj })
        setAlert({
          message: "Exercise saved",
          severity: "success",
        })
        handleCloseModal()
      })
      .catch((error) => {
        console.log("error saving exercise in database: ", error.message)
        setAlert({
          message: `Error saving exercise: ${error.message}`,
          severity: "error",
        })
      })
  }

  return (
    <Modal onClose={handleCloseModal} open={modalOpen}>
      <Box sx={style}>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: { xs: 2, sm: 8 },
            right: { xs: 2, sm: 8 },
          }}
        >
          <CloseIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
        <ExerciseForm exercise={exercise} handleSaveExercise={handleSaveExercise} />
      </Box>
    </Modal>
  )
}

export default EditExerciseModal
