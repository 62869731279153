import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Link, Typography } from "@mui/material"

import Breadcrumbs from "../../common/Breadcrumbs.component"
import PageTitle from "../../common/PageTitle.component"
import ConfigurationTiles from "./ConfigurationTiles.component"

const ConfiguratorPage = () => {
  const isSmall = useMediaQuery("(min-width: 600px)")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <Breadcrumbs
        pages={[
          { title: "Workouts", to: "/" },
          { title: "Fly Code", to: "/fly-code" },
          { title: "Configurator" },
        ]}
      />
      <PageTitle title="Configurator" />
      <Box maxWidth="800px" mt={4}>
        <Typography fontSize={isSmall ? "1.25rem" : "1rem"} fontWeight="300">
          Your Flyweight can be easily configured into 8 different shapes to be
          used for a full range of exercises. The videos below will help you get
          setup and ready for your next workout.
        </Typography>
        <Typography
          fontSize={isSmall ? "1.25rem" : "1rem"}
          fontWeight="300"
          mt={2}
        >
          Ready to go? Get started on your{" "}
          <Link component={NavLink} to="/">
            workout program
          </Link>
          .
        </Typography>
      </Box>
      <ConfigurationTiles />
    </Box>
  )
}

export default ConfiguratorPage
