import React, { useContext, useEffect, useState } from "react"
import { nanoid } from "nanoid"
import { Picker } from "emoji-mart"
import "emoji-mart/css/emoji-mart.css"

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon"

import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"

import IconSelector from "./IconSelector.component"

const configurations = ["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8"]

const checkIfDayExists = (day, workouts) => {
  for (let i = 0; i < workouts.length; i++) {
    if (workouts[i].day === day) return true
  }
  return false
}

const WorkoutForm = ({ workout = null, handleSaveWorkout }) => {
  const { selectAllWorkouts } = useContext(WorkoutsContext)

  const workouts = selectAllWorkouts()

  const [anchorEl, setAnchorEl] = useState(null)
  const popoverId = !!anchorEl ? "simple-popover" : undefined

  const [day, setDay] = useState(workout?.day || "")
  const [description, setDescription] = useState(workout?.description || "")
  const [configuration, setConfiguration] = useState(
    workout?.configuration ? workout.configuration : ""
  )
  const [icon, setIcon] = useState(workout?.icon ? workout.icon : "")

  const onDayChange = (e) => setDay(e.target.value)
  const onDescriptionChange = (e) => setDescription(e.target.value)
  const onConfigurationChange = (e) => setConfiguration(e.target.value)
  const onIconChange = (e) => setIcon(e.target.value)

  useEffect(() => {
    if (workouts && !workout) {
      let nextDay = workouts.length
      do {
        nextDay++
      } while (checkIfDayExists(nextDay, workouts))
      setDay(nextDay)
    }
  }, [workouts, workout])

  const handleOpenPopover = (e) => setAnchorEl(e.currentTarget)
  const handleClosePopover = () => setAnchorEl(null)

  const appendToDescription = (text) =>
    setDescription((prevState) => prevState + text)

  const onSelectEmoji = (emoji) => {
    appendToDescription(emoji.native)
    handleClosePopover()
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const id = workout ? workout.id : nanoid()

    const formValues = {
      configuration,
      day,
      description,
      icon,
    }

    handleSaveWorkout(id, { ...formValues, ...(!workout && { videos: [] }) })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography id="workout-modal-title" fontWeight="600" pb={4}>
        {`${workout ? "Edit" : "Add New"} Workout`}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Day"
            onChange={onDayChange}
            required
            value={day}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <IconSelector icon={icon} onIconChange={onIconChange} />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="config-select-label">Configuration</InputLabel>
            <Select
              labelId="config-select-label"
              label="Configuration"
              onChange={onConfigurationChange}
              value={configuration}
            >
              {configurations.map((config) => (
                <MenuItem key={config} value={config}>
                  {config}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            helperText={
              <Tooltip title="Insert emojis">
                <Button color="info" onClick={handleOpenPopover} size="small">
                  <Typography
                    component="span"
                    fontSize=".875rem"
                    textTransform="none"
                  >
                    Insert emojis
                  </Typography>
                  <InsertEmoticonIcon sx={{ fontSize: "1rem", ml: 0.5 }} />
                </Button>
              </Tooltip>
            }
            label="Description"
            multiline
            onChange={onDescriptionChange}
            rows={3}
            value={description}
            variant="outlined"
          />
          <Popover
            id={popoverId}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Picker autoFocus onSelect={onSelectEmoji} />
          </Popover>
        </Grid>
        {/* {!workout && (
          <Grid item xs={12}>
            <Typography fontWeight="500" pb={1}>
              Add Exercises
            </Typography>
            {configuration && (
              <>
                <ExerciseSelector
                  videos={videos}
                  configuration={configuration}
                  selected={selectedVideos}
                  setSelected={setSelectedVideos}
                  maxHeight="350px"
                />
              </>
            )}
          </Grid>
        )} */}
        <Grid item xs={12}>
          <Button
            mt={4}
            type="submit"
            variant="contained"
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            Save Workout
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default WorkoutForm
