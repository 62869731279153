import { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"

function RequireAuth({ children }) {
  const { authState } = useContext(AuthContext)
  const location = useLocation()

  if (!authState.uid) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return children
}

export default RequireAuth
