import React, { useContext, useState } from "react"

import { Typography } from "@mui/material"

import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"

import Breadcrumbs from "../../../common/Breadcrumbs.component"
import WorkoutsTable from "./WorkoutsTable.component"

const WorkoutsPage = () => {
  const { workoutsState } = useContext(WorkoutsContext)
  const [search, setSearch] = useState("")

  const getVisibleWorkouts = (workouts) => {
    return workouts.filter((workout) => {
      return (
        workout.day?.toString().includes(search.toLowerCase()) ||
        workout.description?.toLowerCase().includes(search.toLowerCase()) ||
        workout.configuration?.toLowerCase().includes(search.toLowerCase())
      )
    }).sort((a, b) => a.day > b.day)
  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Workouts" },
        ]}
      />
      <Typography component="h1" variant="h4" pb={4}>
        Manage Workouts
      </Typography>
      {workoutsState && (
        <WorkoutsTable
          workouts={getVisibleWorkouts(workoutsState)}
          search={search}
          setSearch={setSearch}
        />
      )}
    </>
  )
}

export default WorkoutsPage
