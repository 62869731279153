import React from "react"
import ReactPlayer from "react-player"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, CircularProgress } from "@mui/material"

import VideoOverlay from "./VideoOverlay.component"

const ImageSwiperSlide = ({
  workout,
  video,
  timerPaused,
  setTimerPaused,
  gifPaused,
  setGifPaused,
}) => {
  const isMedium = useMediaQuery("(min-width: 900px)")

  const handlePause = () => {
    if (gifPaused || timerPaused) {
      setGifPaused(false)
      setTimerPaused(false)
    } else {
      setGifPaused(true)
      setTimerPaused(true)
    }
  }

  return (
    <Box
      onClick={handlePause}
      spacing={isMedium ? 1 : 0}
      sx={{
        backgroundColor: "#080808",
        boxShadow:
          "0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02)",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        // height: { xs: "60vh", md: "100%" },
        justifyContent: "center",
        position: "relative",
        width: "100%",
        ".pause-play-icon": {
          transition: "opacity .2s",
          ...(gifPaused ? { opacity: 0.9 } : { opacity: 0 }),
        },
        ...(isMedium && {
          "&:hover": {
            ".pause-play-icon": {
              ...(gifPaused ? { opacity: 0.9 } : { opacity: 0.75 }),
            },
          },
        }),
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          width: "100%",
          // ...(isMedium ? { height: "100%" } : { height: "60vh" }),
        }}
      >
        <Box
          sx={{
            backgroundColor: "#121212",
            flexGrow: 1,
            position: "relative",
            paddingTop: "100%",
          }}
        >
          <CircularProgress
            sx={{
              position: "absolute",
              top: "calc(50% - 20px)",
              left: "calc(50% - 20px)",
            }}
          />
          <ReactPlayer
            className="react-player"
            height="100%"
            loop={true}
            muted={true}
            playing={!gifPaused}
            playsinline
            volume={0}
            width="100%"
            url={video.player_embed_url}
            config={{
              vimeo: {
                playerOptions: {
                  autoplay: 1,
                  autopause: 0,
                  byline: 0,
                  controls: 0,
                  loop: true,
                  muted: true,
                  portrait: 0,
                },
              },
            }}
          />
          {/* <iframe
            src={`https://player.vimeo.com/video/738356666?h=b3bd845b50&amp;autopause=0&amp;autoplay=1&amp;badge=0&amp;&amp;loop=1&amp;player_id=0`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ position:'absolute', top:0, left:0, width:'100%', height:'100%' }}
            title="Move 12.mp4"
            id="video"
          ></iframe> */}
        </Box>
      </Box>
      <VideoOverlay workout={workout} video={video} gifPaused={gifPaused} />
    </Box>
  )
}

export default ImageSwiperSlide
