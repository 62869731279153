import React from "react"
import ReactPlayer from "react-player"

import { Box, CircularProgress, IconButton, Modal, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const modalContentStyles = {
  border: "none",
  display: "flex",
  left: "50%",
  margin: "0 auto",
  maxWidth: "960px",
  outline: "none",
  padding: "padding: 0 10px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
}

const iconButtonStyles = {
  background: "transparent",
  position: "absolute",
  right: 0,
  top: "-55px",
  zIndex: "2",
}

const videoContainerStyles = {
  backgroundColor: "background.paper",
  paddingBottom: "56.34%",
  position: "relative",
  width: "100%",
}

const ReactPlayerVideoModal = ({ modalOpen, handleModalClose, url }) => {
  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
      {url ? (
        <Box sx={modalContentStyles}>
          <Stack width="100%">
            <Box textAlign="right">
              <IconButton
                onClick={handleModalClose}
                sx={iconButtonStyles}
              >
                <CloseIcon sx={{ fontSize: "35px" }} />
              </IconButton>
            </Box>
            <Box sx={videoContainerStyles}>
              <ReactPlayer
                className="react-player"
                height="100%"
                playing={modalOpen}
                playsinline
                width="100%"
                url={url}
                config={{
                  vimeo: {
                    playerOptions: {
                      autopause: 0,
                      byline: 0,
                      controls: 1,
                      loop: true,
                    },
                  },
                }}
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            left: "50%",
            position: "absolute",
            top: "50%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Modal>
  )
}

export default ReactPlayerVideoModal
