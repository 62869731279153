import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import { getAnalytics, logEvent } from "firebase/analytics"

function FirebaseAnalytics() {
  let location = useLocation()
  useEffect(() => {
    // const analytics = window.firebase && window.firebase.analytics
    // if (typeof analytics === "function") {
    //   const page_path = location.pathname + location.search
    //   analytics().setCurrentScreen(page_path)
    //   analytics().logEvent("page_view", { page_path })
    // }
    const page_path = location.pathname + location.search
    const analytics = getAnalytics()
    logEvent(analytics, "page_view", {
      page_title: page_path,
      page_location: window.location.href,
      page_path,
      send_to: "G-LLRNHFTEBB",
    })
  }, [location])
  return null
}

export default FirebaseAnalytics
