import React from "react"
import { Box, Divider, Typography } from "@mui/material"

const PageTitle = ({ title }) => (
  <Box alignSelf="flex-start">
    <Typography
      component="h1"
      variant="h1"
      sx={{
        fontSize: { xs: "1.375rem", sm: "2.25rem" },
        fontWeight: "600",
        textTransform: "uppercase",
      }}
    >
      {title}
    </Typography>
    <Divider
      sx={{
        backgroundColor: "secondary.main",
        height: "4px",
        marginTop: "-4px",
      }}
    />
  </Box>
)

export default PageTitle
