import React, { useContext, useState } from "react"
import axios from "axios"

import { alpha } from "@mui/material/styles"
import {
  Box,
  Button,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"
import { AlertContext } from "../../../app/contexts/AlertContext"

import AddUserModal from "./AddUserModal.component"
import ConfirmDeleteModal from "./ConfirmDeleteModal.component"

const UsersTableToolbar = ({ users, selected, setSelected, search, setSearch }) => {
  const { removeUser } = useContext(AdminUsersContext)
  const { setAlert } = useContext(AlertContext)

  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const handleOpenDeleteModal = () => setConfirmDeleteModalOpen(true)
  const handleCloseDeleteModal = () => setConfirmDeleteModalOpen(false)

  const handleDeleteUsers = () => {
    const usersToDelete = users.filter((user) => selected.includes(user.id))
    usersToDelete.forEach((user) => {
      // delete user from auth
      axios
        .post("/api/firebase/auth/delete", { email: user.email })
        .then((res) => {
          // delete database node
          axios
            .post("/api/users/remove", {
              userId: user.id,
            })
            .then(() => {
              // remove fund from store
              removeUser(user.id)

              // remove is complete
              setSelected([])
              setAlert({
                message: `${
                  selected.length > 1 ? "Users have" : "User has"
                } been permanently deleted`,
                severity: "success",
              })
            })
            .catch((error) => {
              console.log("error deleting user", error.response.data)
              setAlert({
                message: "Unable to remove user from database",
                severity: "error",
              })
            })
        })
        .catch((error) => {
          console.log("Unable to delete user: ", error.response.data.message)
          setAlert({ message: "Unable to delete user", severity: "error" })
        })
    })
  }

  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <TextField
        label="Search users"
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        value={search}
        sx={{ flex: "1 1 100%", maxWidth: "600px" }}
      />
      {selected.length > 0 ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
            pl: 4,
          }}
        >
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            whiteSpace="nowrap"
          >
            {selected.length} selected
          </Typography>
          <Tooltip title="Delete">
            <IconButton
              onClick={(e) => {
                e.currentTarget.blur()
                handleOpenDeleteModal()
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <ConfirmDeleteModal
            modalOpen={confirmDeleteModalOpen}
            handleCloseModal={handleCloseDeleteModal}
            handleDeleteUsers={() => {
              handleCloseDeleteModal()
              handleDeleteUsers(selected)
            }}
            users={users}
            selected={selected}
          />
        </Box>
      ) : (
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{
            flexShrink: 0,
            marginLeft: { xs: 0.5, sm: 2 },
            whiteSpace: "nowrap",
          }}
        >
          + User
        </Button>
      )}
      <AddUserModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setSearch={setSearch}
      />
    </Toolbar>
  )
}

export default UsersTableToolbar
