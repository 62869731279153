import React from "react"
import { NavLink } from "react-router-dom"

import { Box, Link, Stack, Typography } from "@mui/material"

import PageTitle from "../common/PageTitle.component"
import SignIn from "../common/SignIn.component"

const SignInPage = () => (
  <Box sx={{ maxWidth: "40rem", minHeight: "40vh", width: "100%" }}>
    <Stack>
      <Typography
        fontSize="1.125rem"
        fontWeight="300"
        textTransform="uppercase"
      >
        Welcome Back
      </Typography>
      <PageTitle title="Sign in to Fly Code" />
    </Stack>
    <SignIn />
    <Typography fontSize="1.125rem" fontWeight="300" mt={5}>
      Don't have an account?{" "}
      <Link component={NavLink} to="/signup">
        Sign up
      </Link>
    </Typography>
  </Box>
)

export default SignInPage
