import React, { useContext, useState } from "react"

import {
  Grid,
  IconButton,
  List,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"

import { ExercisesContext } from "../../../app/contexts/ExercisesContext"

import EditExerciseModal from "./EditExerciseModal.component"

const ManageExercisesList = ({ videoList, workoutVideos, setWorkoutVideos, reps, setReps }) => {
  const { selectExerciseById } = useContext(ExercisesContext)

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selectedExercise, setSelectedExercise] = useState(null)

  const handleOpenEditModal = (exercise) => {
    setSelectedExercise(exercise)
    setEditModalOpen(true)
  }
  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    setSelectedExercise(null)
  }

  const handleMoveUp = (videoId) => (e) => {
    const index = workoutVideos.indexOf(videoId)
    const length = workoutVideos.length
    setWorkoutVideos(prevState => [
      ...prevState.slice(0, index - 1),
      videoId,
      ...prevState.slice(index - 1, index),
      ...prevState.slice(index + 1, length)
    ])
  }

  const handleMoveDown = (videoId) => (e) => {
    const index = workoutVideos.indexOf(videoId)
    const length = workoutVideos.length
    setWorkoutVideos(prevState => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1, index + 2),
      videoId,
      ...prevState.slice(index + 2, length),
    ])
  }

  const handleRemoveExercise = (videoId) => (e) => {
    const index = workoutVideos.indexOf(videoId)
    const length = workoutVideos.length
    setWorkoutVideos(prevState => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1, length),
    ])
  }

  const handleRepsChange = (videoId) => (e) => {
    setReps(prevState => ({
      ...prevState,
      [videoId]: e.target.value
    }))
  }

  return (
    <>
      <List sx={{ border: "1px solid", borderColor: "grey.200" }}>
        {workoutVideos.map((videoId, index) => {
          const firstItem = index === 0
          const lastItem = index === workoutVideos.length - 1
          const currentVideo = videoList.filter(
            (video) => video.id === videoId
          )[0]
          const exercise = selectExerciseById(currentVideo.parentExerciseId)
          if (exercise) {
            return (
              <Grid
                container
                key={videoId}
                spacing={1}
                sx={{ p: { xs: 1, md: 2 } }}
              >
                <Grid item xs={12} sm={6} md={7}>
                  <Stack
                    direction="row"
                    onClick={() => handleOpenEditModal(exercise)}
                    spacing={2}
                    sx={{ cursor: "pointer" }}
                  >
                    <EditIcon color="action" />
                    <Typography component="span" sx={{ flexGrow: 1 }}>
                      {`${currentVideo.parentName} (${currentVideo.name})`}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <TextField
                    label="Reps / Time"
                    onChange={handleRepsChange(videoId)}
                    size="small"
                    value={reps[videoId]}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Stack direction="row" justifyContent="flex-end">
                    {!firstItem && (
                      <Tooltip placement="left" title="Move up">
                        <IconButton
                          onClick={handleMoveUp(videoId)}
                          size="small"
                          sx={{ ...(lastItem && { marginRight: "34px" }) }}
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!lastItem && (
                      <Tooltip placement="right" title="Move down">
                        <IconButton
                          onClick={handleMoveDown(videoId)}
                          size="small"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip placement="right" title="Remove from workout">
                      <IconButton
                        onClick={handleRemoveExercise(videoId)}
                        size="small"
                      >
                        <DeleteForeverIcon sx={{ color: "error.light" }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            )
          }
          return <React.Fragment key={videoId}></React.Fragment>
        })}
      </List>
      <EditExerciseModal
        modalOpen={editModalOpen}
        handleCloseModal={handleCloseEditModal}
        exercise={selectedExercise}
      />
    </>
  )
}

export default ManageExercisesList
