import React, { useContext } from "react"
import { NavLink } from "react-router-dom"

import { Box, Link, Stack, Tooltip, Typography } from "@mui/material"

import { UserContext } from "../../app/contexts/UserContext"
import { WorkoutsContext } from "../../app/contexts/WorkoutsContext"
import { VideosContext } from "../../app/contexts/VideosContext"

import HorizontalLevelIcon from "../../common/HorizontalLevelIcon.component"

import { StyledTileText } from "./WorkoutTiles.styles"
import { getIcon } from "./DesktopTile.component"

const NextUp = () => {
  const { userState } = useContext(UserContext)
  const { selectWorkoutByDay } = useContext(WorkoutsContext)
  const { selectThumbnailUrl } = useContext(VideosContext)

  // find first incomplete day in history and use this as 'nextUp' day
  const day = userState.history ? userState.history.slice(1).indexOf(false) + 1 : 1
  const workout = selectWorkoutByDay(day)

  const getExerciseCover = (videoId) => selectThumbnailUrl(videoId)
  
  // Temp code for random icons
  const names = ["Agility", "Battery", "Build", "Explosiveness", "Metabolic Burn", "Performance", "Power", "Skill Building", "Speed"]
  const iconName = workout.icon || names[Math.floor(Math.random() * names.length)]

  return (
    <>
      {workout && (
        <Box
          sx={{
            borderBottom: "1px solid",
            borderTop: "1px solid",
            borderColor: "primary.main",
            mt: 6,
            py: 2,
          }}
        >
          <Typography color="text.secondary" fontSize="1.25rem" pb={1}>
            NEXT UP
          </Typography>
          <Link
            component={NavLink}
            to={`/fly-code/day/${day}`}
            sx={{
              display: "flex",
              marginLeft: "-1.5rem",
              marginTop: "-1.5rem",
              textDecoration: "none",
              "& > *": { paddingLeft: "1.5rem", paddingTop: "1.5rem" },
            }}
          >
            <Box sx={{ maxWidth: "250px", width: "40%" }}>
              <Box sx={{ position: "relative" }}>
                <Box
                  component="img"
                  src={getExerciseCover(
                    workout.videos ? workout.videos[0] : ""
                  )}
                  sx={{ width: "100%" }}
                />
              </Box>
            </Box>
            <Stack>
              <Stack alignItems="center" direction="row" pb={2}>
                {iconName && (
                  <Tooltip title={iconName}>
                    {getIcon(iconName)}
                  </Tooltip>
                )}
                <StyledTileText>Day {day}</StyledTileText>
              </Stack>
              <Stack alignItems="center" direction="row">
                <HorizontalLevelIcon
                  level={day <= 30 ? 2 : day <= 60 ? 3 : 4}
                  margin="0 12px 3px 6px"
                />
                <Typography color="text.primary">
                  Phase {day <= 30 ? "1" : day <= 60 ? "2" : "3"}
                </Typography>
              </Stack>
              {/* <Typography sx={{ color: "white", lineHeight: "1.2", maxWidth: "75ch" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </Typography> */}
            </Stack>
          </Link>
        </Box>
      )}
    </>
  )
}

export default NextUp
