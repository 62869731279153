import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"
import axios from "axios"

import { Box, Grid, Paper, Typography } from "@mui/material"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { ExercisesContext } from "../../../app/contexts/ExercisesContext"
import { VideosContext } from "../../../app/contexts/VideosContext"
import { UserContext } from "../../../app/contexts/UserContext"

import Breadcrumbs from "../../../common/Breadcrumbs.component"
import ExerciseForm from "./ExerciseForm.component"
import ExerciseOverview from "./ExerciseOverview.component"

const ExercisePage = () => {
  const { exerciseId } = useParams()
  const { setAlert } = useContext(AlertContext)
  const { selectExerciseById, updateExercise } = useContext(ExercisesContext)
  const { updateVideo } = useContext(VideosContext)
  const { selectUid } = useContext(UserContext)

  const [exercise, setExercise] = useState(null)

  useEffect(() => {
    if (exerciseId) setExercise(selectExerciseById(exerciseId))
  }, [exerciseId, selectExerciseById])

  const saveVideoInfo = (parentExerciseId, videos) => {
    Object.keys(videos).forEach(videoId => {
      const dbObj = {
        parentExerciseId,
        ...videos[videoId]
      }

      axios.post("/api/videos/update", {
        id: videoId,
        dbObj
      }).then(res => {
        updateVideo({
          id: videoId,
          ...dbObj
        })
      }).catch(error => {
        console.log(error.response.data)
      })
    })
  }

  const handleUpdateExercise = (id, videos, formValues) => {
    const dbObj = {
      ...formValues,
      lastUpdated: DateTime.now().setZone("America/New_York").toISO(),
      lastUpdatedBy: selectUid(),
    }

    saveVideoInfo(id, videos)

    axios
      .post("/api/exercises/update", {
        id,
        dbObj,
      })
      .then((res) => {
        // Add new exercise to store
        updateExercise({ id, ...dbObj })
        setAlert({
          message: "Exercise updated",
          severity: "success",
        })
      })
      .catch((error) => {
        console.log("error updating exercise in database: ", error.message)
        setAlert({
          message: `Error updating exercise: ${error.message}`,
          severity: "error",
        })
      })
  }

  return (
    <div>
      {exercise && (
        <>
          <Breadcrumbs
            pages={[
              { title: "Admin", to: "/admin" },
              { title: "Exercises", to: "/admin/exercises" },
              { title: `${exercise.name || ""}` },
            ]}
          />
          <Typography component="h1" variant="h4" pb={4}>
            {`Exercise: ${exercise.name || ""}`}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Box component={Paper} sx={{ padding: { xs: 2, md: 4 } }}>
                <ExerciseForm
                  exercise={exercise}
                  handleSaveExercise={handleUpdateExercise}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <ExerciseOverview exercise={exercise} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default ExercisePage
