import React, { useState, createContext } from "react"
import { getDatabase, ref, get } from "firebase/database"

import placeholder from "../../assets/images/placeholder.jpg"

export const VideosContext = createContext()

const initialState = []

export const VideosProvider = (props) => {
  const database = getDatabase()
  const [videosState, setVideosState] = useState(initialState)

  const startSetVideos = () => {
    return new Promise((resolve) => {
      get(ref(database, "videos/"))
        .then((snap) => {
          const dataArray = []
          for (const [key, value] of Object.entries(snap.val())) {
            dataArray.push({
              id: key,
              ...value,
            })
          }
          setVideosState(dataArray)
          resolve(true)
        })
        .catch((error) => {
          console.error(error)
          resolve([])
        })
    })
  }

  const addVideo = (video) =>
    setVideosState((prevState) => [...prevState, video])

  const updateVideo = (video) =>
    setVideosState((prevState) => [
      ...prevState.filter((prevVideo) => prevVideo.id !== video.id),
      {
        ...prevState.filter((prevVideo) => prevVideo.id === video.id)[0],
        ...video,
      },
    ])

  const removeVideo = (videoId) => {
    setVideosState((prevState) =>
      prevState.filter((prevVideo) => prevVideo.id !== videoId)
    )
  }

  const selectAllVideos = () => videosState

  const selectVideosByIds = (idArray) =>
    videosState.filter((video) => idArray.includes(video.id))

  const selectVideo = (id) => videosState.filter((video) => video.id === id)[0]

  const selectEmbedUrl = (id) =>
    videosState.filter((video) => video.id === id)[0]?.player_embed_url || null

  const selectThumbnailUrl = (id) =>
    videosState.filter((video) => video.id === id)[0]?.thumbnail || placeholder

  return (
    <VideosContext.Provider
      value={{
        videosState,
        startSetVideos,
        addVideo,
        updateVideo,
        removeVideo,
        selectAllVideos,
        selectVideosByIds,
        selectVideo,
        selectEmbedUrl,
        selectThumbnailUrl,
      }}
    >
      {props.children}
    </VideosContext.Provider>
  )
}

export default VideosProvider
