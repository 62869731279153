import React from "react"

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"

const configurationList = ["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8"]

const SelectConfiguration = ({
  configuration,
  setConfiguration,
  configurationError = "",
  setConfigurationError = undefined,
  size = "medium",
}) => {
  const onConfigurationChange = (e) => {
    if (setConfigurationError) setConfigurationError("")
    setConfiguration(e.target.value)
  }

  return (
    <FormControl fullWidth error={!!configurationError} size={size}>
      <InputLabel id="configuration-select-label">Configuration</InputLabel>
      <Select
        id="configuration-select"
        label="Configuration"
        labelId="configuration-select-label"
        onChange={onConfigurationChange}
        size={size}
        value={configuration}
      >
        {configurationList.length > 0 &&
          configurationList
            .slice()
            .map((configName) => (
              <MenuItem key={configName} value={configName}>
                {configName}
              </MenuItem>
            ))}
      </Select>
      <FormHelperText>{configurationError}</FormHelperText>
    </FormControl>
  )
}

export default SelectConfiguration
