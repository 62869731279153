import styled from "@emotion/styled"
import { Box } from "@mui/material"

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => !["uid", "gifPaused", "isMedium"].includes(prop),
})(({ uid, gifPaused, isMedium }) => ({
  backgroundColor: "#080808",
  boxShadow:
    "0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02)",
  ...(uid && {  cursor: "pointer" }),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
  width: "100%",
  ".pause-play-icon": {
    transition: "opacity .2s",
    ...(gifPaused ? { opacity: 0.9 } : { opacity: 0 }),
  },
  ...(isMedium && {
    "&:hover": {
      ".pause-play-icon": {
        ...(gifPaused ? { opacity: 0.9 } : { opacity: 0.75 }),
      },
    },
  }),
}))

export const videoBoxStyle = {
  alignItems: "center",
  backgroundColor: "#121212",
  display: "flex",
  flexGrow: 1,
  height: "100%",
  paddingTop: "56.25%",
  position: "relative",
  width: "100%",
}

export const progressStyle = {
  position: "absolute",
  top: "calc(50% - 20px)",
  left: "calc(50% - 20px)",
}

export const StyledOverlay = styled(Box, {
  shouldForwardProp: (prop) => !["showOverlay"].includes(prop),
})(({ showOverlay }) => ({
  ...(showOverlay && {
    backgroundColor: "rgba(0,0,0,0.4)",
  }),
  height: "100%",
  position: "absolute",
  width: "100%",
}))

export const pausePlayBoxStyle = {
  left: "50%",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
}

export const coachingCueBoxStyle = {
  bottom: "20px",
  position: "absolute",
  right: 0,
}

export const coachingCuesStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  overflow: "hidden",
  paddingLeft: "10px",
  width: { xs: "40%" },
}

export const CueSpacer = styled("div")(({ theme }) => ({
  height: "18px",
  padding: ".125rem 0",
  [theme.breakpoints.up("sm")]: {
    padding: ".25rem 0",
  },
  [theme.breakpoints.up("md")]: {
    padding: ".5rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "1rem 0",
  },
}))

export const StyledCue = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  display: "flex",
  fontSize: ".875rem",
  padding: ".25rem 0",
  textShadow: "1px 1px 3px #000",
  width: "100%",
  "& .text": {
    flexGrow: 0,
    maxWidth: "80%",
  },
  "& .pointer": {
    alignSelf: "center",
    borderBottom: "2px dotted",
    borderColor: theme.palette.primary.main,
    flexGrow: 1,
    marginLeft: ".25rem",
    marginTop: "2px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
    padding: ".5rem 0",
  },
  [theme.breakpoints.up("md")]: {
    marginLeft: "30px",
    padding: "1rem 0",
  },
}))

export const lockedBoxStyle = {
  alignItems: "center",
  color: "text.secondary",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
}

export const lockIconStyles = {
  color: "text.secondary",
  fontSize: { xs: "2rem", sm: "4rem" },
  opacity: 0.8,
}
