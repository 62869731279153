import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    "fontFamily": `"Eurostile", "Arial", "Helvetica", sans-serif`
  },
  palette: {
    primary: {
      main: '#066056',
      light: '#377f77',
      dark: '#04433c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#b01721',
      light: '#bf454d',
      dark: '#7b1017',
      contrastText: '#fff',
    }
  }
})

const AdminTheme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
 
export default AdminTheme
