import React from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import { IconButton, Tooltip } from "@mui/material"
import RestartAltIcon from '@mui/icons-material/RestartAlt'

const RestartButton = ({ resetTimer }) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")

  return (
    <Tooltip title="Restart timer">
      <IconButton
        edge="start"
        onClick={resetTimer}
        size={isLarge ? "medium" : "small"}
      >
        <RestartAltIcon
          sx={{ fontSize: { xs: 30, sm: 35, lg: 50 } }}
        />
      </IconButton>
    </Tooltip>
  )
}
 
export default RestartButton
