import React, { useContext } from "react"
import axios from "axios"

import { Box, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"

import SelectExistingExercise from "./SelectExistingExercises.component"

const style = {
  bgcolor: "background.paper",
  border: "1px solid",
  borderColor: "grey.400",
  boxShadow: 24,
  left: "50%",
  maxHeight: "80vh",
  maxWidth: "800px",
  overflowY: "auto",
  padding: { xs: 2, sm: 4, md: 6 },
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const AddExercisesModal = ({
  modalOpen,
  workout,
  handleCloseModal,
  videoList,
  setWorkoutVideos,
}) => {
  const { setAlert } = useContext(AlertContext)
  const { addVideosToWorkout } = useContext(WorkoutsContext)

  const handleAddToWorkout = (videoIds) => {
    axios
      .post("/api/workouts/update", {
        id: workout.id,
        dbObj: {
          videos: [
            ...(workout.videos?.length > 0 ? workout.videos : []),
            ...videoIds,
          ],
        },
      })
      .then(() => {
        // Update local state
        setWorkoutVideos(prevState => [...prevState, ...videoIds])
        // Update workout in store
        addVideosToWorkout(workout.id, videoIds)
        setAlert({
          message: "Workout updated",
          severity: "success",
        })
        handleCloseModal()
      })
      .catch((error) => {
        console.log("error updating workout in database: ", error.message)
        setAlert({
          message: `Error updating workout: ${error.message}`,
          severity: "error",
        })
      })
  }

  return (
    <Modal
      aria-labelledby="exercise-modal-title"
      onClose={handleCloseModal}
      open={modalOpen}
    >
      <Box sx={style}>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: { xs: 2, sm: 8 },
            right: { xs: 2, sm: 8 },
          }}
        >
          <CloseIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
        <Box pb={2}>
          <SelectExistingExercise
            defaultConfiguration={workout.configuration || null}
            videoList={
              workout.videos
                ? videoList?.filter(
                    (video) => !workout.videos.includes(video.id)
                  )
                : videoList
            }
            handleAddToWorkout={handleAddToWorkout}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default AddExercisesModal
