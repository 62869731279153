import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material"

import { UserContext } from "../../app/contexts/UserContext"

import PageTitle from "../../common/PageTitle.component"
import SafetyTips from "./SafetyTips.component"

const SafetyCertificationPage = () => {
  const navigate = useNavigate()
  const { safetyCertified, startSetSafetyCertified } = useContext(UserContext)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (safetyCertified) navigate("/fly-code")
  }, [safetyCertified, navigate])

  const handleChange = (e) => setChecked(e.target.checked)

  const handleCertify = () => {
    startSetSafetyCertified(true)
  }

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <PageTitle title="Flyweight Safety Information" />
      <Box
        sx={{
          backgroundColor: "#080808",
          borderBottom: "1px solid",
          borderColor: "text.disabled",
          maxHeight: { xs: "40vh", sm: "50vh", md: "60vh" },
          mt: 4,
          pb: 2,
          px: 2,
          overflow: "auto",
        }}
      >
        <SafetyTips />
      </Box>
      <Stack alignItems="flex-start" mt={2} spacing={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="I certify that I have read and understand the above safety information."
        />
        <Button color="primary" disabled={!checked} onClick={handleCertify} variant="contained">
          Continue
        </Button>
      </Stack>
    </Box>
  )
}

export default SafetyCertificationPage
