import React, { useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"
import axios from "axios"

import { Box, Button, Grid, Paper, Typography } from "@mui/material"

import { UserContext } from "../../../app/contexts/UserContext"
import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"
import { ExercisesContext } from "../../../app/contexts/ExercisesContext"
import { VideosContext } from "../../../app/contexts/VideosContext"

import AddExercisesButton from "./AddExercisesButton.component"
import ManageExercisesList from "./ManageExercisesList.component"

const ManageExercises = ({ workout = null }) => {
  const { selectUid } = useContext(UserContext)
  const { updateWorkout } = useContext(WorkoutsContext)
  const { selectExerciseById } = useContext(ExercisesContext)
  const { selectAllVideos } = useContext(VideosContext)
  const allVideos = selectAllVideos()

  const [videoList, setVideoList] = useState([])
  const [workoutVideos, setWorkoutVideos] = useState(workout?.videos || [])
  const [reps, setReps] = useState(workout?.reps || {})

  useEffect(() => {
    let dataArray = []
    if (allVideos) {
      dataArray = allVideos.map((video) => {
        let parentConfig = ""
        let parentName = ""
        if (video.parentExerciseId) {
          const parentExercise = selectExerciseById(video.parentExerciseId)
          parentConfig = parentExercise?.configuration || ""
          parentName = parentExercise?.name || ""
        }
        return {
          ...video,
          configuration: parentConfig,
          parentName: parentName,
        }
      })
      setVideoList(dataArray)
    }
  }, [allVideos, selectExerciseById])

  const handleSaveExercises = () => {
    const dbObj = {
      reps,
      videos: workoutVideos,
      lastUpdated: DateTime.now().setZone("America/New_York").toISO(),
      lastUpdatedBy: selectUid(),
    }

    axios
      .post("/api/workouts/update", {
        id: workout.id,
        dbObj,
      })
      .then((res) => {
        // Add new workout to store
        updateWorkout({ id: workout.id, ...dbObj })
      })
      .catch((error) => {
        console.log("error updating workout in database: ", error.message)
      })
  }

  return (
    <Box component={Paper} sx={{ padding: { xs: 2, md: 4 } }}>
      <Grid container pb={4} spacing={1}>
        <Grid item xs={12} sm={8}>
          <Typography component="h3" variant="h5">
            Manage Exercises
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: { xs: "flex-start", sm: "flex-end" } }}>
          {videoList.length > 0 && (
            <AddExercisesButton
              workout={workout}
              videoList={videoList}
              setWorkoutVideos={setWorkoutVideos}
            />
          )}
        </Grid>
      </Grid>
      {workout.videos?.length > 0 && videoList.length > 0 && (
        <Box sx={{ border: "1px solid", borderColor: "grey.200" }}>
          <ManageExercisesList
            videoList={videoList}
            workoutVideos={workoutVideos}
            setWorkoutVideos={setWorkoutVideos}
            reps={reps}
            setReps={setReps}
          />
        </Box>
      )}
      <Button
        onClick={handleSaveExercises}
        variant="contained"
        sx={{ mt: 2, width: { xs: "100%", sm: "auto" } }}
      >
        Save Exercises
      </Button>
    </Box>
  )
}

export default ManageExercises
