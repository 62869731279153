import SignInPage from "../../features/SignInPage.component"
import SignUpPage from "../../features/SignUpPage.component"
import ForgotPasswordPage from "../../features/ForgotPasswordPage.component"

const accountRoutes = [
  { path: "/signin", component: SignInPage },
  { path: "/signup", component: SignUpPage },
  { path: "/forgot-password", component: ForgotPasswordPage },
]

export default accountRoutes
