import React, { useContext } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"

import useMediaQuery from "@mui/material/useMediaQuery"

import { VideosContext } from "../../app/contexts/VideosContext"

import ImageSwiperSlide from "./ImageSwiperSlide.component"

const ImageSwiper = ({
  workout,
  handleSwipe,
  timerPaused,
  setTimerPaused,
  gifPaused,
  setGifPaused,
}) => {
  const isMedium = useMediaQuery("(min-width: 900px)")
  const { selectVideosByIds } = useContext(VideosContext)
  const videos = selectVideosByIds(workout.videos)

  const onRealIndexChange = (swiper) => {
    handleSwipe(swiper.realIndex)
  }

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      navigation={isMedium}
      onRealIndexChange={onRealIndexChange}
      pagination={{
        clickable: true,
      }}
      slidesPerView={1}
    >
      {workout.videos.map((videoId, index) => {
        return (
          <SwiperSlide key={index}>
            <ImageSwiperSlide
              workout={workout}
              video={videos.filter(video => video.id === videoId)[0]}
              timerPaused={timerPaused}
              setTimerPaused={setTimerPaused}
              gifPaused={gifPaused}
              setGifPaused={setGifPaused}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default ImageSwiper
