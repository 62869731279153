import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"

import { UserContext } from "../../../app/contexts/UserContext"
import { AuthContext } from "../../../app/contexts/AuthContext"

const SpoofUserButton = ({ user }) => {
  const navigate = useNavigate()
  const { setUid } = useContext(AuthContext)
  const { setUserState } = useContext(UserContext)

  const handleSpoofUser = () => {
    setUid(user.id)
    setUserState({ ...user, uid: user.id })
    navigate("/")
  }

  return (
    <Button onClick={handleSpoofUser} variant="contained">
      View App as this user
    </Button>
  )
}

export default SpoofUserButton
