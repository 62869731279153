import React from "react"

import { Box, CircularProgress, Modal, useMediaQuery } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import {
  StyledContainer,
  StyledIconButton,
  videoBoxStyle,
  videoStyle,
  progressBoxStyle,
} from "./ConfigurationVideoModal.styles"

const ConfigurationVideoModal = ({
  videoModalOpen,
  handleVideoModalClose,
  video,
}) => {
  const isLandscape = useMediaQuery("(orientation: landscape)")

  return (
    <Modal open={videoModalOpen} onClose={handleVideoModalClose}>
      {video ? (
        <StyledContainer isLandscape={isLandscape}>
          <Box sx={videoBoxStyle}>
            <StyledIconButton
              onClick={handleVideoModalClose}
              isLandscape={isLandscape}
            >
              <CloseIcon sx={{ fontSize: "35px" }} />
            </StyledIconButton>
            <video
              controls={true}
              autoPlay={true}
              muted={true}
              loop={true}
              height="432"
              width="640"
              style={videoStyle}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </Box>
        </StyledContainer>
      ) : (
        <Box sx={progressBoxStyle}>
          <CircularProgress />
        </Box>
      )}
    </Modal>
  )
}

export default ConfigurationVideoModal
