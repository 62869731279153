import landscapeBg from "../../assets/images/homepage-video-bg-landscape.jpg"
import portraitBg from "../../assets/images/homepage-video-bg-portrait.jpg"

export const containerStyle = (theme) => ({
  backgroundImage: `url('${landscapeBg}')`,
  [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
    backgroundImage: `url('${portraitBg}')`,
    marginTop: "calc(-40px + -1.5rem)",
  },
  backgroundSize: "cover",
  color: "#fff",
  position: "relative",
  "&:hover, &:active, &:visited": {
    color: "#fff",
  }
})

export const overlayStyle = {
  backgroundColor: "#000000",
  height: "100%",
  left: 0,
  opacity: 0.6,
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 150,
}

export const textBoxStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  position: "absolute",
  width: "100%",
  zIndex: 200,
}

export const h1Style = (theme) => ({
  fontSize: "2rem",
  fontWeight: "700",
  letterSpacing: ".2rem",
  pb: 4,
  textAlign: "center",
  textTransform: "uppercase",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.5rem",
    letterSpacing: ".25rem",
  },
  [theme.breakpoints.up("me")]: {
    fontSize: "3rem",
    letterSpacing: ".3rem",
    pb: 3,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3.25rem",
    letterSpacing: ".35rem",
    pb: 4,
  },
})

export const h2Style = (theme) => ({
  fontSize: "1rem",
  pb: 2,
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.125rem",
  },
  [theme.breakpoints.up("me")]: {
    fontSize: "1.25rem",
    pb: 1.5,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
    pb: 2,
  },
})

export const frameStyle = (theme) => ({
  overflow: "hidden",
  paddingTop: "56.25%",
  [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
    paddingTop: "177.77%",
  },
  pointerEvents: "none",
  position: "relative",
  width: "100%",
  iframe: {
    border: "none",
    bottom: 0,
    height: "100%",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
  },
})
