import React from "react"
import { NavLink } from "react-router-dom"
import { Box, Grid, Link, Typography } from "@mui/material"

import { tileTitleStyles, h1Styles } from "./HomePage.styles"
import HorizontalLevelIcon from "../../common/HorizontalLevelIcon.component"

import logo from "../../assets/images/flycode-logo.png"
const programVideoScreenshot =
  "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/images%2Fprogram-video-screenshot.jpg?alt=media&token=10f5ae42-7a35-40a8-9ee1-27968e0135e5"

const HomePageFlyCode = () => (
  <Grid container columnSpacing={4} mt={4} rowSpacing={2}>
    <Grid item xs={12} md={7}>
      <Box mb={2}>
        <Link component={NavLink} to="/fly-code">
          <Box
            component="img"
            src={logo}
            sx={{
              maxWidth: { xs: "150px", sm: "200px", md: "250px" },
              width: "100%",
            }}
          />
        </Link>
      </Box>
      <Link component={NavLink} to="/fly-code" sx={h1Styles}>
        90-Day Program
      </Link>
      <Typography fontSize="1.125rem" pb={2}>
        Follow along through 90 daily workouts, designed to build
        explosiveness, aerobic muscle and competitive stamina. Workouts
        focus on building, explosiveness, metabolic burn, skill building,
        agility and performance.
      </Typography>
      <Typography fontSize="1.125rem" pb={2}>
        The program is comprised of three 30-day phases, scaling in
        intensity as you progress through the program. Discover the Fly Code
        difference.
      </Typography>
      <Box alignItems="center" display="flex">
        <HorizontalLevelIcon size={4} level={2} margin="0 8px 4px 0" />
        <Typography>Phase 1 &mdash;</Typography>
        <HorizontalLevelIcon size={4} level={4} margin="0 8px 4px 8px" />
        <Typography>Phase 3 Intensities</Typography>
      </Box>
    </Grid>
    <Grid item xs={12} md={5}>
      <Link
        component={NavLink}
        to="/fly-code"
        display="block"
        position="relative"
      >
        <Box component="img" src={programVideoScreenshot} width="100%" />
        <Typography sx={tileTitleStyles}>
          90-Day Program
        </Typography>
      </Link>
    </Grid>
  </Grid>
)

export default HomePageFlyCode
