import React, { useState } from "react"

import { Box, Paper, Table, TableContainer, TablePagination } from "@mui/material"

import UsersTableToolbar from "./UsersTableToolbar.component"
import UsersTableHead from "./UsersTableHead.component"
import UsersTableBody from "./UsersTableBody.component"

const UsersTable = ({ users, search, setSearch }) => {
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("lname")
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = users.map((user) => user.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <UsersTableToolbar
          users={users}
          selected={selected}
          setSelected={setSelected}
          search={search}
          setSearch={setSearch}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <UsersTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <UsersTableBody
              users={users}
              selected={selected}
              setSelected={setSelected}
              page={page}
              rowsPerPage={rowsPerPage}
              order={order}
              orderBy={orderBy}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

export default UsersTable
