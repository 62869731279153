import React, { useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

const AddUserForm = ({ handleCloseModal, handleAddUser }) => {
  const [formValues, setFormValues] = useState({
    code: "",
    email: "",
    fname: "",
    lname: "",
  })
  const [isAdmin, setIsAdmin] = useState(false)

  const handleFormChange = (property, value) => {
    setFormValues((prevState) => ({ ...prevState, [property]: value }))
  }

  const handleSubmit = () =>  handleAddUser(formValues, isAdmin)

  return (
    <Box component="form">
      <Typography id="user-modal-title" fontWeight="600" pb={4}>
        Add New User
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            onChange={(e) => handleFormChange("fname", e.target.value)}
            value={formValues.fname}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            onChange={(e) => handleFormChange("lname", e.target.value)}
            value={formValues.lname}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            onChange={(e) => handleFormChange("email", e.target.value)}
            value={formValues.email}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Sign up code / temp password"
            onChange={(e) => handleFormChange("code", e.target.value)}
            value={formValues.code}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isAdmin} />}
            label="Administrator Account?"
            onChange={() => setIsAdmin((prevState) => !prevState)}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={handleCloseModal} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Add User
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddUserForm
