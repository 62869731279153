import styled from "@emotion/styled"
import { Box, IconButton } from "@mui/material"

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => !["isLandscape"].includes(prop),
})(({ isLandscape }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  left: "50%",
  margin: "0 auto",
  maxWidth: "960px",
  padding: "padding: 0 10px",
  ...(isLandscape && { padding: "10px 60px" }),
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}))

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => !["isLandscape"].includes(prop),
})(({ isLandscape }) => ({
  background: "transparent",
  position: "absolute",
  right: 0,
  top: "-55px",
  zIndex: "2",
  ...(isLandscape && { right: "-55px", top: 0 }),
}))

export const videoBoxStyle = {
  backgroundColor: "background.paper",
  border: "2px solid #151515",
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  position: "relative",
  paddingBottom: "67.5%",
  width: "100%",
}

export const videoStyle = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
}

export const progressBoxStyle = {
  left: "50%",
  position: "absolute",
  top: "50%",
}
