import React from "react"

import { Box } from "@mui/material"

import PageTitle from "../../common/PageTitle.component"
import SafetyTips from "./SafetyTips.component"

const SafetyTipsPage = () => (
  <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
    <PageTitle title="Flyweight Safety Information" />
    <Box mt={4} pb={2}>
      <SafetyTips />
    </Box>
  </Box>
)

export default SafetyTipsPage
