import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { styled } from "@mui/material/styles"
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material"

import { AuthContext } from "../app/contexts/AuthContext"
import { UserContext } from "../app/contexts/UserContext"

const userPDF =
  "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/files%2FFlyweight_UserGuide.pdf?alt=media&token=8bfefb26-165f-41c4-9662-8b04b8f8c5db"
const warningsPDF =
  "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/files%2FFlyweight_WarningGuide.pdf?alt=media&token=dd5fab0e-3063-433d-91a9-d9d528a27e80"

const StyledDrawer = styled(Drawer)(() => ({
  "& .MuiPaper-root": {
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
  },
  "& .MuiTypography-root": {
    fontSize: "1.125rem",
  },
}))

const MenuDrawer = ({ drawerOpen, setDrawerOpen }) => {
  const { handleSignOut } = useContext(AuthContext)
  const { userState, clearUser } = useContext(UserContext)
  const navigate = useNavigate()

  const onSignOut = () => {
    clearUser()
    handleSignOut()
  }

  return (
    <StyledDrawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Box
        onClick={() => setDrawerOpen(false)}
        onKeyDown={() => setDrawerOpen(false)}
        role="presentation"
        sx={{ pt: 5, width: 250 }}
      >
        <List component="nav">
          <ListItem>
            <ListItemText
              primary={
                userState.fname && userState.lname
                  ? `${userState.fname} ${userState.lname}`
                  : "Anonymous"
              }
              sx={{ color: "text.secondary" }}
            />
          </ListItem>
          <ListItemButton onClick={() => navigate("/change-password")}>
            <ListItemText primary={"Change Password"} />
          </ListItemButton>
        </List>
        {userState.isAdmin && (
          <>
            <Divider />
            <List component="nav">
              <ListItemButton onClick={() => navigate("/admin/dashboard")}>
                <ListItemText primary={"Admin Dashboard"} />
              </ListItemButton>
            </List>
          </>
        )}
        <Divider />
        <List component="nav">
          {/* <ListItem button onClick={() => navigate("/")}>
            <ListItemText primary={"Select Program"} />
          </ListItem> */}
          <ListItemButton onClick={() => navigate("/fly-code")}>
            <ListItemText primary={"Fly Code"} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/configurator")}>
            <ListItemText primary={"Configurator"} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/safety-tips")}>
            <ListItemText primary={"Safety Tips"} />
          </ListItemButton>
          <ListItemButton onClick={() => window.open(userPDF)}>
            <ListItemText primary={"User Guide"} />
          </ListItemButton>
          <ListItemButton onClick={() => window.open(warningsPDF)}>
            <ListItemText primary={"Warnings Guide"} />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={onSignOut}>
            <ListItemText primary={"Sign out"} />
          </ListItemButton>
        </List>
      </Box>
    </StyledDrawer>
  )
}

export default MenuDrawer
