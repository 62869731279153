import React, { useContext, useEffect, useMemo, useRef, useState } from "react"

import { Box, Grid, Typography, useMediaQuery } from "@mui/material"

import { VideosContext } from "../../app/contexts/VideosContext"

import MobileTile from "./MobileTile.component"
import DesktopTile from "./DesktopTile.component"

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  )

  useEffect(() => {
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

const PreviewTiles = ({ workouts }) => {
  const isSmall = useMediaQuery("(min-width: 600px)")

  const { selectThumbnailUrl } = useContext(VideosContext)

  const baseGroupSize = 9
  const initialGroupSize = baseGroupSize
  const [sliceSize, setSliceSize] = useState(initialGroupSize)

  const infiniteScrollRef = useRef(null)
  const isInViewport = useIsInViewport(infiniteScrollRef)
  useEffect(() => {
    if (isInViewport) setSliceSize((prevState) => prevState + baseGroupSize)
  }, [isInViewport])

  const getExerciseCover = (videoId) => selectThumbnailUrl(videoId)

  return (
    <Box sx={{ position: "relative" }}>
      <Typography color="text.secondary" fontSize="1.25rem" mt={6} pb={1}>
        PROGRAM DAYS
      </Typography>
      <Grid container spacing={1}>
        {workouts
          .sort((a, b) => a.day - b.day)
          .slice(0, sliceSize)
          .map((workout) => {
            return (
              <Grid
                item
                key={workout.day}
                id={`day-${workout.day}-tile`}
                xs={12}
                sm={6}
                md={4}
                sx={{
                  aspectRatio: { xs: "auto", sm: "1" },
                }}
              >
                {isSmall ? (
                  <DesktopTile
                    day={workout.day}
                    getExerciseCover={getExerciseCover}
                    icon={workout.icon}
                    lastCompleted={1}
                    nextDay={3}
                    videos={workout.videos || null}
                  />
                ) : (
                  <MobileTile
                    day={workout.day}
                    getExerciseCover={getExerciseCover}
                    icon={workout.icon}
                    lastCompleted={1}
                    nextDay={3}
                    videos={workout.videos || null}
                  />
                )}
              </Grid>
            )
          })}
      </Grid>
      <Box ref={infiniteScrollRef} />
    </Box>
  )
}

export default PreviewTiles
