import React, { useContext, useState } from "react"
import ReactPlayer from "react-player"

import { Box, CircularProgress, useMediaQuery } from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { UserContext } from "../../app/contexts/UserContext"
import {
  StyledContainer,
  videoBoxStyle,
  progressStyle,
  StyledOverlay,
  pausePlayBoxStyle,
  coachingCueBoxStyle,
  coachingCuesStyle,
  CueSpacer,
  StyledCue,
  lockedBoxStyle,
  lockIconStyles,
} from "./ChallengeVideo.styles"

import PausePlayIcon from "../Workout/PausePlayIcon.component"
import CoachingCuesButton from "../Workout/CoachingCuesButton.component"

const playerConfig = {
  vimeo: {
    playerOptions: {
      autoplay: 0,
      autopause: 0,
      byline: 0,
      controls: 0,
      loop: true,
      portrait: 0,
    },
  },
}

const ChallengeVideo = ({ handlePause, gifPaused, challenge, playerRef }) => {
  const isMedium = useMediaQuery("(min-width: 900px)")
  const { uid } = useContext(UserContext)
  const [showCoachingCues, setShowCoachingCues] = useState(false)

  return (
    <StyledContainer
      uid={uid}
      gifPaused={gifPaused}
      isMedium={isMedium}
      onClick={uid ? handlePause : undefined}
      spacing={isMedium ? 1 : 0}
    >
      <Box sx={videoBoxStyle}>
        <CircularProgress sx={progressStyle} />
        <ReactPlayer
          className="react-player"
          height="100%"
          loop={true}
          playing={uid ? !gifPaused : false}
          playsinline
          ref={playerRef}
          width="100%"
          url={challenge.player_embed_url}
          config={playerConfig}
        />
      </Box>
      <StyledOverlay showOverlay={uid ? showCoachingCues : true}>
        {uid ? (
          <>
            <Box id="pause-play" className="pause-play-icon" sx={pausePlayBoxStyle}>
              <PausePlayIcon gifPaused={gifPaused} />
            </Box>
            {showCoachingCues && challenge?.reps && (
              <Box id="coaching-cues" sx={coachingCuesStyle}>
                {challenge.reps.split("\n").map((cue, i) => {
                  if (!cue) return <CueSpacer key={i} />
                  return (
                    <StyledCue key={i}>
                      <TaskAltIcon sx={{ fontSize: 20, mr: 1, mt: .5 }} />
                      <div className="text">{cue}</div>
                    </StyledCue>
                  )
                })}
              </Box>
            )}
            <Box sx={coachingCueBoxStyle}>
              {challenge?.reps && (
                <CoachingCuesButton
                  showCoachingCues={showCoachingCues}
                  setShowCoachingCues={setShowCoachingCues}
                />
              )}
            </Box>
          </>
        ) : (
          <Box sx={lockedBoxStyle}>
            <LockIcon sx={lockIconStyles} />
            Sign-in to view
          </Box>
        )}
      </StyledOverlay>
    </StyledContainer>
  )
}

export default ChallengeVideo
