import React from "react"

import { PlayArrow as PlayIcon, Pause as PauseIcon } from "@mui/icons-material"
import { Box, useMediaQuery } from "@mui/material"

const PausePlayIcon = ({ gifPaused }) => {
  const isSmall = useMediaQuery("(min-width: 600px)")

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, .5)",
        borderRadius: "50%",
        display: "flex",
        height: { xs: 60, md: 80 },
        justifyContent: "center",
        width: { xs: 60, md: 80 },
      }}
    >
      {gifPaused && <PlayIcon sx={{ fontSize: { xs: 40, md: 60 } }} />}
      {!gifPaused && isSmall && (
        <PauseIcon sx={{ fontSize: { xs: 40, md: 50 } }} />
      )}
    </Box>
  )
}

export default PausePlayIcon
