import React, { useContext, useState } from "react"
import { nanoid } from "nanoid"
import axios from "axios"

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

import { VideosContext } from "../../../app/contexts/VideosContext"

const configurations = ["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8"]

const ExerciseForm = ({ exercise = null, handleSaveExercise }) => {
  const { selectVideo } = useContext(VideosContext)

  const [configuration, setConfiguration] = useState(
    exercise?.configuration ? exercise.configuration : ""
  )
  const [name, setName] = useState(exercise?.name || "")
  const [coachingCues, setCoachingCues] = useState(exercise?.coachingCues || "")
  const [video1, setVideo1] = useState(exercise?.videos ? { id: exercise.videos[0], ...selectVideo(exercise.videos[0]) } : { id: "" })
  const [video1Error, setVideo1Error] = useState("")
  const [video2, setVideo2] = useState(exercise?.videos ? { id: exercise.videos[1], ...selectVideo(exercise.videos[1]) } : { id: "" })
  const [video2Error, setVideo2Error] = useState("")

  const onConfigurationChange = (e) => setConfiguration(e.target.value)
  const onNameChange = (e) => setName(e.target.value)
  const onCoachingCuesChange = (e) => setCoachingCues(e.target.value)
  const onVideo1Change = (e) => setVideo1({ id: e.target.value })
  const onVideo2Change = (e) => setVideo2({ id: e.target.value })

  const fetchVimeoInfo = (video1Id, video2Id = null) => {
    return new Promise((resolve, reject) => {
      // first fetch video 1 info
      axios.post("/api/vimeo/get-thumbnail", { vimeoId: video1Id }).then(res1 => {
        const video1Obj = {
          configuration,
          duration: res1.data.duration,
          name: res1.data.name,
          player_embed_url: res1.data.player_embed_url,
          thumbnail: res1.data.pictures.base_link
        }
        setVideo1({
          id: video1Id,
          ...video1Obj
        })

        // then fetch video 2 info
        if (video2Id) {
          axios.post("/api/vimeo/get-thumbnail", { vimeoId: video2Id }).then(res2 => {
            const video2Obj = {
              configuration,
              duration: res2.data.duration,
              name: res2.data.name,
              player_embed_url: res2.data.player_embed_url,
              thumbnail: res2.data.pictures.base_link
            }
            setVideo2({
              id: video2Id,
              ...video2Obj
            })
  
            // then resolve promise
            resolve({
              [video1Id]: video1Obj,
              [video2Id]: video2Obj,
            })
          }).catch(() => {
            setVideo2Error("Invalid Vimeo ID")
          })
        } else {
          setVideo2({ id: "" })
          resolve({ [video1Id]: video1Obj })
        }

      }).catch(() => {
        setVideo1Error("Invalid Vimeo ID")
        reject()
      })
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const id = exercise ? exercise.id : nanoid()

    let videos = null
    if (video1.id && video2.id) videos = await fetchVimeoInfo(video1.id, video2.id)
    if (video1.id && !video2.id) videos = await fetchVimeoInfo(video1.id)
    if (!video1.id && video2.id) videos = await fetchVimeoInfo(video2.id)

    const formValues = {
      configuration,
      name,
      coachingCues,
      videos: videos ? Object.keys(videos) : null,
    }

    handleSaveExercise(id, videos, formValues)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography id="exercise-modal-title" fontWeight="600" pb={4}>
        {`${exercise ? "Edit" : "Add New"} Exercise`}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="config-select-label">Configuration</InputLabel>
            <Select
              labelId="config-select-label"
              label="Configuration"
              onChange={onConfigurationChange}
              value={configuration}
            >
              {configurations.map((config) => (
                <MenuItem key={config} value={config}>
                  {config}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            onChange={onNameChange}
            required
            value={name}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            helperText="Place each separate coaching cue on a new line"
            label="Coaching Cues"
            placeholder="One cue per line"
            multiline
            onChange={onCoachingCuesChange}
            rows={3}
            value={coachingCues}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} mt={2}>
            <Typography id="exercise-modal-title" fontWeight="600">
              Exercise Videos
            </Typography>
            <Stack spacing={1}>
              <Typography id="exercise-modal-title">
                Video 1
              </Typography>
              <TextField
                error={!!video1Error}
                helperText={video1Error}
                label="Vimeo ID"
                onChange={onVideo1Change}
                size="small"
                value={video1.id}
                variant="outlined"
                sx={{ alignSelf: "flex-start" }}
              />
              {video1.name && (
                <Box sx={{ fontSize: ".75rem" }}>
                  <strong>Name:</strong> {video1.name}<br/>
                  <strong>Duration:</strong> {video1.duration}s<br/>
                  <strong>Thumbnail:</strong> <Link href={video1.thumbnail} target="_blank" rel="noreferrer">{video1.thumbnail}</Link><br/>
                  <strong>Embed URL:</strong> <Link href={video1.player_embed_url} target="_blank" rel="noreferrer">{video1.player_embed_url}</Link><br/>
                </Box>
              )}
            </Stack>
            <Stack spacing={1}>
              <Typography id="exercise-modal-title">
                Video 2
              </Typography>
              <TextField
                error={!!video2Error}
                helperText={video2Error}
                label="Vimeo ID"
                onChange={onVideo2Change}
                size="small"
                value={video2.id}
                variant="outlined"
                sx={{ alignSelf: "flex-start" }}
              />
              {video2.name && (
                <Box sx={{ fontSize: ".75rem" }}>
                  <strong>Name:</strong> {video2.name}<br/>
                  <strong>Duration:</strong> {video2.duration}s<br/>
                  <strong>Thumbnail:</strong> <Link href={video2.thumbnail} target="_blank" rel="noreferrer">{video2.thumbnail}</Link><br/>
                  <strong>Embed URL:</strong> <Link href={video2.player_embed_url} target="_blank" rel="noreferrer">{video2.player_embed_url}</Link><br/>
                </Box>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            mt={4}
            type="submit"
            variant="contained"
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            Save Exercise
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ExerciseForm
