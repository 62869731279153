import { Box } from "@mui/material"
import React, { useState, useEffect } from "react"

const PrivacyPolicyPage = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null)

  useEffect(() => {
    fetch("https://www.iubenda.com/api/privacy-policy/96556172")
      .then(response => response.json())
      .then(data => setPrivacyPolicy(data))
  }, [])

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <Box mb={8} mt={2}>
        {privacyPolicy && (
          <div className="privacy-policy" dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}></div>
        )}
      </Box>
    </Box>
  )
}
 
export default PrivacyPolicyPage
