import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { DateTime } from "luxon"
import axios from "axios"
import PasswordStrengthBar from "react-password-strength-bar"

import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material"

import "../app/firebase"
import { AlertContext } from "../app/contexts/AlertContext"
import { UserContext } from "../app/contexts/UserContext"

import StyledTextField from "../common/mui/StyledTextField.component"

const SignUp = () => {
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)
  const { setSignInSignUpPopup } = useContext(UserContext)

  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [fnameError, setFnameError] = useState("")
  const [lnameError, setLnameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const onFnameChange = (e) => {
    setFnameError("")
    setFname(e.target.value)
  }
  const onLnameChange = (e) => {
    setLnameError("")
    setLname(e.target.value)
  }
  const onEmailChange = (e) => {
    setEmailError("")
    setEmail(e.target.value)
  }
  const onPasswordChange = (e) => {
    setPasswordError("")
    setPassword(e.target.value)
  }
  const onConfirmPasswordChange = (e) => {
    setPasswordError("")
    setConfirmPassword(e.target.value)
  }

  const signUserIn = (email, password) => {
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setSignInSignUpPopup(false)
        setAlert({
          message: "Welcome to the Flyweight App!",
          severity: "error",
        })
        navigate("/")
      })
      .catch(() => {
        // unable to sign user in
        setAlert({
          message: "Welcome to the Flyweight App! Please sign in to continue.",
          severity: "error",
        })
        navigate("/signin")
      })
  }

  const addToDatabase = (userId, dbObj) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users/add", {
          userId,
          dbObj,
        })
        .then(() => {
          resolve()
        })
        .catch((error) => {
          console.log("error creating user in database: ", error.message)
          reject()
        })
    })
  }

  const deleteAuthAccount = (email) => {
    axios
      .post("/api/firebase/auth/delete", {
        email,
      })
      .catch((error) => {
        console.log("error deleting auth acount", error.message)
      })
  }

  const createAuthAccount = (authInfo) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/firebase/auth/create", authInfo)
        .then((res) => {
          resolve({ userId: res.data })
        })
        .catch((error) => {
          console.log(
            "error creating auth account",
            error.response.data
          )
          reject(error.response.data)
        })
    })
  }

  const handleRegisterUser = (user, validPassword, code) => {
    const authObj = {
      email: user.email,
      fname: user.fname,
      lname: user.lname,
      password: validPassword,
    }
    const dbObj = {
      ...user,
      isAdmin: false,
      lastCompleted: 0,
      username: user.email,
      created: DateTime.now().setZone("America/New_York").toISO(),
      signupCode: code,
    }

    return new Promise((resolve, reject) => {
      createAuthAccount(authObj)
        .then((res) => {
          addToDatabase(res.userId, dbObj)
            .then(() => {
              resolve()
            })
            .catch(() => {
              deleteAuthAccount(user.email)
              reject()
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const handleSignUp = (e) => {
    e.preventDefault()
    let error = false

    if (!fname) {
      error = true
      setFnameError("First name is required")
    }
    if (!lname) {
      error = true
      setLnameError("Last name is required")
    }
    if (!email) {
      error = true
      setEmailError("Valid email is required")
    }
    if (!password) {
      error = true
      setPasswordError("Password is required")
    }
    if (password && password !== confirmPassword) {
      error = true
      setPasswordError("Passwords do not match")
    }

    if (!error) {
      handleRegisterUser({ fname, lname, email }, password)
        .then(() => {
          signUserIn(email, password)
        })
        .catch((regError) => {
          console.log(regError)
          let message = ""
          if (regError.code === "auth/email-already-exists")
            message =
              "Email address is already in use by another account. Please follow the 'Sign In' link and sign in with your email address and password."
          else
            message = "Error registering account. Please refresh and try again."
          setAlert({
            message,
            severity: "error",
          })
        })
    }
  }

  return (
    <Box component="form" onSubmit={handleSignUp} mt={3}>
      <Typography fontSize="1.125rem" pb={4}>
        <Link href="https://theflyweight.us" target="_blank" rel="noreferrer">
          Flyweight purchase
        </Link>{" "}
        is required to register for Fly Code. You must regiser with the same
        email address that was used for purchase.
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledTextField
            error={!!fnameError}
            helperText={fnameError}
            id="fname"
            label="First Name"
            onChange={onFnameChange}
            type="text"
            value={fname}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={!!lnameError}
            helperText={lnameError}
            id="fname"
            label="Last Name"
            onChange={onLnameChange}
            type="text"
            value={lname}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            error={!!emailError}
            helperText={emailError}
            id="email"
            label="Email"
            onChange={onEmailChange}
            type="email"
            value={email}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            <Grid item xs={10} sm={11}>
              <StyledTextField
                id="password"
                label="Password (min 6 characters)"
                onChange={onPasswordChange}
                type={showPassword ? "text" : "password"}
                value={password}
              />
              <PasswordStrengthBar password={password} />
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              sx={{ alignItems: "flex-start", display: "flex" }}
            >
              <Box sx={{ marginTop: { xs: "30px", lg: "38px" } }}>
                <Tooltip
                  title={showPassword ? "Hide password" : "Show password"}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            <Grid item xs={10} sm={11}>
              <StyledTextField
                error={!!passwordError}
                helperText={passwordError}
                id="password"
                label="Confirm Password"
                onChange={onConfirmPasswordChange}
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              sx={{ alignItems: "flex-start", display: "flex" }}
            >
              <Box sx={{ marginTop: { xs: "30px", lg: "38px" } }}>
                <Tooltip
                  title={
                    showConfirmPassword ? "Hide password" : "Show password"
                  }
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ marginTop: "2rem" }}
          >
            <Typography component="span" fontSize="1.125rem" px={3}>
              Create Account
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SignUp
