import AdminDashboardPage from "../../features/admin/dashboard/DashboardPage.component"
import AdminUsersPage from "../../features/admin/users/UsersPage.component"
import AdminUserPage from "../../features/admin/users/UserPage.component"
import AdminWorkoutsPage from "../../features/admin/workouts/WorkoutsPage.component"
import AdminEditWorkoutPage from "../../features/admin/workouts/EditWorkoutPage.component"
import AdminExercisesPage from "../../features/admin/exercises/ExercisesPage.component"
import AdminEditExercisePage from "../../features/admin/exercises/EditExercisePage.component"

const adminRoutes = [
  { path: "/admin", component: AdminDashboardPage },
  { path: "/admin/dashboard", component: AdminDashboardPage },
  { path: "/admin/users", component: AdminUsersPage },
  { path: "/admin/users/:userId", component: AdminUserPage },
  { path: "/admin/workouts", component: AdminWorkoutsPage },
  { path: "/admin/workouts/:day", component: AdminEditWorkoutPage },
  { path: "/admin/exercises", component: AdminExercisesPage },
  { path: "/admin/exercises/:exerciseId", component: AdminEditExercisePage },
]

export default adminRoutes
