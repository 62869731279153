import React, { useContext, useEffect, useState } from "react"

import { ExercisesContext } from "../../app/contexts/ExercisesContext"
import { VideosContext } from "../../app/contexts/VideosContext"

const VideosOverview = ({ reps, videos }) => {
  const { exercisesState } = useContext(ExercisesContext)
  const { videosState } = useContext(VideosContext)

  const [videosList, setVideosList] = useState([])

  useEffect(() => {
    if (exercisesState.length > 0 && videosState.length > 0) {
      setVideosList(
        videos.map(videoId => {
          const video = videosState.filter(video => video.id === videoId)[0]
          return ({
            ...video,
            parentExerciseName: exercisesState.filter(exercise => exercise.id === video.parentExerciseId)[0].name,
            reps: reps ? reps[videoId] : null
          })
        })
      )
    }
  }, [videos, reps, exercisesState, videosState])

  return (
    <div>
      {videosList.map(video => (
        <div key={video.id}>
          {video.parentExerciseName}
          {video.reps && `: ${video.reps}`}
        </div>
      ))}
    </div>
  )
}

export default VideosOverview
