import React, { useState } from "react"

import { styled } from "@mui/material/styles"
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material"

import { configVideos } from "../configurator/ConfigurationTiles.component"
import ConfigurationVideoModal from "../configurator/ConfigurationVideoModal.component"
import VideosOverview from "./VideosOverview.component"

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: "1rem",
  fontWeight: "500",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
    fontWeight: "500",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
    fontWeight: "600",
  },
}))

export const StyledBody = styled(Box, {
  shouldForwardProp: (prop) => prop !== "clamped",
})(({ theme, clamped }) => ({
  display: "-webkit-box",
  fontSize: ".875rem",
  overflow: "hidden",
  overflowWrap: "break-word",
  paddingRight: ".5rem",
  textOverflow: '""',
  WebkitBoxOrient: "vertical",
  ...(clamped ? { WebkitLineClamp: 5 } : { WebkitLineClamp: "none" }),
  whiteSpace: "pre-wrap",
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
}))

export const StyledDivider = styled(Divider)(() => ({
  backgroundColor: "#fff",
  height: "1px",
  margin: "1rem 0",
  width: "100%",
}))

const DayOverview = ({ workout, exercise, currentVideo }) => {
  const [currentConfigVideo, setCurrentConfigVideo] = useState(null)
  const [videoModalOpen, setVideoModalOpen] = useState(false)

  const handleVideoModalOpen = (configuration) => {
    const video = configVideos.filter(
      (video) => video.config === configuration
    )[0]
    setCurrentConfigVideo(video ? video.url : null)
    setVideoModalOpen(true)
  }

  const handleVideoModalClose = () => {
    setCurrentConfigVideo(null)
    setVideoModalOpen(false)
  }

  return (
    <Stack
      sx={{
        alignSelf: { xs: "stretch", sm: "center", md: "flex-start" },
        marginTop: { xs: 3, md: 2, lg: 3 },
        minWidth: { sm: "335px", lg: "370px" },
      }}
    >
      <StyledHeader component="h1" variant="h1">
        Day {workout.day}
      </StyledHeader>
      {workout.videos && (
        <>
          <StyledDivider />
          <StyledBody>
            <VideosOverview
              reps={workout.reps || null}
              videos={workout.videos}
            />
          </StyledBody>
        </>
      )}
      {workout.description && (
        <>
          <StyledDivider />
          <StyledBody>{workout.description}</StyledBody>
        </>
      )}
      {exercise && (
        <>
          <StyledDivider />
          <Typography
            sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
          >
            {exercise.name}
            {workout.reps &&
              workout.reps[currentVideo.id] &&
              `: ${workout.reps[currentVideo.id]}`}
          </Typography>
        </>
      )}
      {exercise?.configuration && (
        <>
          <Tooltip title="Watch a how-to video">
            <Typography
              component="span"
              onClick={() => handleVideoModalOpen(exercise.configuration)}
              sx={{
                alignSelf: "flex-start",
                color: "primary.main",
                cursor: "pointer",
                transition: "color .2s",
                "&:hover": { color: "primary.dark" },
              }}
            >
              Configuration: {exercise.configuration}
            </Typography>
          </Tooltip>
          <ConfigurationVideoModal
            videoModalOpen={videoModalOpen}
            handleVideoModalClose={handleVideoModalClose}
            video={currentConfigVideo}
          />
        </>
      )}
    </Stack>
  )
}

export default DayOverview
