import React, { useContext, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"

import { Box, Grid, Link, Typography } from "@mui/material"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"

import { AuthContext } from "../../app/contexts/AuthContext"
import { WorkoutsContext } from "../../app/contexts/WorkoutsContext"

import logo from "../../assets/images/flycode-logo.png"
import WorkoutTiles from "./WorkoutTiles.component"
import HorizontalLevelIcon from "../../common/HorizontalLevelIcon.component"
import NextUp from "./NextUp.component"
import ReactPlayerVideoModal from "../../common/ReactPlayerVideoModal"
import { UserContext } from "../../app/contexts/UserContext"
import Breadcrumbs from "../../common/Breadcrumbs.component"

const userPDF =
  "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/files%2FFlyweight_UserGuide.pdf?alt=media&token=8bfefb26-165f-41c4-9662-8b04b8f8c5db"
const warningsPDF =
  "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/files%2FFlyweight_WarningGuide.pdf?alt=media&token=dd5fab0e-3063-433d-91a9-d9d528a27e80"
const programVideoScreenshot =
  "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/images%2Fprogram-video-screenshot.jpg?alt=media&token=10f5ae42-7a35-40a8-9ee1-27968e0135e5"

const WorkoutsPage = () => {
  const { state } = useLocation()
  const { authState } = useContext(AuthContext)
  const { userState } = useContext(UserContext)
  const { selectAllWorkouts } = useContext(WorkoutsContext)
  const workouts = selectAllWorkouts()

  const [modalOpen, setModalOpen] = useState(false)
  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs
            pages={[
              { title: "Workouts", to: "/" },
              { title: "Fly Code" },
            ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              maxWidth: { xs: "150px", sm: "200px", md: "250px" },
              width: "100%",
            }}
          />
          <Typography
            component="h2"
            sx={{
              color: "primary.main",
              fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
              fontWeight: "600",
              lineHeight: "1.25",
              marginTop: "20px",
              pb: 0.5,
              textTransform: "uppercase",
            }}
          >
            90-Day Program
          </Typography>
          <Typography fontSize="1.125rem" pb={2}>
            Get ready to sweat. Get ready for Cardio 2.0.
          </Typography>
          <Typography fontSize="1.125rem" pb={2}>
            Discover the Flyweight difference.
          </Typography>
          <Typography fontSize="1.125rem" pb={2}>
            Need help configuring your Flyweight? Check out our{" "}
            <Link component={NavLink} to="/configurator">
              product configuration guide
            </Link>
            .
          </Typography>
          <Typography fontSize="1.125rem" pb={4}>
            You must read the{" "}
            <Link component={NavLink} to="/safety-tips">
              Safety Tips
            </Link>
            ,{" "}
            <Link href={userPDF} target="_blank" rel="noreferrer">
              User Guide
            </Link>
            , and{" "}
            <Link href={warningsPDF} target="_blank" rel="noreferrer">
              Warnings Guide
            </Link>{" "}
            before operating Flyweight.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ alignItems: "center", display: "flex" }}
              >
                <HorizontalLevelIcon size={4} level={2} margin="0 8px 4px 0" />
                <Typography>Phase 1 Intensity</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ alignItems: "center", display: "flex" }}
              >
                <HorizontalLevelIcon size={4} level={3} margin="0 8px 4px 0" />
                <Typography>Phase 2 Intensity</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ alignItems: "center", display: "flex" }}
              >
                <HorizontalLevelIcon size={4} level={4} margin="0 8px 4px 0" />
                <Typography>Phase 3 Intensity</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ position: "relative" }}>
            <Box
              component="img"
              src={programVideoScreenshot}
              width="100%"
              sx={{ marginTop: "-1px" }}
            />
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                left: 0,
                position: "absolute",
                top: 0,
                width: "100%",
              }}
            >
              <PlayCircleIcon
                onClick={handleModalOpen}
                sx={{ cursor: "pointer", fontSize: "4rem" }}
              />
            </Box>
            <ReactPlayerVideoModal
              modalOpen={modalOpen}
              handleModalClose={handleModalClose}
              url="https://player.vimeo.com/video/749675914?h=0cf12b561a"
            />
          </Box>
        </Grid>
      </Grid>
      {authState.uid && userState.lastCompleted < 90 && <NextUp />}
      {workouts && (
        <WorkoutTiles
          workouts={workouts}
          unlocked={state && state.unlocked ? state.unlocked : null}
        />
      )}
    </Box>
  )
}

export default WorkoutsPage
