import React from "react"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

const StyledCueContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  paddingLeft: ".5rem",
  width: "40%",
  '@media (max-width: 400px)': {
    width: "60%",
  },
  [theme.breakpoints.up("sm")]: {
    marginTop: "4rem",
    paddingLeft: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    marginTop: "0",
    paddingLeft: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    marginTop: "0",
    paddingLeft: "2.5rem",
  },
}))

const StyledCue = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: ".875rem",
  padding: ".5rem 0",
  width: "100%",
  "& .text": {
    flexGrow: 0,
    maxWidth: "70%",
  },
  "& .pointer": {
    alignSelf: "center",
    borderBottom: "2px dotted",
    borderColor: theme.palette.primary.main,
    flexGrow: 1,
    marginLeft: ".25rem",
    marginTop: "2px",
  },
  '@media (max-width: 400px)': {
    "& .pointer": {
      display: "none",
    },
  },
  '@media (min-width: 450px)': {
    padding: "1rem 0",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: ".875rem",
    padding: "1rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
    padding: "2rem 0",
  },
}))

const CueSpacer = styled("div")(({ theme }) => ({
  height: "18px",
  padding: ".125rem 0",
  [theme.breakpoints.up("sm")]: {
    padding: ".25rem 0",
  },
  [theme.breakpoints.up("md")]: {
    padding: ".5rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "1rem 0",
  },
}))

const CoachingCues = ({ coachingCues, pointer = true }) => (
  <StyledCueContainer>
    {coachingCues.map((cue, i) => {
      if (cue) {
        return (
          <StyledCue key={i}>
            <div className="text">{cue}</div>
            {pointer && <div className="pointer" />}
          </StyledCue>
        )
      }
      return <CueSpacer key={i} />
    })}
  </StyledCueContainer>
)

export default CoachingCues
