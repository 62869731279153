import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const StyledTileContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    !["day", "nextDay"].includes(prop),
})(({ day, nextDay }) => ({
  alignItems: "flex-end",
  boxShadow:
    "0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02)",
  cursor: "pointer",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  ...(day <= nextDay
    ? {
        ".backgroundImageContainer": {
          transition: "transform .5s",
        },
        "&:hover": {
          ".backgroundImageContainer": {
            transform: "scale(1.2)",
          },
        },
      }
    : {}),
}))

export const StyledBackgroundImageContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    !["day", "nextDay", "unlocked"].includes(prop),
})(({ day, getExerciseCover, nextDay, videos }) => ({
  background: `url(${getExerciseCover(videos ? videos[0] : "")})`,
  backgroundRepeat: "",
  backgroundPosition: "center top 25%",
  backgroundSize: "cover",
  height: "100%",
  width: "100%",
  "&::before": {
    content: '""',
    background: "rgba(0, 0, 0, 0.8)",
    position: "absolute",
    inset: "0px",
    ...(day <= nextDay
      ? {
          background: "rgba(0, 0, 0, 0.3)",
        }
      : {}),
  },
}))

export const phaseBoxStyles = {
  alignItems: "center",
  background: "white",
  color: "#121212",
  display: "flex",
  padding: "6px 12px",
  position: "absolute",
  left: 0,
  top: 0,
}

export const lockIconStyles = {
  color: "text.secondary",
  fontSize: { xs: "1.25rem", sm: "2rem" },
  opacity: 0.8,
}

export const checkIconStyles = {
  ...lockIconStyles,
  color: "primary.main",
}

export const StyledTextContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    !["day", "nextDay", "unlocked"].includes(prop),
})(({ theme, day, nextDay }) => ({
  maxWidth: "75%",
  padding: "0.5rem 1rem",
  position: "absolute",
  textAlign: "center",
  ...(day <= nextDay
    ? {
        color: "text.primary",
      }
    : {
        color: "text.disabled",
      }),
  [theme.breakpoints.up("sm")]: {
    padding: "1rem 2rem",
  },
}))

export const StyledTileText = styled("span")(({ theme }) => ({
  color: "white",
  display: "inline-block",
  fontSize: "1rem",
  fontWeight: "600",
  letterSpacing: ".2rem",
  marginLeft: ".5rem",
  textShadow: "1px 1px 3px #000",
  textTransform: "uppercase",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.25rem",
  },
}))
