import React, { createContext, useState } from "react"
import { getDatabase, ref, get } from "firebase/database"

import placeholder from "../../assets/images/placeholder.jpg"

export const ExercisesContext = createContext()

const initialState = []

export const ExercisesProvider = (props) => {
  const database = getDatabase()
  const [exercisesState, setExercisesState] = useState(initialState)

  const startSetExercises = () => {
    return new Promise((resolve) => {
      get(ref(database, "exercises/"))
        .then((snap) => {
          const dataArray = []
          if (snap.exists()) {
            for (const [key, value] of Object.entries(snap.val())) {
              dataArray.push({
                id: key,
                ...value,
              })
            }
          }
          setExercisesState(dataArray.sort((a, b) => a.day > b.day))
          resolve(true)
        })
        .catch((error) => {
          console.error(error)
          resolve([])
        })
    })
  }

  const addExercise = (exercise) =>
    setExercisesState((prevState) => [...prevState, exercise])

  const updateExercise = (exercise) => {
    setExercisesState((prevState) => [
      ...prevState.map((prevExercise) => {
        if (prevExercise.id !== exercise.id) {
          return prevExercise
        } else {
          return {
            ...prevExercise,
            ...exercise,
          }
        }
      }),
    ])
  }

  const removeExercise = (id) => {
    setExercisesState((prevState) =>
      prevState.filter((prevExercise) => prevExercise.id !== id)
    )
  }

  const selectAllExercises = () => exercisesState

  const selectExerciseById = (id) =>
    exercisesState.filter((exercise) => exercise.id === id)[0]

  const selectExercisesByIds = (idArray) =>
    exercisesState.filter((exercise) => idArray.includes(exercise.id))

  const selectExerciseByConfig = (configuration) =>
    exercisesState.filter(
      (exercise) => exercise.configuration === configuration
    )
  
  const selectThumbnailById = (id) => {
    const videos = exercisesState.filter((exercise) => exercise.id === id)[0]?.videos
    if (videos) return videos[Object.keys(videos)[0]].thumbnail
    return placeholder
  }

  return (
    <ExercisesContext.Provider
      value={{
        exercisesState,
        startSetExercises,
        addExercise,
        updateExercise,
        removeExercise,
        selectAllExercises,
        selectExerciseById,
        selectExercisesByIds,
        selectExerciseByConfig,
        selectThumbnailById,
      }}
    >
      {props.children}
    </ExercisesContext.Provider>
  )
}

export default ExercisesProvider
