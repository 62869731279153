import { useContext } from "react"
import { Navigate } from "react-router-dom"
import { UserContext } from "../contexts/UserContext"

function RequireAdmin({ children }) {
  const { userState } = useContext(UserContext)

  if (!userState.isAdmin) {
    return <Navigate to="/" />
  }

  return children
}

export default RequireAdmin
