import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import axios from "axios"

import { Box, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { UserContext } from "../../../app/contexts/UserContext"
import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"

import WorkoutForm from "./WorkoutForm.component"

const style = {
  bgcolor: "background.paper",
  border: "1px solid",
  borderColor: "grey.400",
  boxShadow: 24,
  left: "50%",
  maxHeight: "90vh",
  maxWidth: "800px",
  overflowY: "auto",
  padding: { xs: 2, sm: 4, md: 6 },
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const AddWorkoutModal = ({ modalOpen, handleCloseModal }) => {
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)
  const { selectUid } = useContext(UserContext)
  const { addWorkout } = useContext(WorkoutsContext)

  const handleSaveWorkout = (id, formValues) => {
    const created = DateTime.now().setZone("America/New_York").toISO()
    const createdBy = selectUid()
    const dbObj = {
      ...formValues,
      created,
      createdBy,
      lastUpdated: created,
      lastUpdatedBy: createdBy,
    }

    axios
      .post("/api/workouts/add", {
        id,
        dbObj,
      })
      .then((res) => {
        // Add new workout to store
        addWorkout({ id, ...dbObj })
        setAlert({
          message: "Workout added",
          severity: "success",
        })
        handleCloseModal()
        navigate(`/admin/workouts/${dbObj.day}`)
      })
      .catch((error) => {
        console.log("error creating workout in database: ", error.message)
        setAlert({
          message: `Error creating workout: ${error.message}`,
          severity: "error",
        })
      })
  }

  return (
    <Modal
      onClose={handleCloseModal}
      open={modalOpen}
      aria-labelledby="workout-modal-title"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: { xs: 2, sm: 8 },
            right: { xs: 2, sm: 8 },
          }}
        >
          <CloseIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
        <WorkoutForm
          handleSaveWorkout={handleSaveWorkout}
        />
      </Box>
    </Modal>
  )
}

export default AddWorkoutModal
