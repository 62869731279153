import React from 'react'

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import AgilityIcon from "@mui/icons-material/Games"
import BatteryIcon from "@mui/icons-material/BatteryChargingFull"
import BuildIcon from "@mui/icons-material/Construction"
import ExplosivenessIcon from "@mui/icons-material/Flare"
import MetabolicBurnIcon from "@mui/icons-material/LocalFireDepartment"
import PerformanceIcon from "@mui/icons-material/WorkspacePremium"
import PowerIcon from "@mui/icons-material/Bolt"
import SkillBuildingIcon from "@mui/icons-material/Insights"
import SpeedIcon from "@mui/icons-material/Speed"

const iconStyles = { color: "primary.main", fontSize: "1.25rem", mr: 1 }
const icons = [
  { title: "Agility", icon: <AgilityIcon sx={iconStyles} /> },
  { title: "Battery", icon: <BatteryIcon sx={iconStyles} /> },
  { title: "Build", icon: <BuildIcon sx={iconStyles} /> },
  { title: "Explosiveness", icon: <ExplosivenessIcon sx={iconStyles} /> },
  { title: "Metabolic Burn", icon: <MetabolicBurnIcon sx={iconStyles} /> },
  { title: "Performance", icon: <PerformanceIcon sx={iconStyles} /> },
  { title: "Power", icon: <PowerIcon sx={iconStyles} /> },
  { title: "Skill Building", icon: <SkillBuildingIcon sx={iconStyles} /> },
  { title: "Speed", icon: <SpeedIcon sx={iconStyles} /> },
]

const IconSelector = ({ icon, onIconChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="icon-select-label">Icon</InputLabel>
      <Select
        labelId="icon-select-label"
        label="Icon"
        onChange={onIconChange}
        value={icon}
      >
        {icons.map((item) => (
          <MenuItem key={item.title} value={item.title}>
            {item.icon}{item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
 
export default IconSelector;