import React, { useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"

import { styled } from "@mui/material/styles"
import { Box, Grid, Link, Paper, Skeleton, Stack, Typography } from "@mui/material"

import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"
import { VideosContext } from "../../../app/contexts/VideosContext"

const StyledThumbnail = styled("img")(() => ({
  maxWidth: "450px",
  width: "100%",
}))

const CoverImage = ({ workout }) => {
  const { selectThumbnailUrl } = useContext(VideosContext)
  const thumbnailUrl = selectThumbnailUrl(workout.videos ? workout.videos[0] : "")

  return (
    <Link href={thumbnailUrl} target="_blank" rel="noreferrer">
      <StyledThumbnail
        src={thumbnailUrl}
        alt="thumbnail"
      />
    </Link>
  )
}

const WorkoutOverview = ({ workout }) => {
  const { selectUser } = useContext(AdminUsersContext)

  const [lastUpdatedBy, setLastUpdatedBy] = useState(null)

  useEffect(() => {
    if (workout && workout.lastUpdatedBy)
      setLastUpdatedBy(selectUser(workout.lastUpdatedBy))
  }, [workout, selectUser])

  return (
    <Box component={Paper} sx={{ padding: { xs: 2, md: 4 } }}>
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: {
            xs: "column-reverse",
            sm: "row",
            md: "column-reverse",
            lg: "row",
          },
        }}
      >
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Stack spacing={2}>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                Completions
              </Typography>
              {workout ? (
                <Typography component="span">0</Typography>
              ) : (
                <Skeleton sx={{ maxWidth: "200px" }} />
              )}
            </Stack>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                Most recent completion
              </Typography>
              {workout ? (
                <Typography component="span">Justin Spegele</Typography>
              ) : (
                <Skeleton sx={{ maxWidth: "400px" }} />
              )}
            </Stack>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                Last Updated
              </Typography>
              {workout && workout.lastUpdated ? (
                <Typography component="span">
                  {DateTime.fromISO(workout.lastUpdated).toLocaleString(
                    DateTime.DATETIME_FULL
                  )}
                </Typography>
              ) : (
                <Skeleton sx={{ maxWidth: "200px" }} />
              )}
            </Stack>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                Last Updated By
              </Typography>
              {lastUpdatedBy ? (
                <Typography component="span">
                  {`${lastUpdatedBy.fname} ${lastUpdatedBy.lname}`}
                </Typography>
              ) : (
                <Skeleton sx={{ maxWidth: "200px" }} />
              )}
            </Stack>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                Database Id:
              </Typography>
              {workout.id}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <CoverImage workout={workout} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default WorkoutOverview
