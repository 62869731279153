import { useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"

function RequireAnon({ children }) {
  const { authState } = useContext(AuthContext)

  if (authState.uid) {
    return <Navigate to="/" />
  }

  return children
}

export default RequireAnon
