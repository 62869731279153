import React from "react"
import { PropTypes } from "prop-types"

import { Box } from "@mui/material"

const HorizontalLevelIcon = ({ size, level, margin, dark }) => {
  const bars = []
  for (let i = 1; i <= size; i++) bars.push(i)

  return (
    <Box sx={{ alignItems: "flex-end", display: "flex", margin }}>
      {bars.map((box, i) => (
        <Box
          key={i}
          sx={{
            ...(box <= level
              ? { backgroundColor: dark ? "rgba(0, 0, 0, .87)" : "text.primary" }
              : { backgroundColor: dark ? "rgba(0, 0, 0, .38)" : "text.disabled" }),
            height: `calc(3px + 3px * ${box})`,
            ...(i > 0 && { marginLeft: "2px" }),
            width: "2px",
          }}
        />
      ))}
    </Box>
  )
}

HorizontalLevelIcon.defaultProps = {
  size: 4,
  level: 1,
  margin: "0 0 2px 0",
  dark: false,
}

HorizontalLevelIcon.propTypes = {
  size: PropTypes.number,
  level: PropTypes.number,
  mb: PropTypes.string,
  theme: PropTypes.string,
}

export default HorizontalLevelIcon
