import React from "react"

import { Typography } from "@mui/material"

const HomePage = () => {
  return (
    <Typography component="h1" variant="h5">404: Page Not Found</Typography>
  )
}
 
export default HomePage
