import React from "react"
import { Box, Typography } from "@mui/material"
import RxIcon from "../../common/mui/RxIcon"

const VideoOverlayHead = ({ workout, exercise, video }) => {
  return (
    <Box
      sx={{ padding: { xs: "2rem .5rem", sm: "2rem 1rem", lg: "2.5rem" } }}
    >
      <Typography
        sx={{ fontSize: { xs: ".875rem", sm: "1rem", fontWeight: "600" } }}
      >
        Day {workout.day} Exercise {workout.videos.indexOf(video.id) + 1} of{" "}
        {workout.videos.length}
      </Typography>
      <Typography
        sx={{ fontSize: { xs: "1.25rem", sm: "2rem", fontWeight: "600" } }}
      >
        {exercise.name}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "1.25rem",
            md: "2rem",
            fontWeight: "600",
            lineHeight: 1,
          },
        }}
      >
        <RxIcon viewBox="0 0 448.1 512.083" sx={{ color: "primary.main", fontSize: 22, mr: 1 }} />
        {workout.reps && workout.reps[video.id]}
      </Typography>
    </Box>
  )
}

export default VideoOverlayHead
