import WorkoutPage from "../../features/Workout/WorkoutPage.component"
import ChangePasswordPage from "../../features/ChangePasswordPage.component"
import SafetyCertificationPage from "../../features/safety/SafetyCertificationPage.component"

const privateRoutes = [
  { path: "/fly-code/day/:day", component: WorkoutPage },
  { path: "/workouts/day/:day", component: WorkoutPage },
  { path: "/change-password", component: ChangePasswordPage },
  { path: "/safety-certification", component: SafetyCertificationPage },
]

export default privateRoutes
