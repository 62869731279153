import React, { useContext } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { Box, Link, Stack, Tooltip, Typography } from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"
import CheckIcon from "@mui/icons-material/Check"

import { UserContext } from "../../app/contexts/UserContext"

import HorizontalLevelIcon from "../../common/HorizontalLevelIcon.component"

import {
  lockIconStyles,
  checkIconStyles,
  StyledTileText,
} from "./PreviewTiles.styles"
import { getIcon } from "./DesktopTile.component"

const MobileTile = ({
  day,
  getExerciseCover,
  icon,
  lastCompleted,
  nextDay,
  videos,
}) => {
  const navigate = useNavigate()
  const { setSignInSignUpPopup } = useContext(UserContext)

  // Temp code for random icons
  const names = ["Agility", "Battery", "Build", "Explosiveness", "Metabolic Burn", "Performance", "Power", "Skill Building", "Speed"]
  const iconName = icon || names[Math.floor(Math.random() * names.length)]

  const handleNavigateToWorkout = () => navigate(`/preview/day/${day}`)
  const handleOpenLoginPopup = () => setSignInSignUpPopup(true)

  const RenderContents = () => (
    <>
      <Box sx={{ minWidth: "125px", width: "40%" }}>
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            src={getExerciseCover(videos ? videos[0] : "")}
            sx={{ width: "100%" }}
          />
          <Box
            sx={{
              background: "rgba(0, 0, 0, 0.8)",
              height: "100%",
              left: 0,
              p: 1,
              position: "absolute",
              textAlign: "right",
              top: 0,
              width: "100%",
              ...(day <= nextDay
                ? {
                    background: "rgba(0, 0, 0, 0.3)",
                  }
                : {}),
            }}
          >
            {!(day <= nextDay) && <LockIcon sx={lockIconStyles} />}
            {day && day <= lastCompleted && <CheckIcon sx={checkIconStyles} />}
          </Box>
        </Box>
      </Box>
      <Stack>
        <Stack alignItems="center" direction="row" pb={2}>
          {iconName && (
            <Tooltip title={iconName}>
              {getIcon(iconName)}
            </Tooltip>
          )}
          <StyledTileText>Day {day}</StyledTileText>
        </Stack>
        <Stack alignItems="center" direction="row">
          <HorizontalLevelIcon
            level={day <= 30 ? 2 : day <= 60 ? 3 : 4}
            margin="0 12px 3px 6px"
          />
          <Typography color="text.primary">
            Phase {day <= 30 ? "1" : day <= 60 ? "2" : "3"}
          </Typography>
        </Stack>
      </Stack>
    </>
  )

  return (
    <Box
      onClick={
        day <= nextDay ? handleNavigateToWorkout : handleOpenLoginPopup
      }
      sx={{
        cursor: "pointer",
        display: "flex",
        marginLeft: "-1.5rem",
        marginTop: "-1.5rem",
        textDecoration: "none",
        "& > *": { paddingLeft: "1.5rem", paddingTop: "1.5rem" },
      }}
    >
      <RenderContents />
    </Box>
  )
}

export default MobileTile
