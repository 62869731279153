import React, { createRef, useContext, useState } from "react"
import { useParams } from "react-router-dom"

import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"

import { UserContext } from "../../app/contexts/UserContext"

import Breadcrumbs from "../../common/Breadcrumbs.component"
import TimerContainer from "../Workout/TimerContainer.component"
import ChallengeVideo from "./ChallengeVideo.component"
import DifficultyStars from "./DifficultyStars.component"

import liveWorkout1Img from "../../assets/images/on-the-fly-f1-thumb.jpg"
import liveWorkout2Img from "../../assets/images/on-the-fly-f2-thumb.jpg"
import liveWorkout3Img from "../../assets/images/on-the-fly-f3-thumb.jpg"
import liveWorkout5Img from "../../assets/images/on-the-fly-f5-thumb.jpg"

export const onTheFlyChallenges = [
  {
    description:
      "Complete the three-round circuit without putting Flyweight down.",
    difficulty: 4,
    id: "f1",
    image: liveWorkout1Img,
    player_embed_url: "https://player.vimeo.com/video/815879067?h=230e5d0bdd",
    reps: "30 Windshield Wipers (decreasing to 20 and then 15 reps for subsequent sets)\n30 Power Pulls (decreasing to 20 and then 15 reps for subsequent sets)\n20 Rows (decreasing to 15 and then 10 reps for subsequent sets)",
    title: "F1 Challenge",
  },
  {
    description:
      "Complete three sets as a circuit with 60 seconds rest between each set.",
    difficulty: 3,
    id: "f2",
    image: liveWorkout2Img,
    player_embed_url: "https://player.vimeo.com/video/815880141?h=ea0d53c8a6",
    reps: "60 seconds of Skiing\n20 High Pulls\n10 Reverse Fly",
    title: "F2 Challenge",
  },
  {
    description:
      "Complete three sets as a circuit with 30 seconds rest between each set.",
    difficulty: 3,
    id: "f3",
    image: liveWorkout3Img,
    player_embed_url: "https://player.vimeo.com/video/745203407?h=8a393cc455",
    reps: "20 Slams\n20 Underhand Tosses\n20 Side Tosses /side",
    title: "F3 Challenge",
  },
  {
    description: "Complete 3 sets, resting as needed.",
    difficulty: 5,
    id: "f5",
    image: liveWorkout5Img,
    player_embed_url: "https://player.vimeo.com/video/815886624?h=65f80a58c3",
    reps: "20 Fireball Throws on each side\n20 Hip Tosses on each side\n20 Wall Tosses\n30 Piston Pushes\n20 Squat Presses",
    title: "F5 Challenge",
  },
]

const ChallengesPage = () => {
  const { id } = useParams()
  const isMedium = useMediaQuery("(min-width: 900px)")
  const { uid } = useContext(UserContext)
  const challenge = onTheFlyChallenges.filter((item) => item.id === id)[0]

  const [pauseAll, setPauseAll] = useState(true)
  const [timer, setTimer] = useState(0)
  const [restTimer, setRestTimer] = useState(0)
  const [timerPaused, setTimerPaused] = useState(true)
  const [gifPaused, setGifPaused] = useState(true)

  const playerRef = createRef()

  const handlePause = () => {
    if (gifPaused || timerPaused) {
      setPauseAll(false)
      setGifPaused(false)
      setTimerPaused(false)
    } else {
      setGifPaused(true)
      setTimerPaused(true)
    }
  }

  const resetTimer = () => {
    if (timerPaused) {
      setRestTimer(0)
      return
    }
    setTimerPaused(false)
    setGifPaused(false)
    setTimer(0)
    playerRef.current.seekTo(0)
  }

  return (
    <Grid container alignSelf="flex-start">
      <Grid item xs={12}>
        <Breadcrumbs
          pages={[{ title: "Workouts", to: "/" }, { title: challenge.title }]}
        />
      </Grid>
      <Grid item xs={12}>
        <ChallengeVideo
          handlePause={handlePause}
          gifPaused={gifPaused}
          challenge={challenge}
          playerRef={playerRef}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack
          alignItems={isMedium ? "flex-start" : "center"}
          direction={isMedium ? "row" : "column"}
          justifyContent={isMedium ? "center" : "flex-start"}
          mt={isMedium ? 4 : 0.5}
          spacing={isMedium ? 6 : 2}
        >
          {uid && (
            <Box alignSelf="center">
              <TimerContainer
                pauseAll={pauseAll}
                timer={timer}
                setTimer={setTimer}
                restTimer={restTimer}
                setRestTimer={setRestTimer}
                timerPaused={timerPaused}
                setTimerPaused={setTimerPaused}
                setGifPaused={setGifPaused}
                resetTimer={resetTimer}
              />
            </Box>
          )}
          <Stack
            sx={{
              alignSelf: { xs: "stretch", sm: "center", md: "flex-start" },
              marginTop: { xs: 3, md: 2, lg: 3 },
            }}
          >
            <Typography
              component="h1"
              variant="h1"
              sx={{
                alignItems: "center",
                display: "flex",
                fontSize: { xs: "1.25rem", md: "1.5rem", lg: "2rem" },
                fontWeight: { xs: "500", md: "600" },
                justifyContent: "space-between",
                minWidth: { xs: "300px", md: "400px" }
              }}
            >
              {challenge.title}
              <DifficultyStars difficulty={challenge.difficulty} />
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography pb={1}>{challenge.description}</Typography>
            <Typography whiteSpace="pre-wrap">{challenge.reps}</Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ChallengesPage
