import React, { useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"

import CustomizedTheme from "../app/CustomizedTheme"
import CssBaseline from "@mui/material/CssBaseline"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

import Header from "./Header.component"
import AlertStack from "./AlertStack.component"
import PreviewCode from "../features/Preview/PreviewCode.component"
import SignInSignUpModal from "./SignInSignUpModal.component"

export const StyledBackground = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "calc(100vh - 72px)",
  minWidth: "320px",
  padding: ".5rem",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    padding: "1rem",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "1.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "2rem",
  },
}))

const StyledMain = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: "1200px",
  width: "100%",
}))

const LoginLayout = () => {
  const location = useLocation()
  const [signin, setSignIn] = useState(false)
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    const signinPaths = [
      "/fly-code",
      "/workouts",
      "/otf/f1",
      "/otf/f2",
      "/otf/f3",
      "/otf/f5",
    ]
    const previewPaths = [
      "/preview",
      "/preview/day/1",
      "/preview/day/2",
      "/preview/day/3",
    ]
    if (signinPaths.includes(location.pathname)) setSignIn(true)
    if (previewPaths.includes(location.pathname)) setPreview(true)
  }, [location.pathname])

  return (
    <CustomizedTheme>
      <CssBaseline />
      <StyledBackground>
        {preview && <PreviewCode />}
        {signin && <SignInSignUpModal />}
        <StyledMain>
          <Header preview={preview} />
          <Box display="flex" flexGrow="1">
            <Outlet />
          </Box>
          {/* <Footer /> */}
        </StyledMain>
        <AlertStack />
      </StyledBackground>
    </CustomizedTheme>
  )
}

export default LoginLayout
