import React from "react"
import { Outlet } from "react-router-dom"

import AdminTheme from "./AdminTheme"
import CssBaseline from "@mui/material/CssBaseline"
import { styled } from "@mui/material/styles"
import { Box, Container } from "@mui/material"

import AdminAppBar from "./AdminAppBar.component"
// import Footer from "../../common/Footer.component"
import AlertStack from "../../common/AlertStack.component"

const StyledMain = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  minHeight: "calc(100vh - 72px)",
  width: "100%",
}))

const AdminLayout = () => {
  return (
    <AdminTheme>
      <CssBaseline />
      <StyledMain>
        <AdminAppBar />
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            pt: { xs: 2, md: 4 },
          }}
        >
          <Box display="flex" flexDirection="column" flexGrow="1">
            <Outlet />
          </Box>
          {/* <Footer /> */}
        </Container>
      </StyledMain>
      <AlertStack />
    </AdminTheme>
  )
}

export default AdminLayout
