import React from "react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Modal from "@mui/material/Modal"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import PersonIcon from "@mui/icons-material/Person"

const style = {
  bgcolor: 'background.paper',
  border: '1px solid #666',
  borderRadius: '5px',
  boxShadow: 24,
  left: '50%',
  maxHeight: '100vh',
  maxWidth: '500px',
  overflow: 'auto',
  px: 3,
  py: 2,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%'
}

const ConfirmDeleteModal = ({ modalOpen, handleCloseModal, handleDeleteExercises, exercises, selected }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
    >
      <Box sx={style}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography component="h2" variant="h6" id="modal-title">
            Confirm Action
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Typography paddingBottom={2}>
            You are about to delete {selected.length} exercise{selected.length > 1 && 's'}.
            This process is irreversible.
          </Typography>
          <Typography paddingBottom={2}>Do you want to proceed?</Typography>
          <Typography>
            Exercises to be deleted:
          </Typography>
          <List>
            {exercises
              .filter(exercise => selected.includes(exercise.id))
              .map(exercise => (
                <ListItem disablePadding key={exercise.id}>
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={exercise.name} />
                </ListItem>
              ))
            }
          </List>
        </Box>
        <Box display="flex" justifyContent="flex-end" paddingTop={4}>
          <Stack spacing={2} direction="row">
            <Button
              onClick={handleCloseModal}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteExercises}
              color="error"
              type="submit"
              variant="contained"
            >
              Delete Exercise{selected.length > 1 && 's'}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}
 
export default ConfirmDeleteModal
