import React, { useContext, useState } from "react"

import { Typography } from "@mui/material"

import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"

import Breadcrumbs from "../../../common/Breadcrumbs.component"
import UsersTable from "./UsersTable.component"
import UsersExport from "./UsersExport.component"

const UsersPage = () => {
  const { selectUsers } = useContext(AdminUsersContext)
  const users = selectUsers()
  const [search, setSearch] = useState("")

  const getVisibleUsers = (allUsers) => {
    return allUsers.filter((user) => {
      return (
        user.fname?.toLowerCase().includes(search.toLowerCase()) ||
        user.lname?.toLowerCase().includes(search.toLowerCase()) ||
        user.email?.toLowerCase().includes(search.toLowerCase())
      )
    }).sort((a, b) => a.lname > b.lname)
  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Users" },
        ]}
      />
      <Typography component="h1" variant="h4" pb={4}>
        Manage Users
      </Typography>
      {users && (
        <>
          <UsersTable
            users={getVisibleUsers(users)}
            search={search}
            setSearch={setSearch}
          />
          <UsersExport users={users} />
        </>
      )}
    </>
  )
}

export default UsersPage
