import React, { useState } from "react"

import {
  Box,
  Button,
  Typography,
} from "@mui/material"

import SelectConfiguration from "./SelectConfiguration.component"
import ExerciseSelector from "./ExerciseSelector.component"

const SelectExistingExercise = ({ defaultConfiguration, videoList, handleAddToWorkout }) => {
  const [configuration, setConfiguration] = useState(defaultConfiguration)
  const [selected, setSelected] = useState([])

  const handleClick = () => {
    handleAddToWorkout(selected)
    setSelected([])
  }

  return (
    <Box pb={2}>
      <Typography id="exercise-modal-title" fontWeight="600" pb={2}>
        Add existing exercises to this workout
      </Typography>
      <SelectConfiguration
        configuration={configuration}
        setConfiguration={setConfiguration}
        size="small"
      />
      {configuration && (
        <>
          <ExerciseSelector
            videoList={videoList}
            configuration={configuration}
            selected={selected}
            setSelected={setSelected}
          />
          <Button
            disabled={selected.length === 0}
            onClick={handleClick}
            variant="contained"
          >
            + Add to workout
          </Button>
        </>
      )}
    </Box>
  )
}

export default SelectExistingExercise
