import React from "react"
import { Link } from "react-router-dom"

import { Box, Typography, useMediaQuery } from "@mui/material"

import {
  containerStyle,
  overlayStyle,
  textBoxStyle,
  h1Style,
  h2Style,
  frameStyle,
} from "./HeroVideo.styles"

const landscapeVideo = "https://player.vimeo.com/video/815747625?h=a583462c01&background=1&autoplay=1&badge=0&autopause=0&player_id=0&app_id=58479"
const portraitVideo = "https://player.vimeo.com/video/815754035?h=460daf0b63&background=1&autoplay=1&badge=0&autopause=0&player_id=0&app_id=58479"

const HeroVideo = () => {
  const isMobile = useMediaQuery(
    "(max-width:600px) and (orientation: portrait)"
  )

  return (
    <Link to="/fly-code">
      <Box id="video-container" sx={containerStyle}>
        <Box sx={overlayStyle}></Box>
        <Box sx={textBoxStyle}>
          <Typography component="h1" sx={h1Style}>
            Flyweight Workouts
          </Typography>
          <Typography component="h2" sx={h2Style}>
            Get ready to sweat. Get ready for Cardio 2.0.
          </Typography>
        </Box>
        <Box sx={frameStyle}>
          <iframe
            src={isMobile ? portraitVideo : landscapeVideo}
            allowFullScreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            title="Flyweight background video"
          ></iframe>
        </Box>
      </Box>
    </Link>
  )
}

export default HeroVideo
