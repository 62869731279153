import React from "react"
import { Outlet } from "react-router-dom"

import CustomizedTheme from "../app/CustomizedTheme"
import CssBaseline from "@mui/material/CssBaseline"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

import heroMobile from "../assets/images/login-bg600-portrait.jpg"
import heroXs from "../assets/images/login-bg600.jpg"
import heroSm from "../assets/images/login-bg900.jpg"
import heroMd from "../assets/images/login-bg1200.jpg"
import heroLg from "../assets/images/login-bg1600.jpg"

import Header from "./Header.component"
// import Footer from "./Footer.component"
import AlertStack from "./AlertStack.component"

const StyledBackground = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${heroXs})`,
  backgroundSize: "contain",
  WebkitBackgroundSize: "cover",
  MozBackgroundSize: "cover",
  OBackgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  minHeight: "calc(100vh - 72px)",
  padding: ".5rem",
  width: "100%",
  "@media (orientation : portrait)": {
    backgroundImage: `url(${heroMobile})`,
  },
  [theme.breakpoints.up("sm")]: {
    backgroundImage: `url(${heroSm})`,
    padding: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${heroMd})`,
  },
  [theme.breakpoints.up("lg")]: {
    backgroundImage: `url(${heroLg})`,
    padding: "1.5rem",
  },
  // [theme.breakpoints.up("xl")]: {
  //   backgroundImage: `url(${heroXl})`,
  //   padding: "2rem",
  // },
}))

const StyledMain = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: "1200px",
  width: "100%",
}))

const LoginLayout = () => {
  return (
    <CustomizedTheme>
      <CssBaseline />
      <StyledBackground>
        <StyledMain>
          <Header />
          <Box alignItems="center" display="flex" flexGrow="1">
            <Outlet />
          </Box>
          {/* <Footer /> */}
        </StyledMain>
        <AlertStack />
      </StyledBackground>
    </CustomizedTheme>
  )
}

export default LoginLayout
