import React, { useContext, useState } from "react"

import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"

import { ExercisesContext } from "../../../app/contexts/ExercisesContext"

const ExerciseSelector = ({ videoList, configuration, selected, setSelected, maxHeight = "40vh" }) => {
  const isSmall = useMediaQuery("(min-width: 600px")
  const isMedium = useMediaQuery("(min-width: 900px")
  const { selectExerciseById } = useContext(ExercisesContext)

  const [filter, setFilter] = useState("")
  const onFilterChange = (e) => setFilter(e.target.value)

  const selectVisibleVideos = () => {
    return videoList.filter(video => {
      const nameMatch = video.name?.toLowerCase().includes(filter.toLowerCase())
      const parentNameMatch = video.parentName?.toLowerCase().includes(filter.toLowerCase())
      const configMatch = video.config?.toLowerCase().includes(filter.toLowerCase())

      return nameMatch || parentNameMatch || configMatch
    })
  }

  const handleClick = (videoId) => () => {
    if (selected.includes(videoId)) {
      setSelected((prevState) => prevState.filter((id) => id !== videoId))
      return
    }

    setSelected((prevState) => [...prevState, videoId])
  }

  return (
    <Box mt={2}>
      <TextField
        fullWidth
        label="Filter Videos"
        onChange={onFilterChange}
        size="small"
        value={filter}
        variant="outlined"
      />
      <Box id="test" sx={{ mt: 1, maxHeight: maxHeight, overflowY: "auto" }}>
        <Typography color="text.secondary" fontSize=".875rem" mb={-2}>
          Select one or more gifs
        </Typography>
        <ImageList cols={isMedium ? 4 : isSmall ? 3 : 2} sx={{ width: "100%" }}>
          {videoList.length > 0 && (
            selectVisibleVideos()
            .slice()
            .filter(video => {
              const parentExercise = selectExerciseById(video.parentExerciseId)
              return parentExercise && parentExercise.configuration === configuration
            })
            .sort((a, b) => a.parentName > b.parentName)
            .map(video => {
              const parentExercise = selectExerciseById(video.parentExerciseId)
              return (
                <ImageListItem
                  key={video.id}
                  onClick={handleClick(video.id)}
                  sx={{
                    cursor: "pointer",
                    ...(selected.includes(video.id)
                      ? {
                          ".MuiImageListItemBar-root": {
                            backgroundColor: "rgba(2, 136, 209, 0.8)",
                          },
                        }
                      : {
                          "&: hover": {
                            ".MuiImageListItemBar-root": {
                              backgroundColor: "rgba(2, 136, 209, 0.5)",
                            },
                          },
                        }),
                  }}
                >
                  <img
                    src={video.thumbnail}
                    srcSet={video.thumbnail}
                    alt={video.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={parentExercise.name}
                    subtitle={video.name}
                  />
                </ImageListItem>
              )
            })
          )}
        </ImageList>
      </Box>
      <Typography
        mt={1}
        textAlign="right"
      >{`${selected.length} selected`}</Typography>
    </Box>
  )
}

export default ExerciseSelector
