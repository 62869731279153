import React, { useState } from "react"
import PreviewCodeModal from "./PreviewCodeModal.component"

const previewCode = "01026"

const PreviewCode = () => {
  const localCode = localStorage.getItem('previewCode')
  const [modalOpen, setModalOpen] = useState(localCode === previewCode ? false : true)

  const handleCloseModal = () => setModalOpen(false)

  return (
    <PreviewCodeModal
      modalOpen={modalOpen}
      handleCloseModal={handleCloseModal}
      previewCode={previewCode}
    />
  )
}

export default PreviewCode
