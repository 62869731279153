import React from "react"
import { NavLink } from "react-router-dom"

import { Box, Link, Stack, Typography } from "@mui/material"

import PageTitle from "../common/PageTitle.component"
import SignUp from "../common/SignUp.component"

const SignUpPage = () => (
  <Box sx={{ maxWidth: "40rem", minHeight: "40vh", width: "100%" }}>
    <Stack>
      <Typography
        fontSize="1.125rem"
        fontWeight="300"
        textTransform="uppercase"
      >
        Welcome
      </Typography>
      <PageTitle title="Sign up for Fly Code" />
    </Stack>
    <SignUp />
    <Typography fontSize="1.125rem" fontWeight="300" mt={5}>
      Already have an account?{" "}
      <Link component={NavLink} to="/signin">
        Sign in
      </Link>
    </Typography>
  </Box>
)

export default SignUpPage
