import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { register as registerServiceWorker } from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"

import AuthProvider from "./app/contexts/AuthContext"
import UserProvider from "./app/contexts/UserContext"
import AlertProvider from "./app/contexts/AlertContext"
import WorkoutsProvider from "./app/contexts/WorkoutsContext"
import ExercisesProvider from "./app/contexts/ExercisesContext"
import VideosProvider from "./app/contexts/VideosContext"
import ScrollToTop from "./app/ScrollToTop"
import FirebaseAnalytics from "./app/FirebaseAnalytics"

import "./app/firebase"

import App from "./App"

import "swiper/css/bundle"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./index.css"


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <FirebaseAnalytics />
      <AuthProvider>
        <UserProvider>
          <WorkoutsProvider>
            <ExercisesProvider>
              <VideosProvider>
                <AlertProvider>
                  <App />
                </AlertProvider>
              </VideosProvider>
            </ExercisesProvider>
          </WorkoutsProvider>
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)

registerServiceWorker()
reportWebVitals()
