import React from "react"

import { Box, CircularProgress } from "@mui/material"

import logo from "../assets/images/logo.png"
import { StyledBackground } from "../common/Layout.component"

const Loading = () => {
  return (
    <StyledBackground
      sx={{
        alignItems: "center",
        backgroundColor: "#000",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "0!important",
        width: "100%",
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="Flyweight"
        sx={{ maxWidth: "20rem", paddingBottom: 4, width: "100%" }}
      />
      <CircularProgress size={60} sx={{ color: "#fff" }} />
    </StyledBackground>
  )
}

export default Loading
