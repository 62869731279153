import React from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import { IconButton, Tooltip } from "@mui/material"
import {
  PlayCircle as PlayCircleIcon,
  PauseCircle as PauseCircleIcon,
} from "@mui/icons-material"

const StartPauseButton = ({
  timerPaused,
  setTimerPaused,
  setGifPaused,
}) => {
  const isLarge = useMediaQuery("(min-width: 1200px)")

  const handleClick = () => {
    setTimerPaused((prevState) => !prevState)
    setGifPaused((prevState) => !prevState)
  }

  return (
    <Tooltip title={timerPaused ? "Start timer" : "Pause timer"}>
      <IconButton
        onClick={handleClick}
        size={isLarge ? "medium" : "small"}
      >
        {timerPaused ? (
          <PlayCircleIcon
            sx={{ fontSize: { xs: 30, sm: 35, lg: 50 } }}
          />
        ) : (
          <PauseCircleIcon
            sx={{ fontSize: { xs: 30, sm: 35, lg: 50 } }}
          />
        )}
      </IconButton>
    </Tooltip>
  )
}

export default StartPauseButton
