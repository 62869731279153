import React, { useContext, useEffect, useState } from "react"
import { NavLink, useNavigate, useSearchParams } from "react-router-dom"
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth"

import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from "@mui/material"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material"

import { AlertContext } from "../app/contexts/AlertContext"

import PageTitle from "../common/PageTitle.component"
import StyledTextField from "../common/mui/StyledTextField.component"

const AccountManagementPage = () => {
  const { setAlert } = useContext(AlertContext)
  const navigate = useNavigate()
  const auth = getAuth()
  
  const [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get("oobCode")
  
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [validCode, setValidCode] = useState(true)

  useEffect(() => {
    verifyPasswordResetCode(auth, code).catch((error) => setValidCode(false))
  }, [auth, code])

  const handleSubmit = async e => {
    e.preventDefault()
    confirmPasswordReset(auth, code, password)
    .then(function() {
      setAlert({ message: "Password has been reset", severity: "success" })
      navigate("/signin")
    })
    .catch(error => {
      setPasswordError("Error saving password. Try requesting a new password rest link.")
      console.log(error)
    })
  }

  return (
    <Box sx={{ maxWidth: "40rem", minHeight: "40vh", width: "100%" }}>
      <Stack>
        <Typography fontSize="1.125rem" fontWeight="300">
          Reset Your Password
        </Typography>
        <PageTitle title="Flyweight Digital" />
      </Stack>
      {validCode ? (
        <Box component="form" onSubmit={handleSubmit} mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              <Grid container spacing={.5}>
                <Grid item xs={10} sm={11}>
                  <StyledTextField
                    error={!!passwordError}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    label="New Password"
                    onChange={e => {
                      setPasswordError("")
                      setPassword(e.target.value)
                    }}
                    value={password}
                  />
                </Grid>
                <Grid item xs={2} sm={1}  sx={{ alignItems: "flex-start", display: "flex" }}>
                  <Box sx={{ marginTop: { xs: "30px", lg: "38px" } }}>
                    <Tooltip title={showPassword ? "Hide password" : "Show password"}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={e => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                sx={{ marginTop: 2 }}
              >
                <Typography component="span" fontSize="1.125rem" px={3}>
                  Save Password
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight="300" mt={6}>
                Nevermind, take me back to the{" "}
                <Link component={NavLink} to="/signin">
                  sign in page
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box mt={6}>
          <Typography>Your reset password link has expired or has already been used.</Typography>
          <Typography>
            Try{" "}
            <Link component={NavLink} to="/forgot-password" underline="none">resetting your password</Link>
            {" "}again.
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default AccountManagementPage
