import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { Box, Stack, Tooltip } from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"
import CheckIcon from "@mui/icons-material/Check"
import AgilityIcon from "@mui/icons-material/Games"
import BatteryIcon from "@mui/icons-material/BatteryChargingFull"
import BuildIcon from "@mui/icons-material/Construction"
import ExplosivenessIcon from "@mui/icons-material/Flare"
import MetabolicBurnIcon from "@mui/icons-material/LocalFireDepartment"
import PerformanceIcon from "@mui/icons-material/WorkspacePremium"
import PowerIcon from "@mui/icons-material/Bolt"
import SkillBuildingIcon from "@mui/icons-material/Insights"
import SpeedIcon from "@mui/icons-material/Speed"

import { UserContext } from "../../app/contexts/UserContext"

import HorizontalLevelIcon from "../../common/HorizontalLevelIcon.component"

import {
  StyledTileContainer,
  StyledBackgroundImageContainer,
  phaseBoxStyles,
  iconBoxStyles,
  lockIconStyles,
  checkIconStyles,
  StyledTextContainer,
  StyledTileText,
} from "./WorkoutTiles.styles"

const iconStyles = { color: "primary.main", fontSize: "1.5rem" }

export const getIcon = (name, day, icon) => {
  if (name === "Agility") return <AgilityIcon sx={iconStyles} />
  if (name === "Battery") return <BatteryIcon sx={iconStyles} />
  if (name === "Build") return <BuildIcon sx={iconStyles} />
  if (name === "Explosiveness") return <ExplosivenessIcon sx={iconStyles} />
  if (name === "Metabolic Burn") return <MetabolicBurnIcon sx={iconStyles} />
  if (name === "Performance") return <PerformanceIcon sx={iconStyles} />
  if (name === "Power") return <PowerIcon sx={iconStyles} />
  if (name === "Skill Building") return <SkillBuildingIcon sx={iconStyles} />
  if (name === "Speed") return <SpeedIcon sx={iconStyles} />
}

const DesktopTile = ({
  completed,
  day,
  getExerciseCover,
  icon,
  uid,
  unlocked,
  videos,
}) => {
  const navigate = useNavigate()
  const { setSignInSignUpPopup } = useContext(UserContext)

  // Temp code for random icons
  const names = [
    "Agility",
    "Battery",
    "Build",
    "Explosiveness",
    "Metabolic Burn",
    "Performance",
    "Power",
    "Skill Building",
    "Speed",
  ]
  const iconName = icon || names[Math.floor(Math.random() * names.length)]

  const handleNavigateToWorkout = () => navigate(`/fly-code/day/${day}`)
  const handleOpenLoginPopup = () => setSignInSignUpPopup(true)

  return (
    <StyledTileContainer
      day={day}
      onClick={uid ? handleNavigateToWorkout : handleOpenLoginPopup}
      uid={uid}
      unlocked={unlocked}
    >
      <StyledBackgroundImageContainer
        className="backgroundImageContainer"
        getExerciseCover={getExerciseCover}
        uid={uid}
        videos={videos}
      />
      <Box sx={phaseBoxStyles}>
        Phase {day <= 30 ? "1" : day <= 60 ? "2" : "3"}
        <HorizontalLevelIcon
          level={day <= 30 ? 2 : day <= 60 ? 3 : 4}
          margin="0 0 3px 6px"
          dark={true}
        />
      </Box>
      <Box sx={iconBoxStyles}>
        {!uid && <LockIcon sx={lockIconStyles} />}
        {completed && <>Completed<CheckIcon sx={checkIconStyles} /></>}
      </Box>
      <StyledTextContainer uid={uid}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          pb={2}
        >
          {iconName && (
            <Tooltip title={iconName}>{getIcon(iconName, day, icon)}</Tooltip>
          )}
          <StyledTileText>Day {day}</StyledTileText>
        </Stack>
      </StyledTextContainer>
    </StyledTileContainer>
  )
}

export default DesktopTile
