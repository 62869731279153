import React, { useContext, useEffect, useState } from "react"

import { Box, Divider, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { UserContext } from "../app/contexts/UserContext"

import SignIn from "./SignIn.component"
import SignUp from "./SignUp.component"

const modalStyle = {
  bgcolor: "#080808",
  border: "2px solid #080808",
  boxShadow: 24,
  left: "50%",
  maxHeight: "100vh",
  maxWidth: "600px",
  outline: "none",
  overflow: "auto",
  p: 2,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const buttonStyle = {
  color: "primary.main",
  cursor: "pointer",
  fontSize: "1.125rem",
  textDecoration: "underline",
  textDecorationColor: "rgba(6, 209, 189, 0.4)",
  "&:hover": {
    textDecorationColor: "inherit",
  },
}

const SignInSignUpModal = () => {
  const { uid, signInSignUpPopup, setSignInSignUpPopup } = useContext(UserContext)
  const [toggle, setToggle] = useState("signin")

  useEffect(() => {
    if (uid) setSignInSignUpPopup(false)
  }, [])

  const handleCloseModal = () => {
    setToggle("signin")
    setSignInSignUpPopup(false)
  }
  const handleToggle = () =>
    setToggle((prevState) => (prevState === "signin" ? "signup" : "signin"))

  return (
    <Modal open={signInSignUpPopup || false} aria-labelledby="signin-signup-modal-title">
      <Box sx={modalStyle}>
        <Box textAlign="right">
          <IconButton onClick={handleCloseModal} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box p={2}>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Typography
              color="text.secondary"
              fontSize="1rem"
              textTransform="uppercase"
            >
              Welcome{toggle === "signin" && " Back"}
            </Typography>
            <Typography fontSize="1.75rem" fontWeight="600">
              {toggle === "signin"
                ? "Sign in to Fly Code"
                : "Sign up for Fly Code"}
            </Typography>
          </Box>
          {toggle === "signin" ? <SignIn /> : <SignUp />}
          <Divider sx={{ mb: 3, mt: 4 }} />
          <Typography fontSize="1.125rem" fontWeight="300">
            {toggle === "signin" ? "Don't" : "Already"} have an account?{" "}
            <Typography
              component="span"
              onClick={handleToggle}
              sx={buttonStyle}
            >
              {toggle === "signin" ? "Sign Up" : "Sign In"}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default SignInSignUpModal
