import React, { useContext, useState } from "react"
import axios from "axios"

import { alpha } from "@mui/material/styles"
import {
  Box,
  Button,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"

import AddWorkoutModal from "./AddWorkoutModal.component"
import ConfirmDeleteModal from "./ConfirmDeleteModal.component"

const WorkoutsTableToolbar = ({ workouts, selected, setSelected, search, setSearch }) => {
  const { setAlert } = useContext(AlertContext)
  const { removeWorkout } = useContext(WorkoutsContext)

  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const handleOpenDeleteModal = () => setConfirmDeleteModalOpen(true)
  const handleCloseDeleteModal = () => setConfirmDeleteModalOpen(false)

  const handleDeleteWorkouts = () => {
    const workoutsToDelete = workouts.filter((workout) => selected.includes(workout.id))
    workoutsToDelete.forEach((workout) => {
      axios
        .post("/api/workouts/remove", {
          workoutId: workout.id,
        })
        .then(() => {
          // remove workout from store
          removeWorkout(workout.id)

          // remove is complete
          setSelected([])
          setAlert({
            message: `${
              selected.length > 1 ? "Workouts have" : "Workout has"
            } been permanently deleted`,
            severity: "success",
          })
        })
        .catch((error) => {
          console.log("error deleting workout", error.response.data)
          setAlert({
            message: "Unable to remove workout from database",
            severity: "error",
          })
        })
    })
  }

  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <TextField
        label="Search workouts"
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        value={search}
        sx={{ flex: "1 1 100%", maxWidth: "600px" }}
      />
      {selected.length > 0 ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
            pl: 4,
          }}
        >
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            whiteSpace="nowrap"
          >
            {selected.length} selected
          </Typography>
          <Tooltip title="Delete">
            <IconButton
              onClick={(e) => {
                e.currentTarget.blur()
                handleOpenDeleteModal()
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <ConfirmDeleteModal
            modalOpen={confirmDeleteModalOpen}
            handleCloseModal={handleCloseDeleteModal}
            handleDeleteWorkouts={() => {
              handleCloseDeleteModal()
              handleDeleteWorkouts(selected)
            }}
            workouts={workouts}
            selected={selected}
          />
        </Box>
      ) : (
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{
            flexShrink: 0,
            marginLeft: { xs: 0.5, sm: 2 },
            whiteSpace: "nowrap",
          }}
        >
          + Workout
        </Button>
      )}
      <AddWorkoutModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setSearch={setSearch}
      />
    </Toolbar>
  )
}

export default WorkoutsTableToolbar
