import React from "react"
import { Box } from "@mui/material"

import HeroVideo from "./HeroVideo.component"
import HomePageFlyCode from "./HomePageFlyCode.component"
import HomePageOnTheFly from "./HomePageOnTheFly.component"

const HomePage = () => (
  <Box
    id="main"
    display="flex"
    flexDirection="column"
    sx={{ mb: 6, width: "100%" }}
  >
    <HeroVideo />
    <HomePageFlyCode />
    <HomePageOnTheFly />
  </Box>
)

export default HomePage
