import React, { useContext, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { getAuth, sendPasswordResetEmail  } from "firebase/auth"

import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography
} from "@mui/material"

import { AlertContext } from "../app/contexts/AlertContext"

import PageTitle from "../common/PageTitle.component"
import StyledTextField from "../common/mui/StyledTextField.component"

const ForgotPasswordPage = () => {
  const { setAlert } = useContext(AlertContext)
  const navigate = useNavigate()
  const auth = getAuth()

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()

    if (!email) setEmailError("Please enter a valid email")
    else {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setAlert({ message: `Check ${email} for password reset instructions`, severity: "success" })
          navigate("/signin")
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email") setEmailError("Please enter a valid email")
          else if (error.code === "auth/user-not-found") setEmailError("Email address not found")
          else setEmailError("Error sending reset email" + error.code)
        })
    }
    
  }

  return (
    <Box sx={{ maxWidth: "40rem", minHeight: "40vh", width: "100%" }}>
      <Stack>
        <Typography fontSize="1.125rem" fontWeight="300">
          Forgot Password
        </Typography>
        <PageTitle title="Flyweight Digital" />
      </Stack>
      <Typography fontSize="1.125rem" fontWeight="300" mt={3}>
        To reset your password, enter the email address you use to sign in.
      </Typography>
      <Box component="form" onSubmit={handleSubmit} mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledTextField
              error={!!emailError}
              fullWidth
              helperText={emailError}
              label="Email"
              name="email"
              onChange={e => {
                setEmailError("")
                setEmail(e.target.value)
              }}
              value={email}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              sx={{ marginTop: 2 }}
            >
              <Typography component="span" fontSize="1.125rem" px={3}>
                Send reset link
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="300" mt={6}>
              Nevermind, take me back to the{" "}
              <Link component={NavLink} to="/signin">
                sign in page
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ForgotPasswordPage
