import React, { useContext, useState } from "react"
import axios from "axios"
import PasswordStrengthBar from "react-password-strength-bar"

import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material"

import { AlertContext } from "../app/contexts/AlertContext"

import PageTitle from "../common/PageTitle.component"
import StyledTextField from "../common/mui/StyledTextField.component"
import { UserContext } from "../app/contexts/UserContext"

const ChangePasswordPage = () => {
  const { userState } = useContext(UserContext)
  const { uid } = userState
  const { setAlert } = useContext(AlertContext)

  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  
  const onPasswordChange = (e) => {
    setPasswordError("")
    setPassword(e.target.value)
  }
  const onConfirmPasswordChange = (e) => {
    setPasswordError("")
    setConfirmPassword(e.target.value)
  }

  const handlePasswordReset = () => {
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match")
    } else {
      axios
        .post("/api/firebase/auth/update", {
          uid,
          updates: {
            password,
          },
        })
        .then(() => {
          setPassword("")
          setConfirmPassword("")
          setAlert({
            message: "Password has been updated",
            severity: "success",
          })
        })
        .catch(() => {
          setAlert({ message: "Error updating password", severity: "error" })
        })
    }
  }

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <PageTitle title="Change Password" />

      <Grid container mt={6} maxWidth="400px">
        <Grid item xs={12}>
          <Grid container spacing={.5}>
            <Grid item xs={10} sm={11}>
              <StyledTextField
                id="password"
                label="Password (min 6 characters)"
                onChange={onPasswordChange}
                type={showPassword ? "text" : "password"}
                value={password}
              />
              <PasswordStrengthBar
                password={password}
              />
            </Grid>
            <Grid item xs={2} sm={1}  sx={{ alignItems: "flex-start", display: "flex" }}>
              <Box sx={{ marginTop: { xs: "30px", lg: "38px" } }}>
                <Tooltip title={showPassword ? "Hide password" : "Show password"}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={.5}>
            <Grid item xs={10} sm={11}>
              <StyledTextField
                error={!!passwordError}
                helperText={passwordError}
                id="password"
                label="Confirm Password"
                onChange={onConfirmPasswordChange}
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
              />
            </Grid>
            <Grid item xs={2} sm={1}  sx={{ alignItems: "flex-start", display: "flex" }}>
              <Box sx={{ marginTop: { xs: "30px", lg: "38px" } }}>
                <Tooltip title={showConfirmPassword ? "Hide password" : "Show password"}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button onClick={handlePasswordReset} variant="contained">
          Update Password
        </Button>
      </Box>
    </Box>
  )
}

export default ChangePasswordPage
