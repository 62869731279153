import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getDatabase, ref, set } from "firebase/database"
import { DateTime } from "luxon"
import { getAnalytics, logEvent } from "firebase/analytics"

import { Button } from "@mui/material"

import { AuthContext } from "../../app/contexts/AuthContext"
import { UserContext } from "../../app/contexts/UserContext"
import { AlertContext } from "../../app/contexts/AlertContext"

const CompleteWorkoutButton = ({ currentDay, preview }) => {
  const navigate = useNavigate()
  const database = getDatabase()
  const { selectUid } = useContext(AuthContext)
  const { userState, startSetCompleted } = useContext(UserContext)
  const { setAlert } = useContext(AlertContext)
  const uid = selectUid()

  // Log page view
  useEffect(() => {
    const analytics = getAnalytics()
    logEvent(analytics, "complete_workout", {
      content_type: "day",
      content_id: currentDay,
    })
  }, [])

  const handleCompleteWorkout = () => {
    if (preview) {
      navigate("/preview")
      return
    }

    if (!userState.history[currentDay]) {
      startSetCompleted(uid, currentDay).then(() => {
        setAlert({
          severity: "success",
          message: `Day ${currentDay} completed. Great job!`,
        })
        navigate("/fly-code", { state: { unlocked: currentDay + 1 } })
      })
      return
    }

    navigate("/fly-code")
  }

  return (
    <Button
      fontSize="1rem"
      fullWidth
      onClick={handleCompleteWorkout}
      variant="contained"
      sx={{ mt: 4 }}
    >
      Complete Workout
    </Button>
  )
}

export default CompleteWorkoutButton
