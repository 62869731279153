import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import { styled } from "@mui/material/styles"
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material"

import { VideosContext } from "../../../app/contexts/VideosContext"

import ExercisesTableToolbar from "./ExercisesTableToolbar.component"
import ExercisesTableHead from "./ExercisesTableHead.component"

export const StyledThumbnail = styled("img")(({ theme }) => ({
  height: "50px",
  objectFit: "cover",
  width: "50px",
  [theme.breakpoints.up("sm")]: {
    height: "75px",
    width: "75px",
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const ExercisesTable = ({ exercises, search, setSearch }) => {
  const navigate = useNavigate()
  const { selectThumbnailUrl } = useContext(VideosContext)

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("configuration")
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const newSelecteds = exercises.map((exercise) => exercise.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (e, day) => {
    const selectedIndex = selected.indexOf(day)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, day)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const isSelected = (day) => selected.indexOf(day) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - exercises.length) : 0

  const getExerciseCover = (videoId) => selectThumbnailUrl(videoId)

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <ExercisesTableToolbar
          exercises={exercises}
          selected={selected}
          setSelected={setSelected}
          search={search}
          setSearch={setSearch}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <ExercisesTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={exercises.length}
            />
            <TableBody>
              {exercises
                .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((exercise, index) => {
                  const isItemSelected = isSelected(exercise.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      aria-checked={isItemSelected}
                      hover
                      key={exercise.id}
                      onClick={() =>
                        navigate(`/admin/exercises/${exercise.id}`)
                      }
                      role="checkbox"
                      selected={isItemSelected}
                      tabIndex={-1}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleClick(e, exercise.id)
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {exercise.configuration}
                      </TableCell>
                      <TableCell>
                        <StyledThumbnail src={getExerciseCover(exercise.videos ? exercise.videos[0] : "")} alt="thumbnail" />
                      </TableCell>
                      <TableCell>{exercise.name}</TableCell>
                      <TableCell>{exercise.coachingCues}</TableCell>
                      <TableCell align="right">
                        {exercise.videos ? Object.keys(exercise.videos).length : 0}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={exercises.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

export default ExercisesTable
