import React, { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import { getAnalytics, logEvent } from "firebase/analytics"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Grid, Stack, Typography } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"

import { WorkoutsContext } from "../../app/contexts/WorkoutsContext"
import { ExercisesContext } from "../../app/contexts/ExercisesContext"
import { VideosContext } from "../../app/contexts/VideosContext"

import ImageSwiper from "./ImageSwiper.component"
import DayOverview from "./DayOverview.component"
import TimerContainer from "./TimerContainer.component"
import Breadcrumbs from "../../common/Breadcrumbs.component"

const getVideoByIndex = (workout, videos, index) =>
  videos.filter((video) => video.id === workout.videos[index])[0]

const WorkoutPage = () => {
  const { day } = useParams()
  const { selectWorkoutByDay } = useContext(WorkoutsContext)
  const { selectExerciseById } = useContext(ExercisesContext)
  const { selectVideosByIds } = useContext(VideosContext)

  const workout = selectWorkoutByDay(day)
  const videos = workout.videos ? selectVideosByIds(workout.videos) : []
  const firstVideo = videos.length ? getVideoByIndex(workout, videos, 0) : null

  const [currentVideo, setCurrentVideo] = useState(firstVideo)
  const [timer, setTimer] = useState(0)
  const [restTimer, setRestTimer] = useState(0)
  const [timerPaused, setTimerPaused] = useState(false)
  const [gifPaused, setGifPaused] = useState(false)

  const isMedium = useMediaQuery("(min-width: 900px)")

  // Log page view
  useEffect(() => {
    const analytics = getAnalytics()
    logEvent(analytics, "select_workout", {
      content_type: "day",
      content_id: day,
    })
  }, [])

  const handleSwipe = (slideIndex) => {
    setTimerPaused(false)
    setGifPaused(false)
    if (slideIndex < videos.length) {
      const nextVideo = getVideoByIndex(workout, videos, slideIndex)
      setCurrentVideo(nextVideo)
      setTimer(0)
      return
    }
    setTimer(0)
    setCurrentVideo(null)
  }

  const resetTimer = () => {
    if (timerPaused) {
      setRestTimer(0)
      return
    }
    setTimerPaused(false)
    setGifPaused(false)
    setTimer(0)
  }

  return (
    <Grid container columnSpacing={4}>
      <Grid item xs={12}>
        <Breadcrumbs
          pages={[
            { title: "Workouts", to: "/" },
            { title: "Fly Code", to: "/fly-code" },
            { title: `Day ${day}` },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={7} sx={{ ...(isMedium && { height: "80vh" }) }}>
        {videos.length > 0 ? (
          <ImageSwiper
            workout={workout}
            handleSwipe={handleSwipe}
            timerPaused={timerPaused}
            setTimerPaused={setTimerPaused}
            gifPaused={gifPaused}
            setGifPaused={setGifPaused}
          />
        ) : (
          <Box
            spacing={isMedium ? 1 : 0}
            sx={{
              alignItems: "center",
              backgroundColor: "#080808",
              boxShadow:
                "0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02)",
              display: "flex",
              height: { xs: "60vh", md: "100%" },
              justifyContent: "center",
              padding: 2,
              width: "100%",
            }}
          >
            <Typography fontSize=".875rem">
              Oops. We couldn't find any exercises for this day. Please check
              back soon.
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          overflow: "hidden",
          overflowY: "auto",
          pb: "40px",
          ...(isMedium && { paddingRight: 2, maxHeight: "80vh" }),
        }}
      >
        <Stack direction="column" height="100%">
          {currentVideo && (
            <TimerContainer
              currentDay={parseInt(day)}
              timer={timer}
              setTimer={setTimer}
              restTimer={restTimer}
              setRestTimer={setRestTimer}
              timerPaused={timerPaused}
              setTimerPaused={setTimerPaused}
              setGifPaused={setGifPaused}
              resetTimer={resetTimer}
            />
          )}
          <Box sx={{ display: "flex", gap: 1, margin: { xs: "1.5rem auto 0", md: "1.5rem 0 0" }, maxWidth: { sm: "335px", lg: "370px" } }}>
            <InfoIcon sx={{ color: "primary.main", fontSize: "28px" }} />
            <Typography>
              Swipe to the next workout when you have completed the recommended
              reps. Click the complete button to mark Day {parseInt(day)} complete.
            </Typography>
          </Box>
          <Stack
            direction={isMedium ? "column" : "column-reverse"}
            height="100%"
          >
            {workout && currentVideo && (
              <DayOverview
                workout={workout}
                exercise={selectExerciseById(currentVideo.parentExerciseId)}
                currentVideo={currentVideo}
              />
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default WorkoutPage
