import React, { useContext, useState } from "react"
import axios from "axios"

import { Button } from "@mui/material"

import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"
import { AlertContext } from "../../../app/contexts/AlertContext"
import ConfirmationModal from "../../../common/ConfirmationModal.component"

const ResetProgressButton = ({ user }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { updateUser } = useContext(AdminUsersContext)
  const { setAlert } = useContext(AlertContext)

  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const handleConfirmation = () => {
    const userId = user.id
    const dbObj = { lastCompleted: 0 }

    // Update user in rtdb
    axios
      .post("/api/users/update", {
        userId,
        dbObj,
      })
      .then((res) => {
        // Update user in context
        updateUser({ id: userId, history: new Array(91).fill(false), ...dbObj })

        // Remove user history
        axios
          .post("/api/history/remove", { userId })
          .then((res) => {
            // udpate is complete
            setAlert({
              message: `User progress has been reset`,
              severity: "success",
            })
          })
          .catch((error) => {
            console.log("error deleting user history from db", error.response.data)
            setAlert({ message: "Error deleting user history", severity: "error" })
          })

      })
      .catch((error) => {
        console.log("error writing to db", error.response.data)
        setAlert({ message: "Error updating user", severity: "error" })
      })

    handleCloseModal()
  }

  return (
    <>
      <Button color="error" onClick={handleOpenModal} variant="outlined">
        Reset Progress
      </Button>
      <ConfirmationModal
        modalOpen={modalOpen}
        handleConfirmation={handleConfirmation}
        handleCloseModal={handleCloseModal}
        modalBody="This will reset the users workout completion progress to 0 days completed. This action cannot be undone."
        confirmBtnText="Reset Progress"
      />
    </>
  )
}

export default ResetProgressButton
