import React, { useContext, useEffect, useState } from "react"

import { Typography } from "@mui/material"

import { ExercisesContext } from "../../../app/contexts/ExercisesContext"
import { VideosContext } from "../../../app/contexts/VideosContext"

import Breadcrumbs from "../../../common/Breadcrumbs.component"
import ExercisesTable from "./ExercisesTable.component"

const ExercisesPage = () => {
  const { exercisesState } = useContext(ExercisesContext)
  const { videosState } = useContext(VideosContext)

  const [exercisesList, setExercisesList] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (exercisesState.length > 0 && videosState.length > 0) {
      setExercisesList(exercisesState.map(exercise => ({
        ...exercise,
        videosNames: exercise.videos.map(videoId => videosState.filter(video => video.id === videoId)[0].name).join(", ")
      })))
    }
  }, [exercisesState, videosState])

  const getVisibleExercises = () => {
    return exercisesList.filter((exercise) => {
      return (
        exercise.name?.toLowerCase().includes(search.toLowerCase()) ||
        exercise.videosNames?.toLowerCase().includes(search.toLowerCase()) ||
        exercise.configuration?.toLowerCase().includes(search.toLowerCase())
      )
    }).sort((a, b) => a.name > b.name)
  }

  return (
    <>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Exercises" },
        ]}
      />
      <Typography component="h1" variant="h4" pb={4}>
        Manage Exercises
      </Typography>
      {exercisesState && (
        <ExercisesTable
          exercises={getVisibleExercises()}
          search={search}
          setSearch={setSearch}
        />
      )}
    </>
  )
}

export default ExercisesPage
