import React, { useContext, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { styled } from "@mui/material/styles"
import {
  AppBar,
  Button,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  Divider,
} from "@mui/material/"
import MenuIcon from "@mui/icons-material/Menu"
import LogoutIcon from "@mui/icons-material/Logout"

import { AuthContext } from "../../app/contexts/AuthContext"
import { UserContext } from "../../app/contexts/UserContext"

import logo from "../../assets/images/logo.png"
import ChangePasswordModal from "./ChangePasswordModal.component"

const StyledLogo = styled("img")(({ theme }) => ({
  maxWidth: "220px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: "275px",
  },
}))

const StyledMenu = styled(Menu)(() => ({
  "& .MuiList-root": {
    minWidth: "10rem",
  },
}))

const AdminAppBar = () => {
  const navigate = useNavigate()
  const { handleSignOut } = useContext(AuthContext)
  const { clearUser } = useContext(UserContext)

  const [anchorElNav, setAnchorElNav] = useState(null)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget)
  const handleCloseNavMenu = () => setAnchorElNav(null)

  const handleOpenPasswordModal = () => setPasswordModalOpen(true)
  const handleClosePasswordModal = () => setPasswordModalOpen(false)

  const handleNavigate = (to) => {
    handleCloseNavMenu()
    navigate(to)
  }

  const onSignOut = () => {
    clearUser()
    handleSignOut()
  }

  return (
    <AppBar color="secondary" position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            component={NavLink}
            to="/admin/dashboard"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <StyledLogo src={logo} alt="Flyweight" />
          </Typography>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={() => handleNavigate("/admin/users")}>
                <Typography textAlign="center">Users</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/admin/workouts")}>
                <Typography textAlign="center">Workouts</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/admin/exercises")}>
                <Typography textAlign="center">Exercises</Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleNavigate("/")}>
                <Typography textAlign="center">User App</Typography>
              </MenuItem>
              <MenuItem onClick={handleOpenPasswordModal}>
                <Typography textAlign="center">Change Password</Typography>
              </MenuItem>
              <MenuItem onClick={onSignOut}>
                <Typography textAlign="center">Sign Out</Typography>
              </MenuItem>
            </StyledMenu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <Typography
              component={NavLink}
              to="/admin/dashboard"
              sx={{ ml: 1, display: { xs: "flex", md: "none" } }}
            >
              <StyledLogo src={logo} alt="Flyweight" />
            </Typography>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={() => navigate("/admin/users")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Users
            </Button>
            <Button
              onClick={() => navigate("/admin/workouts")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Workouts
            </Button>
            <Button
              onClick={() => navigate("/admin/exercises")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Exercises
            </Button>
          </Box>
          <Stack
            direction="row"
            sx={{ display: { xs: "none", md: "block" }, flexGrow: 0 }}
          >
            <Button mr={2} onClick={() => navigate("/")} variant="link">
              User App
            </Button>
            <Button mr={2} onClick={handleOpenPasswordModal} variant="link">
              Password
            </Button>
            <Button onClick={handleSignOut} variant="link">
              <LogoutIcon
                sx={{
                  display: { xs: "none", md: "block" },
                  fontSize: "1.25rem",
                  mr: 0.5,
                }}
              />
              Sign Out
            </Button>
          </Stack>
        </Toolbar>
      </Container>
      <ChangePasswordModal
        modalOpen={passwordModalOpen}
        handleCloseModal={handleClosePasswordModal}
      />
    </AppBar>
  )
}

export default AdminAppBar
