import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    "fontFamily": `"Eurostile", "Arial", "Helvetica", sans-serif`,
    monospace: {
      "fontFamily": `"Source Code Pro", monospace`
    }
   },
  palette: {
    mode: 'dark',
    primary: {
      main: '#06d1bd',
      light: '#37daca',
      dark: '#049284',
      contrastText: '#121212',
    },
    secondary: {
      main: '#e5333f',
      light: '#ea5b65',
      dark: '#a0232c',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#e9fd5d',
      light: '#edfd7d',
      dark: '#a3b141',
      contrastText: '#000',
    },
    background: {
      paper: '#000000',
      default: '#000000'
    }
  }
})

const CustomizedTheme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
 
export default CustomizedTheme
