import React, { useContext, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material"

import "../app/firebase"
import { AuthContext } from "../app/contexts/AuthContext"
import { UserContext } from "../app/contexts/UserContext"

import StyledTextField from "./mui/StyledTextField.component"

const SignIn = () => {
  const auth = getAuth()
  const { setAuthState } = useContext(AuthContext)
  const { setSignInSignUpPopup } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const handleSignIn = (e) => {
    e.preventDefault()
    setEmailError("")
    setPasswordError("")

    if (!email) {
      console.log("error")
      setEmailError("Please enter a valid email")
    }

    if (!password) setPasswordError("Please enter your password")

    if (password && email) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setSignInSignUpPopup(false)
          setAuthState(userCredential.user)
          if (
            location.state &&
            location.state.from &&
            location.state.from.pathname
          ) {
            navigate(location.state.from.pathname)
          }
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email")
            setEmailError("Please enter a valid email")
          else if (error.code === "auth/user-not-found")
            setEmailError("Email address not found")
          else if (error.code === "auth/wrong-password")
            setPasswordError("Incorrect password")
          else setPasswordError("Error authenticating user" + error.code)
        })
    }
  }

  return (
    <Box component="form" onSubmit={handleSignIn} mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            <Grid item xs={10} sm={11}>
              <StyledTextField
                error={!!emailError}
                helperText={emailError}
                id="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                value={email}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            <Grid item xs={10} sm={11}>
              <StyledTextField
                error={!!passwordError}
                helperText={passwordError}
                id="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                value={password}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              sx={{ alignItems: "flex-start", display: "flex" }}
            >
              <Box sx={{ marginTop: { xs: "30px", lg: "38px" } }}>
                <Tooltip
                  title={showPassword ? "Hide password" : "Show password"}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ marginTop: 2 }}
          >
            <Typography component="span" fontSize="1.125rem" px={3}>
              Sign in
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography fontWeight="300" mt={4} pr={6}>
            <Link component={NavLink} color="text.secondary" to="/forgot-password">
              Forgot Password?
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SignIn
