import React, { createContext, useState } from "react"
import { getDatabase, ref, get } from "firebase/database"

export const WorkoutsContext = createContext()

const initialState = []

export const WorkoutsProvider = (props) => {
  const database = getDatabase()
  const [workoutsState, setWorkoutsState] = useState(initialState)

  const startSetWorkouts = () => {
    return new Promise((resolve) => {
      get(ref(database, "workouts/"))
        .then((snap) => {
          const dataArray = []
          for (const [key, value] of Object.entries(snap.val())) {
            dataArray.push({
              id: key,
              exercises: [],
              ...value,
            })
          }
          setWorkoutsState(dataArray.sort((a, b) => a.day > b.day))
          resolve(true)
        })
        .catch((error) => {
          console.error(error)
          resolve([])
        })
    })
  }

  const addWorkout = (workout) =>
    setWorkoutsState((prevState) => [...prevState, workout])

  const updateWorkout = (workout) => {
    setWorkoutsState((prevState) => [
      ...prevState.map((prevWorkout) => {
        if (prevWorkout.id !== workout.id) {
          return prevWorkout
        } else {
          return {
            ...prevWorkout,
            ...workout,
          }
        }
      }),
    ])
  }

  const removeWorkout = (workoutId) =>
    setWorkoutsState((prevState) =>
      prevState.filter((prevWorkout) => prevWorkout.id !== workoutId)
    )

  const addVideosToWorkout = (workoutId, videoIds) => {
    setWorkoutsState((prevState) => [
      ...prevState.map((prevWorkout) => {
        if (prevWorkout.id !== workoutId) {
          return prevWorkout
        } else {
          return {
            ...prevWorkout,
            videos: [
              ...(prevWorkout.videos?.length > 0 ? prevWorkout.videos : []),
              ...videoIds,
            ],
          }
        }
      }),
    ])
  }

  const removeExerciseFromWorkout = (workoutId, exerciseId) => {
    setWorkoutsState((prevState) => [
      ...prevState.map((prevWorkout) => {
        if (prevWorkout.id !== workoutId.id) {
          return prevWorkout
        } else {
          const array = prevWorkout.exercises
          const index = array.indexOf(exerciseId)
          return {
            ...prevWorkout,
            exercises: [
              array.slice(0, index),
              array.slice(index + 1, array.length),
            ],
          }
        }
      }),
    ])
  }

  const selectAllWorkouts = () => workoutsState

  const selectWorkoutByDay = (day) =>
    workoutsState.filter((w) => w.day.toString() === day.toString())[0]

  const selectWorkoutById = (id) => workoutsState.filter((w) => w.id === id)[0]

  const selectWorkoutsLength = () => workoutsState.length

  return (
    <WorkoutsContext.Provider
      value={{
        workoutsState,
        startSetWorkouts,
        addWorkout,
        updateWorkout,
        removeWorkout,
        addVideosToWorkout,
        removeExerciseFromWorkout,
        selectAllWorkouts,
        selectWorkoutByDay,
        selectWorkoutById,
        selectWorkoutsLength,
      }}
    >
      {props.children}
    </WorkoutsContext.Provider>
  )
}

export default WorkoutsProvider
