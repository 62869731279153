import { Box } from "@mui/material"
import React, { useState, useEffect } from "react"

const CookiePolicyPage = () => {
  const [cookiePolicy, setCookiePolicy] = useState(null)

  useEffect(() => {
    fetch("https://www.iubenda.com/api/privacy-policy/96556172/cookie-policy")
      .then(response => response.json())
      .then(data => setCookiePolicy(data))
  }, [])

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <Box mb={8} mt={2}>
        {cookiePolicy && (
          <div className="cookie-policy" dangerouslySetInnerHTML={{ __html: cookiePolicy.content }}></div>
        )}
      </Box>
    </Box>
  )
}
 
export default CookiePolicyPage
