import React, { useState, createContext } from "react"
import { getDatabase, ref, get, set } from "firebase/database"
import { DateTime } from "luxon"

export const UserContext = createContext()

const initialState = {
  history: new Array(91).fill(false),
  email: "",
  fname: "",
  lastCompleted: 0,
  lname: "",
  username: "",
  isAdmin: false,
  lastActive: "",
  safetyCertified: false,
  signInSignUpPopup: true,
  uid: "",
}

export const UserProvider = (props) => {
  const database = getDatabase()
  const [userState, setUserState] = useState(initialState)

  const startSetUser = (uid) => {
    const lastActive = DateTime.now().setZone("America/New_York").toISO()

    return new Promise((resolve) => {
      get(ref(database, "users/" + uid))
        .then((userSnap) => {
          get(ref(database, "history/" + uid + "/90-day-workout")).then(
            (historySnap) => {
              setUserState((prevState) => {
                const historyArray = historySnap.val()
                const newHistoryArray = historyArray
                  ? prevState.history.map((value, i) =>
                      historyArray[i] ? historyArray[i] : value
                    )
                  : prevState.history
                return {
                  ...prevState,
                  ...userSnap.val(),
                  lastActive,
                  uid,
                  history: newHistoryArray,
                }
              })
              startSetLastActive(uid, lastActive)
              resolve(true)
            }
          )
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  const clearUser = () => setUserState(initialState)

  const startSetLastActive = (uid, lastActive) => {
    set(ref(database, "users/" + uid + "/lastActive"), lastActive)
      .then(() => {
        setUserState((prevState) => ({
          ...prevState,
          lastActive,
        }))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const startSetCompleted = (uid, day) => {
    const completed = DateTime.now().setZone("America/New_York").toISO()
    const lastCompleted = Math.max(userState.lastCompleted, day)
    const newHistoryArray = userState.history.map((value, i) =>
      i === day ? completed : value
    )
    return new Promise((resolve) => {
      set(ref(database, "history/" + uid + "/90-day-workout"), newHistoryArray)
        .then(() => {
          set(ref(database, "users/" + uid + "/lastCompleted"), lastCompleted)
          setUserState((prevState) => ({
            ...prevState,
            history: newHistoryArray,
            lastCompleted,
          }))
          resolve(true)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  const startSetSafetyCertified = (safetyCertified) => {
    const uid = userState.uid
    if (!uid) return

    set(ref(database, "users/" + uid + "/safetyCertified"), safetyCertified)
      .then(() => {
        setUserState((prevState) => ({
          ...prevState,
          safetyCertified,
        }))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const setSignInSignUpPopup = (signInSignUpPopup) =>
    setUserState((prevState) => ({ ...prevState, signInSignUpPopup }))

  const selectUid = () => userState.uid

  return (
    <UserContext.Provider
      value={{
        userState,
        setUserState,
        safetyCertified: userState.safetyCertified,
        signInSignUpPopup: userState.signInSignUpPopup,
        uid: userState.uid,
        startSetUser,
        clearUser,
        startSetCompleted,
        startSetSafetyCertified,
        selectUid,
        setSignInSignUpPopup,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
