import React, { useContext, useState } from "react"

import { Box, Typography } from "@mui/material"

import { ExercisesContext } from "../../app/contexts/ExercisesContext"

import CoachingCuesButton from "./CoachingCuesButton.component"
import CoachingCues from "./CoachingCues.component"
import PausePlayIcon from "./PausePlayIcon.component"
import VideoOverlayHead from "./VideoOverlayHead.component"

const VideoOverlay = ({ workout, video, gifPaused }) => {   
  const { selectExerciseById } = useContext(ExercisesContext)
  const exercise = selectExerciseById(video.parentExerciseId)

  const [showCoachingCues, setShowCoachingCues] = useState(true)

  return (
    <Box
      sx={{
        ...(showCoachingCues && {
          backgroundColor: "rgba(0,0,0,0.6)",
        }),
        height: "100%",
        position: "absolute",
        width: "100%",
      }}
    >
      <Box
        className="pause-play-icon"
        sx={{
          left: "50%",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <PausePlayIcon gifPaused={gifPaused} />
      </Box>
      {showCoachingCues && exercise?.coachingCues && (
        <Box display="flex" flexDirection="column" height="100%">
          <div>
            <VideoOverlayHead workout={workout} exercise={exercise} video={video} />
          </div>
          <div>
            <CoachingCues workout={workout} coachingCues={exercise.coachingCues.split("\n")} />
          </div>
          <div></div>
        </Box>
      )}
      <Box
        sx={{
          bottom: "20px",
          position: "absolute",
          right: 0,
        }}
      >
        {exercise?.coachingCues && (
          <CoachingCuesButton
            showCoachingCues={showCoachingCues}
            setShowCoachingCues={setShowCoachingCues}
          />
        )}
      </Box>
    </Box>
  )
}

export default VideoOverlay
