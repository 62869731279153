import React, { useEffect, useState } from "react"
import { DateTime } from "luxon"
import { CSVLink } from "react-csv"

import { styled } from "@mui/material/styles"
import FileDownloadIcon from "@mui/icons-material/FileDownload"

const StyledCSVLink = styled(CSVLink)(({ theme }) => ({
  alignItems: "center",
  alignSelf: "flex-end",
  backgroundColor: "transparent",
  border: "0px none",
  borderRadius: "4px",
  color: theme.palette.text.primary,
  display: "inline-flex",
  fontSize: "0.875rem",
  fontWeight: "500",
  justifyContent: "center",
  letterSpacing: "0.02857em",
  lineHeight: "1.75",
  margin: "0px",
  outline: "none",
  padding: "6px 8px",
  position: "relative",
  textDecoration: "none",
  textTransform: "uppercase",
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    backgroundColor: "rgba(18, 18, 18, 0.1)",
    textDecoration: "none",
  },
  "&:active": {
    backgroundColor: "rgba(18, 18, 18, 0.2)",
  },
}))

const UsersExport = ({ users }) => {
  const [csvData, setCsvData] = useState([
    ["First", "Last", "Email", "Username", "Last Login", "Workouts Completed", "isAdmin"],
  ])

  useEffect(() => {
    if (users) {
      const usersArray = []
      users.forEach(user => {
        usersArray.push([
          user.fname,
          user.lname,
          user.email,
          user.username,
          DateTime.fromISO(user.lastActive).toLocaleString(DateTime.DATETIME_FULL),
          user.lastCompleted,
          user.isAdmin
        ])
      })
      setCsvData(prevState => [...prevState, ...usersArray])
    }
  }, [users])

  return (
    csvData.length > 1 ? (
      <StyledCSVLink data={csvData}>
        <FileDownloadIcon sx={{ fontSize: "1rem", marginRight: 0.5 }} />
        Export Users List
      </StyledCSVLink>
    ) : (
      <></>
    )
  )
}

export default UsersExport
