import HomePage from "../../features/Home/HomePage.component"
import WorkoutsPage from "../../features/Workouts/WorkoutsPage.component"
import ChallengesPage from "../../features/Challenges/ChallengesPage.component"
import ConfiguratorPage from "../../features/configurator/ConfiguratorPage.component"
import PrivacyPolicyPage from "../../features/PrivacyPolicyPage.component"
import TermsOfServicePage from "../../features/TermsOfServicePage.component"
import SafetyTipsPage from "../../features/safety/SafetyTipsPage.component"
import CookiePolicyPage from "../../features/CookiePolicyPage.component"
import AccountManagementPage from "../../features/AccountManagementPage.component"

const publicRoutes = [
  { path: "/", component: HomePage },
  { path: "/fly-code", component: WorkoutsPage },
  { path: "/workouts", component: WorkoutsPage },
  { path: "/otf/:id", component: ChallengesPage },
  { path: "/privacy", component: PrivacyPolicyPage },
  { path: "/cookies", component: CookiePolicyPage },
  { path: "/terms", component: TermsOfServicePage },
  { path: "/configurator", component: ConfiguratorPage },
  { path: "/safety-tips", component: SafetyTipsPage },
  { path: "/account-management", component: AccountManagementPage },
]

export default publicRoutes
