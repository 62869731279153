import React, { useState } from "react"
import { NavLink } from "react-router-dom"

import { Box, Button, Divider, Link, Modal, TextField, Typography } from "@mui/material"

const modalStyle = {
  bgcolor: "#080808",
  border: "2px solid #080808",
  boxShadow: 24,
  left: "50%",
  maxWidth: "500px",
  outline: "none",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const PreviewCodeModal = ({ modalOpen, handleCloseModal, previewCode }) => {
  const [userCode, setUserCode] = useState("")
  const [userCodeError, setUserCodeError] = useState("")

  const onUserCodeChange = (e) => {
    if (userCodeError) setUserCodeError("")
    setUserCode(e.target.value)
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      handleCloseModal()
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (userCode !== previewCode) {
      setUserCodeError("Incorrect Code")
      return
    }

    localStorage.setItem("previewCode", userCode)
    handleCloseModal()
  }

  return (
    <Modal
      onClose={handleClose}
      open={modalOpen}
      aria-labelledby="exercise-modal-title"
    >
      <Box component="form" onSubmit={handleSubmit} sx={modalStyle}>
        <Typography
          component="h2"
          sx={{
            fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
            fontWeight: "600",
            lineHeight: "1.25",
            pb: 0.5,
            textTransform: "uppercase",
          }}
        >
          Signup Code
        </Typography>
        <Typography pb={2}>
          Enter the signup code from your email to access the Flycode preview.
        </Typography>
        <TextField
          error={!!userCodeError}
          fullWidth
          helperText={userCodeError}
          label="Signup Code"
          onChange={onUserCodeChange}
          value={userCode}
          variant="outlined"
        />
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2, width: { xs: "100%", sm: "auto" } }}
        >
          Submit
        </Button>
        <Divider sx={{ mb: 3, mt: 4 }} />
        <Typography>
          Already have an account?{" "}
          <Link component={NavLink} to="/signin">
            Sign in
          </Link>
        </Typography>
      </Box>
    </Modal>
  )
}

export default PreviewCodeModal
