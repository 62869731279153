export const tileContainerStyles = {
  alignItems: "flex-end",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
  position: "relative",
  ".backgroundImageContainer": {
    transition: "transform .4s",
  },
  "&:hover": {
    ".backgroundImageContainer": {
      transform: "scale(1.1)",
    },
  },
}

export const backgroundImageContainerStyles = {
  backgroundSize: "cover",
  borderRadius: 0,
  cursor: "pointer",
  display: "flex",
  height: "100%",
  minHeight: "300px",
  p: 2,
  width: "100%",
  "&::before": {
    content: '""',
    background: "rgba(0, 0, 0, 0.35)",
    position: "absolute",
    inset: "0px",
  },
}

export const tileTitleStyles = {
  backgroundColor: "primary.main",
  color: "black",
  fontWeight: 500,
  position: "absolute",
  py: 0.5,
  px: 3,
  right: 0,
  top: 0,
}

export const h1Styles = {
  fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
  fontWeight: "600",
  lineHeight: 1.25,
  pb: 2,
  textTransform: "uppercase",
}

export const h4Styles = {
  color: "text.primary",
  fontSize: { xs: "1.5rem", sm: "1.75rem", md: "2rem" },
  fontWeight: "500",
  marginTop: { xs: 4, sm: 6, md: 8 },
  pb: 2,
  textTransform: "uppercase",
}

export const playerOverlayStyles = {
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  position: "absolute",
  width: "100%",
}

export const difficultyStyles = {
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, .4)",
  bottom: 0,
  color: "text.primary",
  display: "flex",
  fontWeight: 500,
  justifyContent: "center",
  left: 0,
  marginLeft: "auto",
  marginRight: "auto",
  position: "absolute",
  py: 0.5,
  px: 3,
  right: 0,
  "& svg": {
    fontSize: 16
  }
}
