import React from "react"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

import { Checkbox, TableCell, TableRow } from "@mui/material"

const UsersTableRow = ({ selected, setSelected, user }) => {
  const navigate = useNavigate()

  const labelId = `enhanced-table-checkbox-${user.id}`
  const isSelected = (id) => selected.indexOf(id) !== -1
  const isItemSelected = isSelected(user.id)

  const handleClick = (e, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  return (
    <TableRow
      aria-checked={isItemSelected}
      hover
      key={user.id}
      onClick={() => navigate(`/admin/users/${user.id}`)}
      role="checkbox"
      selected={isItemSelected}
      tabIndex={-1}
      sx={{ cursor: "pointer" }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
          onClick={(e) => {
            e.stopPropagation()
            handleClick(e, user.id)
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {user.fname}
      </TableCell>
      <TableCell>{user.lname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell align="right">
        {user.lastActive
          ? DateTime.fromISO(user.lastActive).toFormat("LLL d, yyyy")
          : "n/a"}
      </TableCell>
      <TableCell align="right">{user.history.filter(day => day).length}</TableCell>
      <TableCell align="right">{user.isAdmin ? "Y" : "N"}</TableCell>
    </TableRow>
  )
}

export default UsersTableRow
