import React, { useContext, useState, useEffect } from "react"
import { getAuth } from "firebase/auth"

import { AuthContext } from "./app/contexts/AuthContext"
import { UserContext } from "./app/contexts/UserContext"
import { WorkoutsContext } from "./app/contexts/WorkoutsContext"
import { ExercisesContext } from "./app/contexts/ExercisesContext"
import { VideosContext } from "./app/contexts/VideosContext"

import AppRouter from "./app/router/AppRouter"
import LoadingPage from "./features/LoadingPage.component"

const App = () => {
  const { setAuthState } = useContext(AuthContext)
  const { startSetUser } = useContext(UserContext)
  const { startSetWorkouts } = useContext(WorkoutsContext)
  const { startSetExercises } = useContext(ExercisesContext)
  const { startSetVideos } = useContext(VideosContext)
  const [renderApp, setRenderApp] = useState(false)
  const auth = getAuth()

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      startSetWorkouts().then(() => {
        startSetExercises().then(() => {
          startSetVideos().then(() => {
            if (user) {
              setAuthState(user)
              startSetUser(user.uid).then(() => {
                setRenderApp(true)
              })
            } else {
              setRenderApp(true)
            }
            document.getElementById("footer").style.visibility = "visible"
          })
        })
      })
    })
  }, [])

  return (
    <React.Fragment>{renderApp ? <AppRouter /> : <LoadingPage />}</React.Fragment>
  )
}

export default App
