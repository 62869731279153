import React, { useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

const EditUserForm = ({ user, handleUpdateUser }) => {
  const [formValues, setFormValues] = useState({
    email: user?.email || "",
    fname: user?.fname || "",
    lastCompleted: user?.lastCompleted ? parseInt(user.lastCompleted) : 0,
    lname: user?.lname || "",
    username: user?.username || "",
  })
  const [isAdmin, setIsAdmin] = useState(user ? user.isAdmin : false)

  const handleFormChange = (property, value) => {
    setFormValues((prevState) => ({ ...prevState, [property]: value }))
  }

  const onLastCompletedChange = (e) => {
    if (e.target.value === "") {
      setFormValues((prevState) => ({
        ...prevState,
        lastCompleted: e.target.value,
      }))
      return
    }
    if (Number.isInteger(parseInt(e.target.value))) {
      setFormValues((prevState) => ({
        ...prevState,
        lastCompleted: parseInt(e.target.value),
      }))
      return
    }
  }

  const handleSubmit = () => handleUpdateUser(formValues, isAdmin)

  return (
    <Box component="form">
      <Typography component="h3" pb={4} variant="h5">
        Edit User
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Username"
            onChange={(e) => handleFormChange("username", e.target.value)}
            value={formValues.username}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            onChange={(e) => handleFormChange("fname", e.target.value)}
            value={formValues.fname}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            onChange={(e) => handleFormChange("lname", e.target.value)}
            value={formValues.lname}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            onChange={(e) => handleFormChange("email", e.target.value)}
            value={formValues.email}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isAdmin} />}
            label="Administrator Account?"
            onChange={() => setIsAdmin((prevState) => !prevState)}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={handleSubmit} variant="contained">
              Save Changes
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditUserForm
