import React, { useContext, useState } from "react"
import axios from "axios"

import { alpha } from "@mui/material/styles"
import {
  Box,
  Button,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { ExercisesContext } from "../../../app/contexts/ExercisesContext"

import AddExerciseModal from "./AddExerciseModal.component"
import ConfirmDeleteModal from "./ConfirmDeleteModal.component"

const ExercisesTableToolbar = ({ exercises, selected, setSelected, search, setSearch }) => {
  const { setAlert } = useContext(AlertContext)
  const { removeExercise } = useContext(ExercisesContext)

  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const handleOpenDeleteModal = () => setConfirmDeleteModalOpen(true)
  const handleCloseDeleteModal = () => setConfirmDeleteModalOpen(false)

  const handleDeleteExercises = () => {
    const exercisesToDelete = exercises.filter((exercise) => selected.includes(exercise.id))
    exercisesToDelete.forEach((exercise) => {
      axios
        .post("/api/exercises/remove", {
          exerciseId: exercise.id,
        })
        .then(() => {
          // remove exercise from store
          removeExercise(exercise.id)

          // remove is complete
          setSelected([])
          setAlert({
            message: `${
              selected.length > 1 ? "Exercises have" : "Exercise has"
            } been permanently deleted`,
            severity: "success",
          })
        })
        .catch((error) => {
          console.log("error deleting exercise", error.response.data)
          setAlert({
            message: "Unable to remove exercise from database",
            severity: "error",
          })
        })
    })
  }

  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <TextField
        label="Search exercises"
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        value={search}
        sx={{ flex: "1 1 100%", maxWidth: "600px" }}
      />
      {selected.length > 0 ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "nowrap",
            pl: 4,
          }}
        >
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            whiteSpace="nowrap"
          >
            {selected.length} selected
          </Typography>
          <Tooltip title="Delete">
            <IconButton
              onClick={(e) => {
                e.currentTarget.blur()
                handleOpenDeleteModal()
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <ConfirmDeleteModal
            modalOpen={confirmDeleteModalOpen}
            handleCloseModal={handleCloseDeleteModal}
            handleDeleteExercises={() => {
              handleCloseDeleteModal()
              handleDeleteExercises(selected)
            }}
            exercises={exercises}
            selected={selected}
          />
        </Box>
      ) : (
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{
            flexShrink: 0,
            marginLeft: { xs: 0.5, sm: 2 },
            whiteSpace: "nowrap",
          }}
        >
          + Exercise
        </Button>
      )}
      <AddExerciseModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        setSearch={setSearch}
      />
    </Toolbar>
  )
}

export default ExercisesTableToolbar
