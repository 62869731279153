import React, { useContext } from "react"
import { useLocation } from "react-router-dom"

import { Box, Grid, Typography } from "@mui/material"

import { WorkoutsContext } from "../../app/contexts/WorkoutsContext"

import PreviewTiles from "./PreviewTiles.component"
import HorizontalLevelIcon from "../../common/HorizontalLevelIcon.component"

import logo from "../../assets/images/flycode-logo.png"

const PreviewPage = () => {
  const { state } = useLocation()
  const { selectAllWorkouts } = useContext(WorkoutsContext)
  const workouts = selectAllWorkouts()

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              maxWidth: { xs: "150px", sm: "200px", md: "250px" },
              width: "100%",
            }}
          />
          <Typography
            component="h2"
            sx={{
              color: "primary.main",
              fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
              fontWeight: "600",
              lineHeight: "1.25",
              marginTop: "20px",
              pb: 0.5,
              textTransform: "uppercase",
            }}
          >
            90-Day Program
          </Typography>
          <Typography fontSize="1.125rem" pb={2}>
            Get ready to sweat. Get ready for Cardio 2.0.
          </Typography>
          <Typography fontSize="1.125rem" pb={2}>
            Discover the Flyweight difference.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ alignItems: "center", display: "flex" }}
              >
                <HorizontalLevelIcon size={4} level={2} margin="0 8px 4px 0" />
                <Typography>Phase 1 Intensity</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ alignItems: "center", display: "flex" }}
              >
                <HorizontalLevelIcon size={4} level={3} margin="0 8px 4px 0" />
                <Typography>Phase 2 Intensity</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ alignItems: "center", display: "flex" }}
              >
                <HorizontalLevelIcon size={4} level={4} margin="0 8px 4px 0" />
                <Typography>Phase 3 Intensity</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {workouts && (
        <PreviewTiles
          workouts={workouts}
          unlocked={state && state.unlocked ? state.unlocked : null}
        />
      )}
    </Box>
  )
}

export default PreviewPage
