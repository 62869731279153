import React from "react"

import { Box, Stack, Typography } from "@mui/material"

import MainTimer from "./MainTimer.component"
import RestTimer from "./RestTimer.component"
import StartPauseButton from "./StartPauseButton.component"
import RestartButton from "./RestartButton.component"
import CompleteWorkoutButton from "./CompleteWorkoutButton.component"

const TimerContainer = ({
  pauseAll = false,
  currentDay = null,
  timer,
  setTimer,
  restTimer,
  setRestTimer,
  timerPaused,
  setTimerPaused,
  setGifPaused,
  resetTimer,
  preview = false,
}) => {
  return (
    <Box
      sx={{
        alignSelf: { xs: "stretch", sm: "center", md: "flex-start" },
        backgroundColor: "#080808",
        marginTop: { xs: 2, md: 0 },
        minWidth: "335px",
        padding: 3,
      }}
    >
      <Stack direction="row" sx={{ justifyContent: { xs: "center", md: "flex-start" } }}>
        <Stack
          sx={{
            alignItems: { xs: "flex-end", md: "flex-start" },
            alignSelf: { xs: "center", md: "flex-start" },
            flexDirection: { xs: "row", md: "column" },
            justifyContent: { xs: "flex-end", md: "flex-start" },
          }}
        >
          <Typography
            component="span"
            sx={{
              ...(timerPaused && !pauseAll
                ? { color: "tertiary.main" }
                : { color: "primary.main" }),
              alignSelf: "center",
              fontSize: { xs: "1.25rem", md: "1.875rem", lg: "2.125rem" },
              fontWeight: 500,
              lineHeight: 1,
              paddingBottom: { md: 1 },
              paddingRight: { xs: 1, md: 0 },
            }}
          >
            {timerPaused && !pauseAll ? "Stop Time" : "Go Time"}
          </Typography>
          {timerPaused && !pauseAll ? (
            <RestTimer
              pauseAll={pauseAll}
              resting={timerPaused}
              restTimer={restTimer}
              setRestTimer={setRestTimer}
            />
          ) : (
            <MainTimer
              pauseAll={pauseAll}
              timer={timer}
              setTimer={setTimer}
              timerPaused={timerPaused}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignItems: { xs: "center", md: "flex-end" },
            marginLeft: { xs: 1, md: 3 },
            paddingBottom: { md: "10px" }
          }}
        >
          <StartPauseButton
            pauseAll={pauseAll}
            timerPaused={timerPaused}
            setTimerPaused={setTimerPaused}
            setGifPaused={setGifPaused}
          />
          <RestartButton resetTimer={resetTimer} />
        </Stack>
      </Stack>
      {currentDay && <CompleteWorkoutButton currentDay={currentDay} preview={preview} />}
    </Box>
  )
}

export default TimerContainer
