import React, { useState } from "react"

import { Box, Grid } from "@mui/material"

import { tileContainerStyles, tileStyles, tileBackgroundStyles, tileTitleStyles } from "./ConfigurationTiles.styles"

import overviewImg from "../../assets/images/configurator-overview.jpg"
import f1Img from "../../assets/images/configurator-f1.jpg"
import f2Img from "../../assets/images/configurator-f2.jpg"
import f3Img from "../../assets/images/configurator-f3.jpg"
import f4Img from "../../assets/images/configurator-f4.jpg"
import f5Img from "../../assets/images/configurator-f5.jpg"
import f6Img from "../../assets/images/configurator-f6.jpg"
import f7Img from "../../assets/images/configurator-f7.jpg"
import f8Img from "../../assets/images/configurator-f8.jpg"
import ConfigurationVideoModal from "./ConfigurationVideoModal.component"

const overviewVideo =
  "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FLong.mp4?alt=media&token=b0343801-9289-4a40-9bcf-6da316a68d40"
const f1Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF1.mp4?alt=media&token=f7df9f2d-ca55-49ed-b7d5-6af90757054c"
const f2Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF2.mp4?alt=media&token=c1d5478a-4b37-4ae8-9876-9cb96985bbe1"
const f3Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF3.mp4?alt=media&token=9be36c11-2ad6-4c7d-a827-6202b1a6a55c"
const f4Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF4.mp4?alt=media&token=6d460043-004c-4add-8418-78a3e23b89dd"
const f5Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF5.mp4?alt=media&token=82b50faf-76e2-4738-ac67-fad9be251210"
const f6Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF6.mp4?alt=media&token=758218a7-9f30-4bad-a947-1b4d2d07af06"
const f7Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF7.mp4?alt=media&token=043ce86c-fca4-4c63-a328-574c605b9e51"
const f8Video = "https://firebasestorage.googleapis.com/v0/b/flyweight-d05d5.appspot.com/o/videos%2Fconfigurator%2FF8.mp4?alt=media&token=e4732c8f-1bc7-4eb3-bcea-985146f8212e"

export const configVideos = [
  { config: "na", title: "Overview", thumbnail: overviewImg, url: overviewVideo },
  { config: "F1", title: "F1", thumbnail: f1Img, url: f1Video },
  { config: "F2", title: "F2", thumbnail: f2Img, url: f2Video },
  { config: "F3", title: "F3", thumbnail: f3Img, url: f3Video },
  { config: "F4", title: "F4", thumbnail: f4Img, url: f4Video },
  { config: "F5", title: "F5", thumbnail: f5Img, url: f5Video },
  { config: "F6", title: "F6", thumbnail: f6Img, url: f6Video },
  { config: "F7", title: "F7", thumbnail: f7Img, url: f7Video },
  { config: "F8", title: "F8", thumbnail: f8Img, url: f8Video },
]

const ConfigurationTiles = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const handleVideoModalOpen = (url) => {
    setCurrentVideo(url)
    setVideoModalOpen(true)
  }
  const handleVideoModalClose = () => {
    setCurrentVideo(null)
    setVideoModalOpen(false)
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container spacing={2} mt={4}>
        {configVideos.map((video) => (
          <Grid
            item
            key={video.title}
            id={`${video.title}-tile`}
            xs={12}
            sm={6}
            md={4}
            sx={tileContainerStyles}
          >
            <Box
              onClick={() => handleVideoModalOpen(video.url)}
              sx={tileStyles}
            >
              <Box
                className="backgroundImageContainer"
                sx={{
                  alignItems: "center",
                  background: `url(${video.thumbnail})`,
                  backgroundRepeat: "",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: "100%",
                  width: "100%",
                }}
              />
              <Box sx={tileTitleStyles}>
                {video.title}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <ConfigurationVideoModal
        videoModalOpen={videoModalOpen}
        handleVideoModalClose={handleVideoModalClose}
        video={currentVideo}
      />
    </Box>
  )
}

export default ConfigurationTiles
