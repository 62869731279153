import React, { createContext, useState, useEffect } from "react"
import { fetchAllUsers } from "./AdminUsersFetch"

export const AdminUsersContext = createContext()

const initialState = []

export const AdminUsersProvider = (props) => {
  const [usersState, setUsersState] = useState(initialState)

  useEffect(() => {
    fetchAllUsers().then((data) => setUsersState(data))
  }, [])

  const addUser = (user) => setUsersState((prevState) => [...prevState, user])

  const updateUser = (user) =>
    setUsersState((prevState) => [
      ...prevState.filter((u) => u.id !== user.id),
      {
        ...prevState.filter((u) => u.id === user.id)[0],
        ...user,
      },
    ])

  const removeUser = (userId) =>
    setUsersState((prevState) =>
      prevState.filter((prevUser) => prevUser.id !== userId)
    )

  const selectUsers = () => usersState

  const selectUser = (id) => usersState.filter((user) => user.id === id)[0]

  return (
    <AdminUsersContext.Provider
      value={{
        usersState,
        addUser,
        updateUser,
        removeUser,
        selectUsers,
        selectUser,
      }}
    >
      {props.children}
    </AdminUsersContext.Provider>
  )
}

export default AdminUsersProvider
