import axios from "axios"

export const fetchAllUsers = () => {
  return new Promise(resolve => {
    axios.get("/api/users/all").then(res => {
      const data = res.data
      const dataArray = []
      const emptyArray = new Array(91).fill(false)
      for (const [key, value] of Object.entries(data)) {
        const { history, ...restOfValue } = value
        const historyArray = history ? history["90-day-workout"] : []
        const newHistoryArray = historyArray.length
          ? emptyArray.map((value, i) =>
              historyArray[i] ? historyArray[i] : value
            )
          : emptyArray

        dataArray.push({
          id: key,
          history: newHistoryArray,
          ...restOfValue
        })
      }
      resolve(dataArray)
    }).catch(error => {
      console.log(error.response.data)
      resolve([])
    })

  })
}
