import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"

import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"

import Breadcrumbs from "../../../common/Breadcrumbs.component"
import EditUserForm from "./EditUserForm.component"
import UserPageDetails from "./UserPageDetails.component"

const UserPage = () => {
  const { userId } = useParams()
  const { setAlert } = useContext(AlertContext)
  const { selectUser, updateUser } = useContext(AdminUsersContext)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (userId) setUser(selectUser(userId))
  }, [userId, selectUser])

  const handleUpdateUser = (formValues, isAdmin) => {
    const dbObj = {
      email: formValues.email,
      fname: formValues.fname,
      isAdmin,
      lastCompleted: formValues.lastCompleted,
      lname: formValues.lname,
      username: formValues.username,
    }

    // Update user in rtdb
    axios
      .post("/api/users/update", {
        userId,
        dbObj,
      })
      .then((res) => {
        // Update user in context
        updateUser({ id: userId, ...dbObj })

        // udpate is complete
        setAlert({
          message: `Updates to user ${dbObj.fname} ${dbObj.lname} have been saved`,
          severity: "success",
        })
      })
      .catch((error) => {
        console.log("error writing to db", error.response.data)
        setAlert({ message: "Error updating user", severity: "error" })
      })
  }

  return (
    <>
      {user ? (
        <Breadcrumbs
          pages={[
            { title: "Admin", to: "/admin" },
            { title: "Users", to: "/admin/users" },
            { title: `${user.fname} ${user.lname}` },
          ]}
        />
      ) : (
        <Skeleton sx={{ height: "18px", width: "200px" }} />
      )}
      {user ? (
        <Typography component="h1" variant="h4" pb={4}>
          {user.fname} {user.lname}
        </Typography>
      ) : (
        <Skeleton sx={{ height: "74px", width: "250px" }} />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box component={Paper} p={4}>
            {user && (
              <EditUserForm user={user} handleUpdateUser={handleUpdateUser} />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <UserPageDetails user={user} />
        </Grid>
      </Grid>
    </>
  )
}

export default UserPage
