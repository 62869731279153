import React from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Button, Typography } from "@mui/material"
import CampaignIcon from "@mui/icons-material/Campaign"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"

const CoachingCuesButton = ({ showCoachingCues, setShowCoachingCues }) => {
  const isMedium = useMediaQuery("(min-width: 900px)")
  const isLarge = useMediaQuery("(min-width: 1200px)")

  const handleClick = e => {
    e.stopPropagation()
    setShowCoachingCues((prevState) => !prevState)
  }

  return (
    <Button
      onClick={handleClick}
      size={isLarge ? "medium" : "small"}
      variant="outlined"
      sx={{
        borderColor: "#fff",
        color: "#fff",
        "&: hover": {
          backgroundColor: "rgba(255,255,255,.08)",
          borderColor: "#fff",
        },
        // offset the button so it does not sit under swiper buttons
        marginRight: { xs: 0, md: "30px" },
      }}
    >
      {showCoachingCues ? (
        <VisibilityOffIcon sx={{ fontSize: { xs: "1.25rem", lg: "1.75rem" } }} />
      ) : (
        <CampaignIcon sx={{ fontSize: { xs: "1.25rem", lg: "1.75rem" } }} />
      )}
      {isMedium && (
        <Typography ml={1}>
          {showCoachingCues ? "Hide" : "Show"} coaching cues
        </Typography>
      )}
    </Button>
  )
}

export default CoachingCuesButton
