import React from "react"
import PropTypes from "prop-types"

import { Box, Button, Modal, Stack, Typography } from "@mui/material"

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  left: "50%",
  maxWidth: "600px",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const ConfirmationModal = ({
  modalOpen,
  handleConfirmation,
  handleCloseModal,
  modalTitle = "Are you sure?",
  modalBody = "This action cannot be undone.",
  confirmBtnText = "Yes",
  cancelBtnText = "Cancel",
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="confirmation-modal"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="confirmation-modal" variant="h6" component="h2">
          {modalTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {modalBody}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: "flex-end", marginTop: "2rem" }}
        >
          <Button onClick={handleConfirmation} variant="contained">
            {confirmBtnText}
          </Button>
          <Button onClick={handleCloseModal} color="error" variant="outlined">
            {cancelBtnText}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}

export default ConfirmationModal
