export const tileContainerStyles = {
  height: "300px",
  margin: "0 auto",
  maxWidth: "360px",
  overflow: "hidden",
}

export const tileStyles = {
  alignItems: "center",
  boxShadow:
    "0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02)",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  ".backgroundImageContainer": {
    transition: "transform .5s",
  },
  "&:hover": {
    ".backgroundImageContainer": {
      transform: "scale(1.2)",
    },
  },
}

export const tileTitleStyles = {
  fontSize: { xs: "1rem", sm: "1.125rem" },
  fontWeight: "600",
  maxWidth: "75%",
  letterSpacing: ".2rem",
  padding: { xs: "0.5rem 1rem", sm: "1rem 2rem" },
  textAlign: "center",
  textTransform: "uppercase",
}
