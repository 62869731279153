import React, { useState, createContext } from "react"

export const AlertContext = createContext()

const initialState = {
  key: null,
  message: "",
  severity: "info",
}

export const AlertProvider = props => {
  const [alertState, setAlertState] = useState(initialState)

  const setAlert = (alert) => {
    setAlertState(prevState => ({
      ...prevState,
      key: new Date().getTime(),
      ...alert
    }))
  }

  const clearAlert = () => setAlertState(initialState)

  return (
    <AlertContext.Provider value={{
      alertState,
      setAlert,
      clearAlert
    }}>
      {props.children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
