import React, { useEffect } from "react"

import { styled } from "@mui/material/styles"

const StyledTimer = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: `"Source Pro Code", monospace`,
  fontSize: "2rem",
  fontWeight: 300,
  letterSpacing: "0",
  lineHeight: 1,
  padding: "0px 2px 2px 2px",
  background:
    "conic-gradient(from 90deg at top 3px left 3px, #0000 90deg, " +
    theme.palette.primary.main +
    " 0) 0 0, conic-gradient(from 180deg at top 3px right 3px, #0000 90deg, " +
    theme.palette.primary.main +
    " 0) 100% 0, conic-gradient(from 0deg at bottom 3px left 3px, #0000 90deg, " +
    theme.palette.primary.main +
    " 0) 0 100%, conic-gradient(from -90deg at bottom 3px right 3px, #0000 90deg, " +
    theme.palette.primary.main +
    " 0) 100% 100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "10px 10px",
  backgroundOrigin: "border-box",
  border: "5px solid transparent",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.5rem",
    letterSpacing: "-.1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3.5rem",
    letterSpacing: "-.2rem",
    padding: "2px 8px 6px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
    letterSpacing: "-.3rem",
  },
}))

const MainTimer = ({ pauseAll, timer, setTimer, timerPaused }) => {
  useEffect(() => {
    if (pauseAll) return
    if (timer >= 3600) return

    const interval = setInterval(() => {
      if (!timerPaused) setTimer(timer + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [pauseAll, timer, setTimer, timerPaused])

  return (
    <StyledTimer>
      {timer < 600 && "0"}
      {Math.floor(timer / 60)}:{timer % 60 < 10 && "0"}
      {timer % 60}
    </StyledTimer>
  )
}

export default MainTimer
