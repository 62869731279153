import React from 'react';

import { Box } from "@mui/material";
import StarOutlineIcon from "@mui/icons-material/StarOutline"
import StarRateIcon from "@mui/icons-material/StarRate"

const DifficultyStars = ({ difficulty }) => {
  const stars = [1, 2, 3, 4, 5]
  return (
    <Box alignItems="center" display="flex" ml={0.5}>
      {stars.map((star) => {
        if (star <= difficulty) return <StarRateIcon key={star} sx={{ color: "primary.main" }} />
        else return <StarOutlineIcon key={star} sx={{ color: "primary.main" }} />
      })}
    </Box>
  )
}

export default DifficultyStars