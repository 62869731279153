import React, { useState } from "react"

import { Button } from "@mui/material"

import AddExercisesModal from "./AddExercisesModal.component"

const AddExercisesButton = ({ workout, videoList, setWorkoutVideos }) => {

  const [addModalOpen, setAddModalOpen] = useState(false)

  const handleOpenAddModal = () => setAddModalOpen(true)
  const handleCloseAddModal = () => setAddModalOpen(false)

  return (
    <>
      <Button
        onClick={handleOpenAddModal}
        size="small"
        variant="text"
        sx={{  }}
      >
        + Add Exercises
      </Button>
      <AddExercisesModal
        modalOpen={addModalOpen}
        handleCloseModal={handleCloseAddModal}
        workout={workout}
        videoList={videoList}
        setWorkoutVideos={setWorkoutVideos}
      />
    </>
  )
}

export default AddExercisesButton
