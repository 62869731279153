import React from "react"

import { List, ListItem, ListItemText } from "@mui/material"

const safetyTips = [
  {
    primary: "Consult with your healthcare provider",
    secondary:
      "Before beginning the Flyweight exercise routine, it's essential to consult with your healthcare provider, especially if you have any underlying medical conditions or injuries that could be affected by physical activity.",
  },
  {
    primary: "Start slowly",
    secondary:
      "If you're new to exercise or the Flyweight product, it's important to start slowly and gradually increase the intensity and duration of your workouts. Tailor the workouts based on your own knowledge of your body and scale the workouts up or down in intensity as is appropriate to your individual capabilities.",
  },
  {
    primary: "Warm-up and cool-down",
    secondary:
      "Always warm up before your Flyweight workout to prepare your muscles and prevent injury. Cooling down after your Flyweight workout is just as important to help your body return to a resting state.",
  },
  {
    primary: "Sufficient hydration",
    secondary:
      "Ensuring sufficient hydration is essential before, during, and after your Flyweight workout. Drink water or other fluids as needed to stay hydrated.",
  },
  {
    primary: "Wear appropriate attire",
    secondary:
      "Wear comfortable, breathable clothing and supportive shoes that are appropriate for your Flyweight workout.",
  },
  {
    primary: "Listen to your body",
    secondary:
      "Only you know your individual capabilities, so it's important to listen to your body and tailor the workouts based on your own knowledge of your body. If you experience pain, dizziness, or shortness of breath during your Flyweight workout, stop immediately and rest.",
  },
  {
    primary: "Don't assume",
    secondary:
      "Never assume that the workout instructions provided in the Flyweight app are appropriate for your individual capabilities. It's important to take the time to assess your current fitness level and make modifications to the workouts as needed.",
  },
  {
    primary: "Safety first",
    secondary:
      "It's crucial to understand how to safely perform each Flyweight exercise before attempting it. If you're unsure about how to perform an exercise, seek guidance from a certified personal trainer or fitness professional to ensure you're performing it correctly and safely.",
  },
  {
    primary: "Take rest days",
    secondary:
      "Rest days are just as important as Flyweight workout days. Give your body time to recover and repair itself before jumping back into your routine. Tailor the workouts based on your own knowledge of your body and scale the workouts up or down in intensity as needed on rest days to avoid overexertion.",
  },
  {
    primary: "Be mindful of demonstrations",
    secondary:
      "Some Flyweight workouts are shown as demonstrations by high-level exercisers. These workouts may not be appropriate for everyone and should not be attempted without proper preparation and guidance from a certified personal trainer or fitness professional.",
  },
]

const SafetyTips = () => (
  <List sx={{ fontSize: "1.25rem", maxWidth: "75ch" }}>
    {safetyTips.map((tip, i) => (
      <ListItem disableGutters key={i}>
        <ListItemText
          primary={tip.primary}
          primaryTypographyProps={{ fontSize: "1.125rem" }}
          secondary={tip.secondary}
          secondaryTypographyProps={{ fontSize: "1.125rem" }}
        />
      </ListItem>
    ))}
  </List>
)

export default SafetyTips
