import React, { useContext, useEffect, useState } from "react"
import { DateTime } from "luxon"

import { styled } from "@mui/material/styles"
import { Box, Grid, Link, Paper, Skeleton, Stack, Typography } from "@mui/material"

import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"
import { VideosContext } from "../../../app/contexts/VideosContext"

const StyledThumbnail = styled("img")(() => ({
  maxWidth: "450px",
  width: "100%",
}))

const CoverImage = ({ exercise }) => {
  const { selectThumbnailUrl } = useContext(VideosContext)
  const thumbnailUrl = selectThumbnailUrl(exercise.videos ? exercise.videos[0] : "")

  return (
    <Link href={thumbnailUrl} target="_blank" rel="noreferrer">
      <StyledThumbnail
        src={thumbnailUrl}
        alt="thumbnail"
      />
    </Link>
  )
}

const ExerciseOverview = ({ exercise }) => {
  const { selectUser } = useContext(AdminUsersContext)

  const [lastUpdatedBy, setLastUpdatedBy] = useState(null)

  useEffect(() => {
    if (exercise && exercise.lastUpdatedBy)
      setLastUpdatedBy(selectUser(exercise.lastUpdatedBy))
  }, [exercise, selectUser])

  return (
    <Box component={Paper} sx={{ padding: { xs: 2, md: 4 } }}>
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: {
            xs: "column-reverse",
            sm: "row",
            md: "column-reverse",
            lg: "row",
          },
        }}
      >
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Stack spacing={2}>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                ID: 
              </Typography>
              {exercise && exercise.id}
            </Stack>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                Last Updated
              </Typography>
              {exercise && exercise.lastUpdated ? (
                <Typography component="span">
                  {DateTime.fromISO(exercise.lastUpdated).toLocaleString(
                    DateTime.DATETIME_FULL
                  )}
                </Typography>
              ) : (
                <Skeleton sx={{ maxWidth: "200px" }} />
              )}
            </Stack>
            <Stack>
              <Typography component="span" fontSize=".875rem" fontWeight="600">
                Last Updated By
              </Typography>
              {lastUpdatedBy ? (
                <Typography component="span">
                  {`${lastUpdatedBy.fname} ${lastUpdatedBy.lname}`}
                </Typography>
              ) : (
                <Skeleton sx={{ maxWidth: "200px" }} />
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <CoverImage exercise={exercise} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ExerciseOverview
