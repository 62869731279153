import React from "react"
import { NavLink } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Typography } from "@mui/material"

import {
  tileContainerStyles,
  backgroundImageContainerStyles,
  tileTitleStyles,
  h4Styles,
  difficultyStyles,
} from "./HomePage.styles"

import { onTheFlyChallenges } from "../Challenges/ChallengesPage.component"
import DifficultyStars from "../Challenges/DifficultyStars.component"

const HomePageOnTheFly = () => {
  const isSmall = useMediaQuery("(min-width: 600px)")
  const isMedium = useMediaQuery("(min-width: 900px)")

  return (
    <Box>
      <Typography component="h3" variant="h5" sx={h4Styles}>
        On-the-Fly Challenges
      </Typography>
      <Swiper
        className="liveWorkoutsSwiper"
        modules={[Navigation, Pagination]}
        navigation={isSmall}
        pagination={{
          clickable: true,
        }}
        slidesPerView={isMedium ? 3 : isSmall ? 2 : 1}
        spaceBetween={30}
      >
        {onTheFlyChallenges.length > 0 &&
          onTheFlyChallenges.map((challenge) => (
            <SwiperSlide key={challenge.id}>
              <Box
                component={NavLink}
                to={`/otf/${challenge.id}`}
                sx={tileContainerStyles}
              >
                <Box
                  className="backgroundImageContainer"
                  sx={{
                    ...backgroundImageContainerStyles,
                    backgroundImage: `url('${challenge.image}')`,
                    backgroundPosition: {
                      xs: "center",
                      sm: "center right",
                    },
                  }}
                />
                <Typography sx={tileTitleStyles}>{challenge.title}</Typography>
                <Box sx={difficultyStyles}>
                  Difficulty:
                  <DifficultyStars difficulty={challenge.difficulty} />
                </Box>
              </Box>
            </SwiperSlide>
          ))}
      </Swiper>
    </Box>
  )
}

export default HomePageOnTheFly
