import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"
import axios from "axios"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Box, Grid, Paper, Typography } from "@mui/material"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"
import { UserContext } from "../../../app/contexts/UserContext"

import Breadcrumbs from "../../../common/Breadcrumbs.component"
import WorkoutForm from "./WorkoutForm.component"
import WorkoutOverview from "./WorkoutOverview.component"
import ManageExercises from "./ManageExercises.component"

const WorkoutPage = () => {
  const isMedium = useMediaQuery("(min-width: 900px)")
  const { day } = useParams()
  const { setAlert } = useContext(AlertContext)
  const { selectWorkoutByDay, updateWorkout } = useContext(WorkoutsContext)
  const workout = selectWorkoutByDay(day)
  const { selectUid } = useContext(UserContext)
  // const [workout, setWorkout] = useState(null)

  // useEffect(() => {
  //   if (day) setWorkout(selectWorkoutByDay(day))
  // }, [day, selectWorkoutByDay])

  const handleUpdateWorkout = (id, formValues) => {
    const dbObj = {
      ...formValues,
      lastUpdated: DateTime.now().setZone("America/New_York").toISO(),
      lastUpdatedBy: selectUid(),
    }

    axios
      .post("/api/workouts/update", {
        id,
        dbObj,
      })
      .then((res) => {
        // Add new workout to store
        updateWorkout({ id, ...dbObj })
        setAlert({
          message: "Workout updated",
          severity: "success",
        })
      })
      .catch((error) => {
        console.log("error updating workout in database: ", error.message)
        setAlert({
          message: `Error updating workout: ${error.message}`,
          severity: "error",
        })
      })
  }

  return (
    <div>
      <Breadcrumbs
        pages={[
          { title: "Admin", to: "/admin" },
          { title: "Workouts", to: "/admin/workouts" },
          { title: `Day ${day}` },
        ]}
      />
      <Typography component="h1" variant="h4" pb={4}>
        {`Day ${day}`}
      </Typography>
      {workout && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box component={Paper} sx={{ padding: { xs: 2, md: 4 } }}>
              <WorkoutForm
                workout={workout}
                handleSaveWorkout={handleUpdateWorkout}
              />
            </Box>
          </Grid>
          {isMedium ? (
            <>
              <Grid item xs={12} md={4}>
                <WorkoutOverview workout={workout} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ManageExercises workout={workout} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} lg={8}>
                <ManageExercises workout={workout} />
              </Grid>
              <Grid item xs={12} md={4}>
                <WorkoutOverview workout={workout} />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </div>
  )
}

export default WorkoutPage
