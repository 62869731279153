import React from "react"

import { styled } from "@mui/material/styles"
import { Stack, Tooltip, Typography } from "@mui/material"

const StyledInput = styled("input", {
  shouldForwardProp: (prop) => prop !== "error",
})(({ theme, error }) => ({
  border: "1px solid",
  borderColor: "#fff",
  borderRadius: "0.25rem",
  fontFamily: theme.typography.fontFamily,
  fontSize: "1rem",
  outline: "1px solid",
  ...(error
    ? { outlineColor: theme.palette.error.light }
    : { outlineColor: "#fff" }),
  padding: "8.5px 14px",
  "&: hover": {
    outline: `1px solid ${theme.palette.grey[400]}`,
  },
  "&: focus": {
    borderColor: theme.palette.primary.main,
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up("lg")]: {
    padding: "16.5px 14px",
  }
}))

const StyledTextField = ({
  error,
  helperText,
  label,
  value,
  tooltip,
  ...restOfProps
}) => {
  return (
    <Stack>
      <Typography
        component="label"
        fontSize="1.125rem"
        fontWeight="300"
        htmlFor="email"
        pb={0.5}
      >
        {label}
        {tooltip && (
          <>
            {" "}
            <Tooltip placement="top" title={tooltip.tip}>
              <Typography component="span" color="primary" fontSize=".75rem">
                {tooltip.text}
              </Typography>
            </Tooltip>
          </>
        )}
      </Typography>
      <StyledInput error={error} value={value} {...restOfProps} />
      <Typography
        color={error ? "error.light" : "text.primary"}
        component="label"
        htmlFor="email"
        sx={{ fontSize: { xs: ".75rem", lg: ".875rem" }, lineHeight: 1.5 }}
      >
        {helperText}
      </Typography>
    </Stack>
  )
}

export default StyledTextField
