import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material"

import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"
import { WorkoutsContext } from "../../../app/contexts/WorkoutsContext"
import { ExercisesContext } from "../../../app/contexts/ExercisesContext"
import { VideosContext } from "../../../app/contexts/VideosContext"

import Breadcrumbs from "../../../common/Breadcrumbs.component"

const DashboardPage = () => {
  const navigate = useNavigate()
  const { selectUsers } = useContext(AdminUsersContext)
  const users = selectUsers()
  const { selectAllWorkouts } = useContext(WorkoutsContext)
  const workouts = selectAllWorkouts()
  const { selectAllExercises } = useContext(ExercisesContext)
  const exercises = selectAllExercises()
  const { selectAllVideos } = useContext(VideosContext)
  const videos = selectAllVideos()

  const [activeToday, setActiveToday] = useState(0)
  const [avgDaysCompleted, setAvgDaysCompleted] = useState(0)
  const [totalExercises, setTotalExercises] = useState(0)
  const [totalDaysCompleted, setTotalDaysCompleted] = useState(0)

  useEffect(() => {
    setActiveToday(users.filter((user) =>
      DateTime.fromISO(user.lastActive).hasSame(DateTime.local(), "day")
    ).length)

    let total = 0
    users.forEach(user => {
      if (Number.isInteger(user.lastCompleted)) total += user.lastCompleted
    })
    setTotalDaysCompleted(total)
    setAvgDaysCompleted(Math.round(total / users.length))
  }, [users])

  useEffect(() => {
    let total = 0
    workouts.forEach(workout => total += workout.videos?.length)
    setTotalExercises(total)
  }, [workouts])

  const manageUsers = () => navigate("/admin/users")
  const manageWorkouts = () => navigate("/admin/workouts")
  const manageExercises = () => navigate("/admin/exercises")

  return (
    <>
      <Breadcrumbs pages={[{ title: "Admin" }]} />
      <Typography component="h1" variant="h4" pb={4}>
        Dashboard
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "3rem", lineHeight: 1 }}>
                {users.length}
              </Typography>
              <Typography>Users</Typography>
              <Typography mt={2}>
                {activeToday} active today
              </Typography>
              <Typography>
                {avgDaysCompleted} avg days completed
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={manageUsers}>Manage Users</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "3rem", lineHeight: 1 }}>
                {workouts.length}
              </Typography>
              <Typography>Workouts</Typography>
              <Typography mt={2}>
                {totalExercises} exercises
              </Typography>
              <Typography>
                {totalDaysCompleted} total workouts completed
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={manageWorkouts}>Manage Workouts</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "3rem", lineHeight: 1 }}>
                {exercises.length}
              </Typography>
              <Typography>Exercises</Typography>
              <Typography mt={2}>
                {videos.length} videos
              </Typography>
              <Typography>
                &nbsp;
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={manageExercises}>Manage Exercises</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default DashboardPage
