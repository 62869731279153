import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import axios from "axios"

import { Box, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { UserContext } from "../../../app/contexts/UserContext"
import { ExercisesContext } from "../../../app/contexts/ExercisesContext"
import { VideosContext } from "../../../app/contexts/VideosContext"

import ExerciseForm from "./ExerciseForm.component"

const style = {
  bgcolor: "background.paper",
  border: "1px solid",
  borderColor: "grey.400",
  boxShadow: 24,
  left: "50%",
  maxHeight: "90vh",
  maxWidth: "800px",
  overflowY: "auto",
  padding: { xs: 2, sm: 4, md: 6 },
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const AddExerciseModal = ({ modalOpen, handleCloseModal }) => {
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)
  const { selectUid } = useContext(UserContext)
  const { addExercise } = useContext(ExercisesContext)
  const { addVideo } = useContext(VideosContext)

  const saveVideoInfo = (parentExerciseId, videos) => {
    Object.keys(videos).forEach(videoId => {
      const dbObj = {
        parentExerciseId,
        ...videos[videoId]
      }

      axios.post("/api/videos/add", {
        id: videoId,
        dbObj
      }).then(res => {
        addVideo({
          id: videoId,
          ...dbObj
        })
      }).catch(error => {
        console.log(error.response.data)
      })
    })
  }

  const handleSaveExercise = (id, videos, formValues) => {
    const created = DateTime.now().setZone("America/New_York").toISO()
    const createdBy = selectUid()
    const dbObj = {
      ...formValues,
      created,
      createdBy,
      lastUpdated: created,
      lastUpdatedBy: createdBy,
    }

    if (videos) saveVideoInfo(id, videos)

    axios
      .post("/api/exercises/add", {
        id,
        dbObj,
      })
      .then((res) => {
        // Add new exercise to store
        addExercise({ id, ...dbObj })
        setAlert({
          message: "Exercise added",
          severity: "success",
        })
        handleCloseModal()
        navigate(`/admin/exercises/${id}`)
      })
      .catch((error) => {
        console.log("error creating exercise in database: ", error.message)
        setAlert({
          message: `Error creating exercise: ${error.message}`,
          severity: "error",
        })
      })
  }

  return (
    <Modal
      onClose={handleCloseModal}
      open={modalOpen}
      aria-labelledby="exercise-modal-title"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: { xs: 2, sm: 8 },
            right: { xs: 2, sm: 8 },
          }}
        >
          <CloseIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
        <ExerciseForm
          handleSaveExercise={handleSaveExercise}
        />
      </Box>
    </Modal>
  )
}

export default AddExerciseModal
