import React, { useContext, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { Button, IconButton, Stack, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

import { AuthContext } from "../app/contexts/AuthContext"

import logo from "../assets/images/logo.png"
import MenuDrawer from "./MenuDrawer.component"

const StyledHeader = styled("header")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "1.5rem",
  width: "100%",
  zIndex: 1000,
  [theme.breakpoints.up("md")]: {
    marginBottom: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: "3rem",
  },
}))

const StyledLogo = styled("img")(({ theme }) => ({
  maxWidth: "250px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "300px",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "350px",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "450px",
  },
}))

const Header = ({ preview = false }) => {
  const { authState } = useContext(AuthContext)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const navigate = useNavigate()
  const isSmall = useMediaQuery("(min-width: 600px)")

  return (
    <StyledHeader>
      <Typography
        component={NavLink}
        to={preview ? "/preview" : "/"}
        sx={{ alignItems: "center", display: "flex" }}
      >
        <StyledLogo src={logo} alt="Flyweight" />
      </Typography>
      {authState.uid ? (
        <>
          <IconButton aria-label="Menu" onClick={() => setDrawerOpen(true)}>
            <MenuIcon fontSize={isSmall ? "large" : "medium"} />
          </IconButton>
          <MenuDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </>
      ) : (
        <Stack direction="row" ml={1} spacing={isSmall ? 1 : 0.5}>
          <Button
            onClick={() => navigate("/signin")}
            size={isSmall ? "medium" : "small"}
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              whiteSpace: "nowrap",
              "&: hover": { backgroundColor: "grey.200" },
            }}
          >
            Sign in
          </Button>
          <Button
            onClick={() => navigate("/signup")}
            size={isSmall ? "medium" : "small"}
            sx={{
              color: "#fff",
              whiteSpace: "nowrap",
              "&: hover": { backgroundColor: "grey.900" },
            }}
          >
            Sign up
          </Button>
        </Stack>
      )}
    </StyledHeader>
  )
}

export default Header
